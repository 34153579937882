import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import styles from "./Timeline.module.css";
import tick from "../../Assets/svg/tick.svg";
import useWindowDimensions from "../../Hooks/useWindowDimension";

const Timeline = ({ para }) => {
  const contentStyle = {
    background: "rgba(255, 255, 255, 0.06)",
    boxShadow: "0 3px 0 var(--gold)",
    "--webkit-box-shadow": "0 3px 0 var(--gold)",
  };

  const { width } = useWindowDimensions();

  return (
    <div className={styles.timeline}>
      <h4
        style={{ display: para === "none" && "none" }}
        className={styles.text}
      >
        Welcome to DreamGirls NFT! We are excited to bring our innovative
        concept to the world of digital collectibles and give collectors a
        fresh, exciting, and profitable experience. Our unique digital
        collectibles will be unlike anything you have seen before, and the
        DreamGirls Token will serve as the currency for our ecosystem, giving it
        immediate utility and unlimited growth potential. Get ready for an
        exciting ride!
      </h4>
      <h3>Roadmap</h3>
      <div className={styles.timelineComp}>
        <VerticalTimeline>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="Phase 1"
            contentStyle={contentStyle}
            contentArrowStyle={{
              borderRight: "7px solid rgba(255, 255, 255, 0.06)",
            }}
            iconStyle={{
              background: "var(--gold)",
              boxShadow: "0 0 0 4px var(--gold)",
              "--webkit-box-shadow": "0px 0px 0px 4px var(--gold)",
              width: "40px",
              height: "40px",
              marginLeft: width > 1200 ? "-20px" : "0px",
            }}
          >
            <ListItem>
              The concept & vision for Dream Girls NFT is born
            </ListItem>
            <ListItem>The team is assembled</ListItem>
            <ListItem>
              Initial Roster of 1000 Dream Girl content creators are confirmed
            </ListItem>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="Phase 2"
            contentStyle={contentStyle}
            contentArrowStyle={{
              borderRight: "7px solid rgba(255, 255, 255, 0.06)",
            }}
            iconStyle={{
              background: "var(--gold)",
              boxShadow: "0 0 0 4px var(--gold)",
              "--webkit-box-shadow": "0px 0px 0px 4px var(--gold)",
              width: "40px",
              height: "40px",
              marginLeft: width > 1200 ? "-20px" : "0px",
            }}
          >
            <ListItem weight={"bold"} color="var(--gold)">
              Website and social media platforms are launched
            </ListItem>
            <ListItem>1000 Content creators confirmed</ListItem>
            <ListItem>
              Initial contact with different brands and businesses
            </ListItem>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="Phase 3"
            contentStyle={contentStyle}
            contentArrowStyle={{
              borderRight: "7px solid rgba(255, 255, 255, 0.06)",
            }}
            iconStyle={{
              background: "var(--dark-background)",
              boxShadow: "0 0 0 4px var(--gold)",
              "--webkit-box-shadow": "0px 0px 0px 4px var(--gold)",
              width: "40px",
              height: "40px",
              marginLeft: width > 1200 ? "-20px" : "0px",
            }}
          >
            <ListItem weight={"bold"} color="var(--gold)">
              Launch with first mint
            </ListItem>
            <ListItem>
              First “real world” events take place for NFT holders
            </ListItem>
            <ListItem>
              Partnerships with brands and businesses established
            </ListItem>
            <ListItem>
              Dream Girls Content Creators reach 2500 in number
            </ListItem>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="Phase 4"
            contentStyle={contentStyle}
            contentArrowStyle={{
              borderRight: "7px solid rgba(255, 255, 255, 0.06)",
            }}
            iconStyle={{
              background: "var(--dark-background)",
              boxShadow: "0 0 0 4px var(--gold)",
              "--webkit-box-shadow": "0px 0px 0px 4px var(--gold)",
              width: "40px",
              height: "40px",
              marginLeft: width > 1200 ? "-20px" : "0px",
            }}
          >
            <ListItem>
              Dream Girls Content Creators reach 5000 in number
            </ListItem>
            <ListItem weight={"bold"} color="var(--gold)">
            DG MEME Coin created and airdropped to NFT holders
            </ListItem>
            <ListItem>Tokens Staking enabled</ListItem>
            {/* <ListItem>Smart contracts audit</ListItem> */}
            <ListItem weight={"bold"} color="var(--gold)">
              NFT MarketPlace launched buying, selling & trading
            </ListItem>
            <ListItem>DG swag created</ListItem>
            <ListItem>
              Additional DG Events scheduled and held in multiple cities
            </ListItem>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="Phase 5"
            contentStyle={contentStyle}
            contentArrowStyle={{
              borderRight: "7px solid rgba(255, 255, 255, 0.06)",
            }}
            iconStyle={{
              background: "var(--dark-background)",
              boxShadow: "0 0 0 4px var(--gold)",
              "--webkit-box-shadow": "0px 0px 0px 4px var(--gold)",
              width: "40px",
              height: "40px",
              marginLeft: width > 1200 ? "-20px" : "0px",
            }}
          >
            <ListItem>
              Launch date confirmed for Dream Girls World Tour
            </ListItem>
            {/* <ListItem>Coin listing on Major Exchange</ListItem>
            <ListItem weight={"bold"} color="var(--gold)">
              Acquisition of metaverse plots
            </ListItem> */}
            <ListItem>
              Dream Girls Content Creators reach 10,000 in number
            </ListItem>
            <ListItem weight={"bold"} color="var(--gold)">
              Dream Girls Casino & Online Games created
            </ListItem>
          </VerticalTimelineElement>
        </VerticalTimeline>
      </div>
    </div>
  );
};

export default Timeline;

const ListItem = ({ children, color, weight }) => {
  return (
    <span
      style={{ display: "flex", alignItems: "center", marginBottom: "15px" }}
    >
      <span style={{ marginRight: "22px" }}>
        <img src={tick} alt="Tick" />
      </span>
      <p
        style={{ fontWeight: weight, color: color }}
        className={styles.ListItem}
      >
        {children}
      </p>
    </span>
  );
};
