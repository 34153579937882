import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Input from "../Input/Input";
import Button from "../Button/Button";
import styles from "./Forms.module.css";
import axios from "axios";
import Success from "./Success";
import dg from "../../Assets/png/dgform.png";
import Dropzone from "./DragnDrop";
import { useSearchParams } from "react-router-dom";
import Loader from "../Loader/Loader";
import { useAccount } from "wagmi";
import { ConnectWalletBtn } from "../Wallet/ConnectWallet";
import { ethers } from "ethers";
import { Box, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import ErrorModal from "../ErrorModal/ErrorModal";
import { JWT } from "../../Helper/Constants";

const DreamGirlForm = () => {
  const [status, setStatus] = React.useState("idle");
  const [errorText, setErrorText] = React.useState(null);
  const [phoneNumErr, setPhoneNumErr] = React.useState(null);
  const [referalErr, setReferalErr] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [files, setFiles] = React.useState([]);
  const [username, setUsername] = React.useState(null);
  const [errorArray, setErrorArray] = React.useState([{}]);
  const [fileError, setFileError] = React.useState("");
  const [searchParams] = useSearchParams();
  const { address, isConnected } = useAccount();
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const checkValidity = async (_referalCode) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/get-all-user?referalCode=${_referalCode}`
      );
      // setReferalCode(data.data.data));
      console.log(response);
      return response.data.count;
    } catch (error) {
      console.log("catch", error);
      return false;
    }
  };
  useEffect(() => {
    // getAllUser();
  }, []);
  // const [confirm, setConfirm] = useState(false);
  // const [confirmMsg, setConfirmMsg] = useState(false);
  // const handleCheckbox = () => {
  //   setConfirm(!confirm);
  //   setConfirmMsg(false);
  // };

  const formik = useFormik({
    initialValues: {
      referredBy: searchParams.get("referredBy") || "",
      username: "",
      firstName: "",
      lastName: "",
      password: "",
      email: "",
      phoneNumber: "",
      instagram_handle: "",
      checkbox:false
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .trim("Username should not contain spaces")
        .min(6, "Username must be at least 6 characters")
        .max(16, "Username can be at most 16 characters")
        .matches(
          "^[a-zA-Z_0-9]{6,16}$",
          "Username should contain letters, number or underscores"
        )
        .required("Required"),
      password: Yup.string()
        .trim("Password should not contain spaces")
        .min(8, "Password must be at least 8 characters")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
          "Password should contain at least one uppercase letter, one lowercase letter, one number, one special character."
        )
        // .matches(
        //   "^[a-zA-Z0-9_]{6,16}$",
        //   "Password should contain letters, number or underscores"
        // )
        .required("Required"),
      firstName: Yup.string()
        .trim("Fisrt Name should not contain spaces")
        .required("Required"),
      lastName: Yup.string()
        .trim("Last Name should not contain spaces")
        .required("Required"),
      email: Yup.string()
        .trim("Email should not contain spaces")
        .email("Invalid email address")
        .required("Required"),
      phoneNumber: Yup.string().trim("Phone number should not contain spaces"),

      instagram_handle: Yup.string().trim(
        "Social media handle should not contain spaces"
      ),
      referredBy: Yup.string().trim(
        "Referral code should not contain spaces",
        function (value) {
          if (!value) {
            // If the field is empty, consider it as valid
            return true;
          }
        }
      ),
      checkbox: Yup.boolean().oneOf([true], "Please check the box.")
      .required("Required"),
    }),
    onSubmit: async (
      values,
      { setErrors, setSubmitting, resetForm, errors }
    ) => {
      // alert("Submitted")
      const girlsRoute = `${process.env.REACT_APP_BACKEND}/auth/local/register`;
      setReferalErr(false);
      try {
        if (values.referredBy !== "") {
          const valid = await checkValidity(values.referredBy);
          if (!valid) {
            setReferalErr(true);
            return false;
          }
        }

        const res = await axios.post(girlsRoute, {
          user_type: "Girl",
          ...values,
          // data: { wallet: address , ...values },
        });
        if (res.status === 200) {
          setStatus("success");
          // window.location.href = "/dashboard";
          // localStorage.setItem("jwt", res.data.jwt);
          // localStorage.setItem("id", res.data.user.id);
        } else {
          // setErrorArray(res.data.error.message);
          setErrorText(res.data.error.message);
        }
      } catch (err) {
        setPhoneNumErr(
          err.response.data.error.details.errors?.[0].path?.[0] ===
            "phoneNumber"
        );
        setErrorText(err.response.data.error.message);
      }

      // } else {
      //   if (username?.includes(values.referredBy)) {
      //     setReferalErr(false);
      //     try {
      //       const res = await axios.post(girlsRoute, {
      //         user_type: "Girl",
      //         ...values,
      //         // data: { wallet: address , ...values },
      //       });
      //       if (res.status === 200) {
      //         setStatus("success");
      //         window.location.href = "/dashboard";
      //         localStorage.setItem("jwt", res.data.jwt);
      //         localStorage.setItem("id", res.data.user.id);
      //       } else {
      //         // setErrorArray(res.data.error.message);
      //         setErrorText(res.data.error.message);
      //       }
      //     } catch (err) {
      //       setPhoneNumErr(
      //         err.response.data.error.details.errors?.[0].path?.[0] ===
      //           "phoneNumber"
      //       );
      //       setErrorText(err.response.data.error.message);
      //     }
      //   } else {
      //     setReferalErr(true);
      //   }
      // }

      // try {
      //   let imagesData = new FormData();
      //   files.forEach((file) => {
      //     imagesData.append("files", file);
      //   });
      //   imagesData.append("username", values.username);
      //   imagesData.append("name", values.name);
      //   imagesData.append("email", values.email);
      //   imagesData.append("referredBy", values.referredBy);
      //   imagesData.append("photographer", values.photograph);
      //   imagesData.append("instagramUrl", values.instagramUrl);
      //   imagesData.append("social", values.social);
      //   imagesData.append("description", values.description);
      //   imagesData.append("wallet", values.wallet);

      // const res = await axios.post(girlsRoute, {
      //   data: values,
      // });

      // if (res.status === 200) {

      // const config = {
      //   headers: {
      //     "Content-Type": "multipart/form-data",
      //   },
      // };

      //   const imgRes = await axios.post(
      //     `${process.env.REACT_APP_BACKEND}/create-girl-profile`,
      //     imagesData,
      //     config,
      //   );

      //   // alert(imgRes.status)

      //   if (imgRes.data.status === 201) {
      //     setStatus("success");
      //     resetForm();
      //   }
      //   if (imgRes.data.status === 500) {
      //     setErrorArray(imgRes.data.error.details.errors);
      //   }
      // } catch (err) {
      //   console.log("Error", err);
      // }
    },
  });
  console.log(formik.errors);
  
  useEffect(() => {
    if (formik.values.referredBy === "") {
      setReferalErr(false);
    }
  }, [formik.values.referredBy]);
  const newErrArray = errorArray?.map((e) => e.path)?.[0];
  return (
    <div className={styles.container}>
      <div className={styles.childContainer}>
        <div className={styles.heroText}>
          <h1>Apply to become a DreamGirl</h1>
          <p>
            The Dream Girls NFT platform will allow any girl to turn your photos
            into cash by using them to create unique digital collectibles also
            known as NFTs (Non-Fungible Tokens). No money, work or photo shoot
            needed! Put the photos that are sitting on your phone into action to
            make you money!
          </p>
          <br />
          <p>
            You can submit up to 20 photos and we will select the 3 that will
            create the best NFTs. Each photo will be used to create 1040 unique
            NFTs for a total of 3120 digital collectibles. The template for the
            Digital Collectibles is a custom designed deck of cards and
            collectors will be able to earn rewards and unlock benefits by
            putting together poker hands like 3 of a kind or a full house. This
            will of course encourage them to purchase more of your NFTs. As we
            grow, we will introduce a secondary marketplace where your NFTs may
            be resold and generate additional income for you.
          </p>
          <br />
          <p>
            The Digital Collectibles will be sold singularly for $20 USD or in
            packs of 5, 10, 25 or 50! You will receive 40% commission of all
            your sales! This can generate thousands, even tens of thousands of
            dollars in commission for you. You can also make money by referring
            other girls to our site, check out the affiliate section if that
            interests you.
          </p>
          <br />
          <p>
            Read the{" "}
            <a
              target="_balnk"
              href="https://dreamgirls-strapi.s3.us-west-2.amazonaws.com/Dream_Girls_Opportunity_7851b5d78b.pdf"
            >
              Getting Started
            </a>{" "}
            document.
          </p>
          <br />
          <br />
          <br />
          <img src={dg} alt="Dream Girl" />
        </div>
      </div>
      <div className={styles.childContainer}>
        <div className={styles.formContainer}>
          {status === "idle" ? (
            <form onSubmit={formik.handleSubmit}>
              <div className={styles.inputs}>
                <Input
                  id="username"
                  name="username"
                  type="text"
                  label="Username*"
                  placeholder="username"
                  error={formik.errors.username}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.username}
                  touched={formik.touched.username ? 1 : 0}
                />
                <Input
                  id="password"
                  name="password"
                  type="password"
                  label="Password*"
                  placeholder="password"
                  error={formik.errors.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  touched={formik.touched.password ? 1 : 0}
                  eye={true}
                />
                <Input
                  id="firstName"
                  name="firstName"
                  type="text"
                  label="First Name*"
                  placeholder="First Name"
                  error={formik.errors.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.firstName}
                  touched={formik.touched.firstName ? 1 : 0}
                />
                <Input
                  id="lastName"
                  name="lastName"
                  type="text"
                  label="Last Name*"
                  placeholder="Last Name"
                  error={formik.errors.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.lastName}
                  touched={formik.touched.lastName ? 1 : 0}
                />
                <Input
                  id="email"
                  name="email"
                  type="email"
                  label="Email*"
                  placeholder="name@example.com"
                  error={formik.errors.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  touched={formik.touched.email ? 1 : 0}
                />
                {/* <Input
                  id="affiliate"
                  name="affiliate"
                  type="text"
                  label="Affiliate Address"
                  placeholder="yourAffiliateAddress"
                  error={formik.errors.affiliate}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.affiliate}
                  touched={formik.touched.affiliate ? 1 : 0}
                /> */}
                {/* <Input
                  id="photograph"
                  name="photograph"
                  type="text"
                  label="Photograph Address"
                  placeholder="yourPhotographAddress"
                  error={formik.errors.photograph}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.photograph}
                  touched={formik.touched.photograph ? 1 : 0}
                /> */}
                <Input
                  id="phoneNumber"
                  name="phoneNumber"
                  type="text"
                  label="Phone Number"
                  placeholder="Your Phone Number"
                  error={formik.errors.phoneNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phoneNumber}
                  touched={formik.touched.phoneNumber ? 1 : 0}
                />
                <Input
                  id="referredBy"
                  name="referredBy"
                  type="text"
                  label="Referred By"
                  placeholder="referral code"
                  disabled={searchParams.get("referredBy")}
                  error={formik.errors.referredBy}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.referredBy}
                  touched={formik.touched.referredBy ? 1 : 0}
                />
                <Input
                  id="instagram_handle"
                  name="instagram_handle"
                  type="text"
                  label="Instagram Handle"
                  placeholder="Your Instagram Handle"
                  error={formik.errors.instagram_handle}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.instagram_handle}
                  touched={formik.touched.instagram_handle ? 1 : 0}
                />
                {/* <Input
                  id="social"
                  name="social"
                  type="text"
                  label="Social Media Handle"
                  placeholder="yourSocialMediaHandle"
                  error={formik.errors.social}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.instagram_handle}
                  touched={formik.touched.instagram_handle ? 1 : 0}
                /> */}
                {/* <Input
                  id="description"
                  name="description"
                  type="text"
                  label="Anything else you'd like to share?"
                  placeholder="Type here..."
                  error={formik.errors.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.description}
                  touched={formik.touched.description ? 1 : 0}
                /> */}
                {/* <Dropzone onChange={setFiles} error={fileError} /> */}
              </div>
              {errorText && (
                <Box sx={{ p: "1rem", display: "flex" }}>
                  <Typography sx={{ color: "red", fontSize: "1rem" }}>
                    {phoneNumErr ? "Phone Number must be unique" : errorText}
                    <Typography
                      component={"span"}
                      // title="For More Info"
                    >
                      {" "}
                      <InfoIcon
                        sx={{
                          fontSize: "1.2rem",
                          // cursor: "pointer",
                          verticalAlign: "sub",
                        }}
                      />
                    </Typography>
                  </Typography>
                </Box>
              )}
              {referalErr && (
                <Box sx={{ p: "1rem", display: "flex" }}>
                  <Typography sx={{ color: "red", fontSize: "1rem" }}>
                    {referalErr && "Referal Code is not valid."}
                  </Typography>
                </Box>
              )}
              <Box sx={{
                p:"1rem 1rem 2rem",
              }}>
           <Box
        
        sx={{
          
          display: "flex",
          gap: "0.5rem",
          // flexWrap:"wrap",
          alignItems: "baseline",
        }}
      >
        <input
          type="checkbox"
          style={{ cursor: "pointer", padding: "5px" }}
          id="checkbox"
          name="checkbox"
          value={formik.values.checkbox}
          checked={formik.values.checkbox}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <label
          style={{
            fontSize: "16px",
            fontWeight: "500",
            color: "#fff",
            fontFamily: "Candara",
          }}
        >
          I confirm that I am 18 years of age or older.
        
        </label>
      </Box>
      {formik.errors.checkbox && formik.touched.checkbox && (
          <Typography
            variant="body1"
            sx={{ color: "red", fontSize: "1rem" }}
          >
            {formik.errors.checkbox}
          </Typography>
        )}

              </Box>
   
              {/* {newErrArray && (
                <Box sx={{ p: "1rem", display: "flex" }}>
                  <Typography sx={{ color: "red", fontSize: "1rem" }}>
                    {errorArray?.length} Error Ocuured.{" "}
                    <Typography
                      component={"span"}
                      title="For More Info"
                      onClick={handleClickOpen}
                    >
                      <InfoIcon
                        sx={{
                          fontSize: "1.2rem",
                          cursor: "pointer",
                          verticalAlign: "sub",
                        }}
                      />
                    </Typography>
                  </Typography>
                </Box>
              )}
              <ErrorModal
                handleClose={handleClose}
                open={open}
                errorArray={errorArray}
              /> */}
         
              <div className={styles.buttonContainer}>
                {/* {isConnected?   <Button disabled={formik.isSubmitting} type="submit">
                  {formik.isSubmitting ? <Loader /> : "Submit"}
                </Button>:
                <ConnectWalletBtn/>
                } */}
                <Button disabled={formik.isSubmitting} type={"submit"}>
                  {formik.isSubmitting ? <Loader /> : "Submit"}
                </Button>
              </div>
            </form>
          ) : (
            <Success />
          )}
        </div>
      </div>
    </div>
  );
};

export default DreamGirlForm;

const generateId = () => {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < 10; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
function rec(obj) {
  for (let key of Object.keys(obj)) {
    if (obj[key] === "") {
      delete obj[key];
    } else if (typeof obj[key] === "object") {
      obj[key] = rec(obj[key]);
      if (Object.keys(obj[key]).length === 0) delete obj[key];
    }
  }
  return Array.isArray(obj) ? obj.filter((val) => val) : obj;
}
