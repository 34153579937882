import React from "react";
import WhitelistForm from "../Component/Forms/WhitelistForm";
import Navbar from "../Component/Navbar/Navbar";
import Footer from "../Component/Footer/Footer";
import { Helmet } from "react-helmet-async";

const Whitelist = () => {
  return (
    <>
      <Helmet>
        <title>Whitelist Application</title>
        <meta
          name="description"
          content="
          By completing this form, you will be one of the first to have access to our Dream Girls NFT mint. Simply complete and submit the form and your wallet will be whitelisted. Don't miss your chance to get in on the action early!
          
          You will receive a FREE NFT for everyone you purchase! But a pack of 5 and receive a FREE pack of 5 additional Dream Girls NFTs at no cost!
          "
        />
      </Helmet>
      <Navbar />
      <WhitelistForm />
      <Footer />
    </>
  );
};

export default Whitelist;
