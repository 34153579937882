import { Box, Skeleton } from '@mui/material'
import React, { useEffect, useState } from 'react'

const ImageBox = ({data}) => {
    const [loading, setLoading] = useState(true);
    const [imageKey, setImageKey] = useState(0);
    const [overlayVisible, setOverlayVisible] = useState(false);
    const [imageError, setImageError] = useState(false);
  
  
    // const imageUrl =
    //   "https://dreamgirls-strapi.s3.us-west-2.amazonaws.com/public/preview/56-0.png";
    const imageUrl = `${process.env.REACT_APP_META_URL}/public/preview/${data?.girlId?.id}-${data?.collectionid}.png`; 
  
  
    useEffect(() => {
      const interval = setInterval(() => {
        setOverlayVisible(true);
        setTimeout(() => {
          setImageKey((prevKey) => prevKey + 1);
        }, 300);
      }, 6000); 
  
      return () => clearInterval(interval);
    }, []);
    const handleImageLoad = () => {
        setLoading(false);
        setOverlayVisible(false);
        setImageError(false); 
      };
    
    
      const handleImageError = () => {
        setLoading(false);
        setImageError(true); 
      };
  return (
    <Box
    sx={{
      textAlign: "center",
      backgroundColor: "#000",
      borderRadius: "12px",
      position: "relative", 
          overflow: "hidden",
      // height: "352px",
      height: "100%",
      mt: "3px",
    }}
  >
  <Box
          key={imageKey} 
          sx={{
            position: "relative",
            width: "100%",
            height: "500px",
            backgroundImage: `url(${imageUrl}?${imageKey})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            opacity: loading ? 0 : 1,
            transform: loading ? "scale(1.05)" : "scale(1)",
            transition:loading?"": "opacity 0.6s ease-in-out, transform 0.5s ease-in-out", 
            zIndex: 1,
          }}
        >
  
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.3)", // Semi-transparent overlay
              zIndex: 2, // Ensure overlay is above the image
              opacity: overlayVisible ? 1 : 0, // Fade in/out overlay
              transform: overlayVisible ? "translateY(0)" : "translateY(-100%)", // Slide-in effect from top
              transition: "opacity 0.3s ease-in-out, transform 0.3s ease-in-out", // Smooth fade and slide 
            }}
          />

       
          {loading || imageError ? (
            <Box
              sx={{
               
                p:"1rem",
                  height: "100%",
                  "& .MuiSkeleton-root": {
                    transformOrigin: "0 0%",
                    transform: "scale(1,1)",
                  },
              }}
            >
              <Skeleton
                component="p"
                sx={{ background: "#ffffffb5", height: "100%" }}
              />
            </Box>
          ) : null}

       
          <img
            src={`${imageUrl}?${imageKey}`}
            alt="NFT"
            style={{ display: "none" }}
            onLoad={handleImageLoad}
            onError={handleImageError} 
          />
        </Box>
  </Box>
  )
}

export default ImageBox