import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Input from "../Input/Input";
import Button from "../Button/Button";
import styles from "./Forms.module.css";
import axios from "axios";
import Success from "./Success";
import Loader from "../Loader/Loader";
import { ConnectWalletBtn } from "../Wallet/ConnectWallet";
import { useAccount } from "wagmi";
import { Box, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import ErrorModal from "../ErrorModal/ErrorModal";
import { ethers } from "ethers";

const AffiliateForm = () => {
  const [status, setStatus] = React.useState("idle");
  const [open, setOpen] = React.useState(false);
  const [phoneNumErr, setPhoneNumErr] = React.useState(null);

  // const [errorArray, setErrorArray] = React.useState([{}]);
  const [errorText, setErrorText] = React.useState(null);
  const { address, isConnected } = useAccount();
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const formik = useFormik({
    initialValues: {
      username: "",
      firstName: "",
      lastName: "",
      password: "",
      email: "",
      phoneNumber: "",
      instagram_handle: "", 
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .trim("Username should not contain spaces")
        .min(6, "Username must be at least 6 characters")
        .max(16, "Username can be at most 16 characters")
        .matches(
          "^[a-zA-Z_0-9]{6,16}$",
          "Username should contain letters, number or underscores",
        )
        .required("Required"),
        password: Yup.string()
        .trim("Password should not contain spaces")
        .min(8, "Password must be at least 8 characters")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
          "Password should contain at least one uppercase letter, one lowercase letter, one number, one special character."
        )
        .required("Required"),        
        firstName: Yup.string()
        .trim("Fisrt Name should not contain spaces")
        .required("Required"),
      lastName: Yup.string()
        .trim("Last Name should not contain spaces")
        .required("Required"),
      email: Yup.string()
        .trim("Email should not contain spaces")
        .email("Invalid email address")
        .required("Required"),
      phoneNumber: Yup.string()
        .trim("Phone number should not contain spaces"),         
      instagram_handle: Yup.string().trim(
        "Social media handle should not contain spaces",
      )
    }),
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      const affiliateRoute = `${process.env.REACT_APP_BACKEND}/auth/local/register`;
 
      try {
        const res = await axios.post(affiliateRoute, {
           user_type: "Affiliate"  , ...values 
          // data: { wallet: address , ...values },
        });
        console.log(res)
        if (res.status === 200) {
          setStatus("success");
          // window.location.href = "/dashboard";
          // localStorage.setItem('jwt', res.data.jwt);
          // localStorage.setItem('id', res.data.user.id);
        }
        else  {
          // setErrorArray(res.data.error.message);
        setErrorText(res.data.error.message)

        }
      } catch (err) {
        console.log(err);
        // setErrorArray([err.response.data.error.message]);
        setPhoneNumErr(
          err.response.data.error.details.errors?.[0].path?.[0] ===
            "phoneNumber"
        );
        setErrorText(err.response.data.error.message)

      }
    },
  });
  // const newErrArray = errorArray?.map((e) => e.path)?.[0];
  return (
    <div className={styles.container}>
      <div className={styles.childContainer}>
        <div className={styles.heroText}>
          <h1>Sign up to become an affiliate</h1>
          <p>
            Thanks for your interest in becoming a Dream Girls Affiliate! You
            will earn a percentage of every NFT sold from every girl you
            directly refer to our platform. NFTs will be sold singularly for $20
            USD or in packs of 5, 10, 25 or 50! Customers can purchase the NFTs
            with crypto or credit card.
          </p>
          <br />
          <p>
            For every girl you refer that decides to partner with us you will
            receive 10% of her NFT sales. Refer 5 girls and become a Gold
            Partner and receive 12% of all the NFT sales from your referrals.
            Refer 10, become a Platinum Partner and receive 15%. When your
            referrals NFT collection sells out you can earn up to $6240 USD! As
            a Gold Partner this number would be $7480 and as a Platinum Partner
            $9360. You will have your choice of receiving payment via direct
            deposit or through crypto. <br />
            <br />
            To get stated as an affiliate please complete the application form
            and a representative will be in touch with you ASAP!
          </p>
          <br />
        </div>
      </div>
      <div className={styles.childContainer}>
        <div className={styles.formContainer}>
          {status === "idle" ? (
            <form onSubmit={formik.handleSubmit}>
              <div className={styles.inputs}>
                <Input
                  id="username"
                  name="username"
                  type="text"
                  label="Username*"
                  placeholder="username"
                  error={formik.errors.username}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.username}
                  touched={formik.touched.username ? 1 : 0}
                />
                   <Input
                  id="password"
                  name="password"
                  type="password"
                  label="Password*"
                  placeholder="password"
                  error={formik.errors.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  touched={formik.touched.password ? 1 : 0}
                  eye={true}
                />
                    <Input
                  id="firstName"
                  name="firstName"
                  type="text"
                  label="First Name*"
                  placeholder="First Name"
                  error={formik.errors.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.firstName}
                  touched={formik.touched.firstName ? 1 : 0}
                />
                <Input
                  id="lastName"
                  name="lastName"
                  type="text"
                  label="Last Name*"
                  placeholder="Last Name"
                  error={formik.errors.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.lastName}
                  touched={formik.touched.lastName ? 1 : 0}
                />
                <Input
                  id="email"
                  name="email"
                  type="email"
                  label="Email*"
                  placeholder="name@example.com"
                  error={formik.errors.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  touched={formik.touched.email ? 1 : 0}
                />
                <Input
                  id="phoneNumber"
                  name="phoneNumber"
                  type="tel"
                  label="Phone number"
                  placeholder="Enter your phone"
                  error={formik.errors.phoneNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phoneNumber}
                  touched={formik.touched.phoneNumber ? 1 : 0}
                />
                <Input
                  id="instagram_handle"
                  name="instagram_handle"
                  type="text"
                  label="Instagram Handle"
                  placeholder="Your Instagram Handle"
                  error={formik.errors.instagram_handle}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.instagram_handle}
                  touched={formik.touched.instagram_handle ? 1 : 0}
                />
                {/* <Input
                  id="wallet"
                  name="wallet"
                  type="text"
                  label="Wallet Address"
                  placeholder="yourWalletAddress"
                  error={formik.errors.wallet}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.wallet}
                  touched={formik.touched.wallet ? 1 : 0}
                /> */}
              </div>
              {
                errorText &&
              <Box sx={{ p: "1rem", display: "flex" }}>
                  <Typography sx={{ color: "red", fontSize: "1rem" }}>
                  {phoneNumErr ? "Phone Number must be unique" : errorText}

                    <Typography
                      component={"span"}
                      title="For More Info" 
                    >
                      <InfoIcon
                        sx={{
                          fontSize: "1.2rem",
                          cursor: "pointer",
                          verticalAlign: "sub",
                        }}
                      />
                    </Typography>
                  </Typography>
                </Box>
              }

              {/* {newErrArray && (
                <Box sx={{ p: "1rem", display: "flex" }}>
                  <Typography sx={{ color: "red", fontSize: "1rem" }}>
                    {errorArray?.length} Error Ocuured.{" "}
                    <Typography
                      component={"span"}
                      title="For More Info"
                      onClick={handleClickOpen}
                    >
                      <InfoIcon
                        sx={{
                          fontSize: "1.2rem",
                          cursor: "pointer",
                          verticalAlign: "sub",
                        }}
                      />
                    </Typography>
                  </Typography>
                </Box>
              )}
              <ErrorModal
                handleClose={handleClose}
                open={open}
                errorArray={errorArray}
              /> */}
              <div className={styles.buttonContainer}>
                {/* {isConnected?   <Button disabled={formik.isSubmitting} type="submit">
                  {formik.isSubmitting ? <Loader /> : "Submit"}
                </Button>:
                <ConnectWalletBtn/>
                } */}
                <Button disabled={formik.isSubmitting} type="submit">
                  {formik.isSubmitting ? <Loader /> : "Submit"}
                </Button>
              </div>
            </form>
          ) : (
            <Success />
          )}
        </div>
      </div>
    </div>
  );
};

export default AffiliateForm;

const generateId = () => {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < 10; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
