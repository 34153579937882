import {
  Box,
  Button,
  Grid,
  Typography,
  FormControl,
  MenuItem,
  Select,
  Skeleton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import NFTTab from "../NftDetail/NFTTab";
import { CrossmintPayButton } from "@crossmint/client-sdk-react-ui";
import { Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import GirlImages from "../GirlImages";
import axios from "axios";
import { useParams } from "react-router-dom";
import Loader from "../../../Component/Loader/Loader";
import {
  backgroundArrayData,
  bordersArrayData,
  suitsArrayData,
  zodiacArrayData,
} from "./PreviewData";
import ImageBox from "./ImageBox";

const GirlCollection = ({ girlCollection, data }) => {
  const [girlNft, setGirlNft] = useState(null);
  const [packcategory, setPackCategory] = useState(null);
  const [backgroundArray, setBackgroundArray] = useState(null);
  const [zodiacArray, setZodiacArray] = useState(null);
  const [bordersArray, setBordersArray] = useState(null);
  const [symbolArray, setSymbolArray] = useState(null);
  const [previewResponse, setPreviewResponse] = useState("");
  const [mintPrice, setMintPrice] = useState(0);
  const [mintFormatPrice, setMintFormatPrice] = useState(0);
  const [baseMintPrice, setBaseMintPrice] = useState(0);
  const [baseMintFormatPrice, setBaseMintFormatPrice] = useState(0);
  const [numberOfBaseMints, setNumberOfBaseMints] = useState(0);
  const [priceOfPackInDollars, setPriceOfPackInDollars] = useState(0);
  const [numberOfFreeMints, setNumberOfFreeMints] = useState(0);
  const [signature, setSignature] = useState(false);
  const [mintAsset, setMintAsset] = useState(false);
  const [loading, setLoading] = useState(false);
  const [defaultLoader, setDefaultLoader] = useState(false);

  const handleGirlNft = (e) => {
    getMintPrice();
    setGirlNft(e.target.value);
    setSignature(false);
  };
  const { id, collectionId } = useParams();
  const handleGirlPackCategory = (e) => {
    setSignature(false);
    setPackCategory(e.target.value);
    getMintSignature(e.target.value);
  };
  const handleBackgroundArray = (e) => {
    setBackgroundArray(e.target.value);
  };
  const handleZodiacArray = (e) => {
    setZodiacArray(e.target.value);
  };
  const handleBordersArray = (e) => {
    setBordersArray(e.target.value);
  };
  const handleSymbolArray = (e) => {
    setSymbolArray(e.target.value);
  };
  const handleReset = () => {
    setBackgroundArray(null);
    setZodiacArray(null);
    setBordersArray(null);
    setSymbolArray(null);
    setPreviewResponse("");
  };

  const getMintPrice = async () => {
    setMintFormatPrice(0);
    setMintAsset(null);
    setMintPrice(0);
    await axios({
      method: "GET",
      url: `${process.env.REACT_APP_BACKEND}/get/mintPrice`,
      headers: {
        "Accept-Encoding": "application/json",
      },
    })
      .then((_data) => {
        console.log(_data);
        setMintPrice(_data.data.price);
        setMintFormatPrice(_data.data.formatPrice);
        setMintAsset(_data.data.mintAsset);
      })
      .catch((err) => {
        //  throw err
        console.log(err);
      });
  };

  const getMintSignature = async (packcategory) => {
    setSignature(false);
    await axios({
      method: "POST",
      url: `${process.env.REACT_APP_BACKEND}/get/mintSignature`,
      headers: {
        "Accept-Encoding": "application/json",
      },
      data: {
        mintType: girlNft,
        packCategory: packcategory,
        girlId: data?.girlId?.girlId,
        collectionId: data?.collectionid,
      },
    })
      .then((_data) => {
        // console.log(_data);
        setBaseMintPrice(_data.data.value);
        setBaseMintFormatPrice(_data.data.formatValue);
        setSignature(_data.data.signature);
        setNumberOfBaseMints(_data.data.mintData.numberOfBaseMints);
        setPriceOfPackInDollars(_data.data.mintData.priceOfPackInDollars);
        setNumberOfFreeMints(_data.data.mintData.numberOfFreeMints);
        setMintAsset(_data.data.mintAsset);
      })
      .catch((err) => {
        //  throw err
        console.log(err);
      });
  };

  useEffect(() => {
    console.log({
      totalPrice: baseMintFormatPrice,
      mintType: {
        numberOfBaseMints: numberOfBaseMints,
        priceOfPackInDollars: priceOfPackInDollars,
        numberOfFreeMints: numberOfFreeMints,
      },
      packCategory: packcategory,
      girlId: data?.girlId?.girlId,
      collectionId: data?.collectionid,
      signature: signature,
      asset: mintAsset,
    });
  }, [signature]);
  const previewFunc = async () => {
    setLoading(true);
    try {
      let data = {
        girlId: id,
        collectionId: collectionId,
        background: backgroundArray,
        symbol: symbolArray,
        border: bordersArray,
        zodiac: zodiacArray,
      };

      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND}/get/combination`,
        data
      );
      if (res.status === 200) {
        setLoading(false);
        setPreviewResponse(res.data.data);
      }
    } catch (err) {
      setLoading(false);
      alert("Oops somthing went wrong");
      console.log(err);
    }
  };


  return (
    <div>
      <Grid container spacing={2} my={"10px"}>
        <Grid item lg={4} md={4} sm={12} xs={12}>
        <ImageBox data={data}/>
        </Grid>
        <Grid item lg={8} md={8} sm={12} xs={12}>
          <NFTTab
            totalCollection={girlCollection?.length}
            minted={0}
            fullName={`${data?.girlId?.username}`}
          />
          <Box
            py={"1.5rem"}
            sx={{
              display: "flex",
              // justifyContent: { sm: "space-between", xs: "center" },
              flexDirection: { sm: "row", xs: "column" },
              gap: "1rem",
            }}
          >
            <FormControl
              sx={{
                minWidth: "200px",
                display: "block",
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "& .MuiSvgIcon-root": {
                  color: "#fff",
                },

                "& .MuiInputBase-root": {
                  border: "1px solid #c79d54",
                  borderRadius: "8px",
                  maxWidth: { sm: "200px", xs: "100% !important" },
                  width: "100%",
                },
                "& .MuiSelect-select": {
                  padding: "0.6rem",
                  color: "#fff",
                  fontFamily: "Inter",
                  paddingRight: "2.5rem !important",
                },
              }}
            >
              <Select
                value={girlNft}
                onChange={handleGirlNft}
                style={{ marginRight: "10px", maxWidth: "200px" }}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value={null} key={0}>
                  ----Choose-----
                </MenuItem>
                <MenuItem value={1} key={0}>
                  Single NFT
                </MenuItem>
                <MenuItem value={2} key={0}>
                  Pack of 5 $100 plus 1 free mint
                </MenuItem>
                <MenuItem value={3} key={0}>
                  Pack of 10 $200 plus 3 free mints
                </MenuItem>
                <MenuItem value={4} key={0}>
                  Pack of 25 $500 plus 9 free mints
                </MenuItem>
                <MenuItem value={5} key={0}>
                  Pack of 50 $1000 plus 20 free mints
                </MenuItem>

                {/* {data?.images?.map((imageData, imageIndex) => {
                  return (
                    <MenuItem value={imageData?.id} key={imageIndex}>
                      NFT {imageIndex + 1}
                    </MenuItem>
                  );
                })} */}
              </Select>
            </FormControl>
            {girlNft > 1 && (
              <FormControl
                sx={{
                  minWidth: "200px",
                  display: "block",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "& .MuiSvgIcon-root": {
                    color: "#fff",
                  },

                  "& .MuiInputBase-root": {
                    border: "1px solid #c79d54",
                    borderRadius: "8px",
                    maxWidth: { sm: "200px", xs: "100% !important" },
                    width: "100%",
                  },
                  "& .MuiSelect-select": {
                    padding: "0.6rem",
                    color: "#fff",
                    fontFamily: "Inter",
                    paddingRight: "2.5rem !important",
                  },
                }}
              >
                <Select
                  onChange={handleGirlPackCategory}
                  displayEmpty
                  style={{ maxWidth: "200px" }}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value={null} key={0}>
                    ----Choose-----
                  </MenuItem>
                  <MenuItem value={0} key={0}>
                    Mint the pack of current picture of the girl
                  </MenuItem>
                  <MenuItem value={1} key={0}>
                    Mint the pack randomly from the girl's 3 picture series
                  </MenuItem>
                  <MenuItem value={2} key={0}>
                    Mint the pack across all the girls series randomly
                  </MenuItem>

                  {/* {data?.images?.map((imageData, imageIndex) => {
                  return (
                    <MenuItem value={imageData?.id} key={imageIndex}>
                      NFT {imageIndex + 1}
                    </MenuItem>
                  );
                })} */}
                </Select>
              </FormControl>
            )}

            {girlNft == 1 &&
            mintFormatPrice > 0 &&
            data?.girlId &&
            mintPrice > 0 &&
            mintAsset ? (
              <CrossmintPayButton
                collectionId={process.env.REACT_APP_COLLECTION_SINGLE}
                projectId={process.env.REACT_APP_COLLECTION_PROJECT}
                mintConfig={{
                  totalPrice: mintFormatPrice,
                  girlTokenId: data?.girlId?.girlId,
                  collectionId: data?.collectionid,
                  asset: mintAsset,
                }}
                getButtonText={(connecting, paymentMethod) =>
                  connecting ? "Connecting" : `BUY`
                }
                environment="production"
              />
            ) : (
              //             <CrossmintPayButton
              //             collectionId="ce739670-bc58-416e-858b-ca55990d1123"
              //             projectId="798a7321-ec15-49d6-825e-b4687a153783"
              //            mintConfig={{"totalPrice":mintFormatPrice, _values: mintPrice ,"girlTokenId":data?.girlId?.girlId,"collectionId":data?.collectionid+1}}
              // environment="staging"
              //             />
              <></>
            )}

            {girlNft > 1 &&
            packcategory != null &&
            baseMintFormatPrice > 0 &&
            baseMintPrice > 0 &&
            signature &&
            data?.girlId != undefined &&
            data?.collectionid != undefined &&
            mintAsset ? (
              <CrossmintPayButton
                collectionId={process.env.REACT_APP_COLLECTION_PAIR}
                projectId={process.env.REACT_APP_COLLECTION_PROJECT}
                mintConfig={{
                  totalPrice: baseMintFormatPrice,
                  mintType: {
                    numberOfBaseMints: numberOfBaseMints,
                    priceOfPackInDollars: priceOfPackInDollars,
                    numberOfFreeMints: numberOfFreeMints,
                  },
                  packCategory: packcategory,
                  girlId: data?.girlId?.girlId,
                  collectionId: data?.collectionid,
                  signature: signature,
                  asset: mintAsset,
                }}
                getButtonText={(connecting, paymentMethod) =>
                  connecting ? "Connecting" : `BUY`
                }
                environment="production"
              />
            ) : (
              <></>
            )}
          </Box>
          {/* <Box>
            <Box
              sx={{
                padding: "4px",
                mb: "1rem",
                mt: "3rem",
                border: "1px solid #1f1f1f",
                borderRadius: "24px",
              }}
            >
              <Typography
                sx={{
                  backgroundColor: "#1f1f1f",
                  fontSize: "15px",
                  textAlign: "center",
                  textTransform: "capitalize",
                  padding: "8px 10px",
                  borderRadius: "24px",
                  fontWeight: "600",
                }}
              >
                Preview (For Testing purpose only)
              </Typography>
            </Box>

            <Box
              py={"1.5rem"}
              sx={{
                display: "flex",
                gap: "1rem",
                flexDirection: { sm: "row", xs: "column" },
                gap: "1rem",
                justifyContent: { sm: "flex-start", xs: "center" },
                flexWrap: "wrap",
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "& .MuiSvgIcon-root": {
                  color: "#fff",
                },

                "& .MuiInputBase-root": {
                  border: "1px solid #c79d54",
                  borderRadius: "8px",
                  width: "100%",
                  textTransform: "capitalize",
                  maxWidth: { sm: "200px", xs: "100% !important" },
                },
                "& .MuiSelect-select": {
                  padding: "0.6rem",
                  color: "#fff",
                  fontFamily: "Inter",
                  paddingRight: "2.5rem !important",
                },
              }}
            >
              <Box>
                <Typography
                  sx={{
                    mb: "0.5rem",
                    color: "#fff",
                    fontSize: { sm: "16px", xs: "16px" },
                  }}
                >
                  Background
                </Typography>
                <FormControl
                  sx={{
                    minWidth: "200px",
                    display: "block",
                  }}
                >
                  <Select
                    value={backgroundArray}
                    onChange={handleBackgroundArray}
                    displayEmpty
                    style={{ maxWidth: "200px" }}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value={null} key={0}>
                      ----Choose-----
                    </MenuItem>
                    {backgroundArrayData
                      ?.sort((a, b) => a.localeCompare(b))
                      .map((e, index) => (
                        <MenuItem value={e} key={index}>
                          {e}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>
              <Box>
                <Typography
                  sx={{
                    mb: "0.5rem",
                    color: "#fff",
                    fontSize: { sm: "16px", xs: "16px" },
                  }}
                >
                  Zodiac
                </Typography>
                <FormControl
                  sx={{
                    minWidth: "200px",
                    display: "block",
                  }}
                >
                  <Select
                    value={zodiacArray}
                    onChange={handleZodiacArray}
                    displayEmpty
                    style={{ maxWidth: "200px" }}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value={null} key={0}>
                      ----Choose-----
                    </MenuItem>
                    {zodiacArrayData
                      ?.sort((a, b) => a.localeCompare(b))
                      .map((e, index) => (
                        <MenuItem value={e} key={index}>
                          {e}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>
              <Box>
                <Typography
                  sx={{
                    mb: "0.5rem",
                    color: "#fff",
                    fontSize: { sm: "16px", xs: "16px" },
                  }}
                >
                  Border
                </Typography>
                <FormControl
                  sx={{
                    minWidth: "200px",
                    display: "block",
                  }}
                >
                  <Select
                    value={bordersArray}
                    onChange={handleBordersArray}
                    displayEmpty
                    style={{ maxWidth: "200px" }}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value={null} key={0}>
                      ----Choose-----
                    </MenuItem>
                    {bordersArrayData
                      ?.sort((a, b) => a.localeCompare(b))
                      .map((e, index) => (
                        <MenuItem value={e} key={index}>
                          {e}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>
              <Box>
                <Typography
                  sx={{
                    mb: "0.5rem",
                    color: "#fff",
                    fontSize: { sm: "16px", xs: "16px" },
                  }}
                >
                  Suits
                </Typography>
                <FormControl
                  sx={{
                    minWidth: "200px",
                    display: "block",
                  }}
                >
                  <Select
                    value={symbolArray}
                    onChange={handleSymbolArray}
                    displayEmpty
                    style={{ maxWidth: "200px" }}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value={null} key={0}>
                      ----Choose-----
                    </MenuItem>
                    {suitsArrayData
                      ?.sort((a, b) => a.localeCompare(b))
                      .map((e, index) => (
                        <MenuItem value={e} key={index}>
                          {e}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
            <Typography
              sx={{
                mt: "1rem",
                color: "#fff",
                fontSize: { sm: "16px", xs: "16px" },
                textAlign: "center",
              }}
            >
              Choose one from each preview option
            </Typography>
            <Box
              sx={{
                mt: "1rem",
                display: "flex",
                justifyContent: "center",
                gap: "1rem",
                flexDirection: { sm: "row", xs: "column" },
                "& button": {
                  width: { sm: "200px", xs: "100%" },
                  fontSize: "18px",
                  // my: "0.5rem",
                  p: "5px !important",
                  borderRadius: "8px",
                  border: "2px solid #c79d54",
                  textTransform: "capitalize",
                  "&.Mui-disabled": {
                    cursor: "not-allowed !important",
                    pointerEvents: "auto !important",
                    // color: "rgb(255 255 255 / 60%) !important",
                    background:
                      "linear-gradient(92.97deg,#3d2b0a 3.8%,#fcebbf 34.39%,#eec96c 57.07%,#fed87f 99.59%)",
                    "&:hover": {
                      opacity: "1",
                    },
                  },
                },
              }}
            >
              <Button
                disabled={
                  zodiacArray == null ||
                  bordersArray == null ||
                  backgroundArray == null ||
                  symbolArray == null
                }
                disableRipple
                sx={{
                  color: "#000",
                  background:
                    "linear-gradient(92.97deg,#3d2b0a 3.8%,#fcebbf 34.39%,#eec96c 57.07%,#fed87f 99.59%)",
                  "&:hover": {
                    background:
                      "linear-gradient(92.97deg,#3d2b0a 3.8%,#fcebbf 34.39%,#eec96c 57.07%,#fed87f 99.59%)",
                  },
                }}
                onClick={previewFunc}
              >
                {loading ? <Loader /> : "Preview"}
              </Button>
              <Button
                disableRipple
                sx={{
                  color: "#fff",
                }}
                onClick={handleReset}
              >
                Reset
              </Button>
            </Box>
          </Box> */}
        </Grid>
      </Grid>
    </div>
  );
};

export default GirlCollection;
