import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { TOKEN } from "../../../Helper/Constants";

const handle401 = (res, next) => {
  localStorage.clear();
  if (res.status === 401) return next("/signin");
};

const handle403 = (res, next) => {
  localStorage.clear();
  if (res.status === 403) return next("/signin");
};
export const fetchProfile = async (next) => {
  const jwt = localStorage.getItem('jwt') ; 
  const config = {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  };
  try {
    const data = await axios.get(
      `${process.env.REACT_APP_BACKEND}/users/me?populate=*`,
      config,
    );
    return data.data;
  } catch (error) {
    console.log("catch", error);
    // handle401(error.response, next);
    // handle403(error.response, next);
  }
};

const fetchReferredUsers = async (next) => {
  const jwt = localStorage.getItem('jwt') ; 

  const config = {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  };
  try {
    const data = await axios.get(
      `${process.env.REACT_APP_BACKEND}/find-referred`,
      config,
    );
    // console.log(data);
    return data.data;
  } catch (error) {
    console.log("catch", error);
    // handle401(error.response, next);
    // handle403(error.response, next);
  }
};
const fetchPhotographerUsers = async (next) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
    },
  };
  try {
    const data = await axios.get(
      `${process.env.REACT_APP_BACKEND}/find-referred`,
      config,
    );
    // console.log(data);
    return data.data;
  } catch (error) {
    console.log("catch", error);
  }
};
const fetchReferredPhotographerUsers = async (next) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
    },
  };
  try {
    const data = await axios.get(
      `${process.env.REACT_APP_BACKEND}/find-referred-photographer`,
      config,
    );
    // console.log(data);
    return data.data;
  } catch (error) {
    console.log("catch", error);
  }
};

export const useProfile = () => {
  const [profile, setProfile] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      fetchProfile(navigate).then((data) => setProfile(data));
    }
  }, [navigate]);
  return profile;
};

export const useReferredUsers = () => {
  const [referredUsers, setReferredUsers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      fetchReferredUsers(navigate).then((data) => setReferredUsers(data));
    }
  }, [navigate]);

  return referredUsers;
};
export const usePhotographerUsers = () => {
  const [photographerUsers, setPhotographerUsers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      fetchPhotographerUsers(navigate).then((data) =>
        setPhotographerUsers(data),
      );
    }
  }, [navigate]);

  return photographerUsers;
};
export const useReferredPhotographerUsers = () => {
  const [photographerReferredUsers, setPhotographerReferredUsers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      fetchReferredPhotographerUsers(navigate).then((data) =>
        setPhotographerReferredUsers(data),
      );
    }
  }, [navigate]);

  return photographerReferredUsers;
};
