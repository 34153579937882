import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Input from "../Input/Input";
import Button from "../Button/Button";
import styles from "./Auth.module.css";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import Loader from "../Loader/Loader";

const Forgotpassword = () => {
  const [searchParams] = useSearchParams();
  const [emailSent, setEmailSent] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      email: searchParams?.get("email") || "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required("Required"),
    }),
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      const forgotPassword = `${process.env.REACT_APP_BACKEND}/auth/forgot-password`;

      //call api to Forgotpassword
      try {
        const response = await axios.post(forgotPassword, {
          email: values.email,
        });
        // check if response is ok
        if (response.status === 200) {
          setSubmitting(false);
          // redirect user to reset password page using react router
          setEmailSent(true);
        }
      } catch (e) {
        setSubmitting(false);
        console.log("e", e);
        if (e.code === "ERR_NETWORK") {
          alert("Network Error");
        }
        if (e.code === "ERR_BAD_REQUEST") {
          setErrors({ email: "No user found for this email!" });
        }
      }
    },
  });

  useEffect(() => {
    localStorage.clear();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.formContainer}>
        {!emailSent && (
          <>
            <h1>Please enter your email to continue</h1>
            <form onSubmit={formik.handleSubmit}>
              <Input
                id="email"
                name="email"
                type="text"
                label="Enter your email*"
                placeholder="email"
                error={formik.errors.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email.trim()}
                touched={formik.touched.email ? 1 : 0}
                width={"372px"}
              />

              <div className={styles.buttonContainer}>
                <Button
                  width={"372px"}
                  disabled={formik.isSubmitting}
                  type="submit"
                >
                  {formik.isSubmitting ? <Loader /> : "Submit"}
                </Button>
              </div>
            </form>
          </>
        )}
        {emailSent && (
          <>
            <h1>
              Email sent successfully, please check your email to reset your
              password
            </h1>
          </>
        )}
      </div>
    </div>
  );
};

export default Forgotpassword;
