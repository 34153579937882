import React from "react";
import BusinessForm from "../Component/Forms/BusinessForm";
import Navbar from "../Component/Navbar/Navbar";
import Footer from "../Component/Footer/Footer";
import { Helmet } from "react-helmet-async";

const Business = () => {
  return (
    <>
      <Helmet>
        <title>Business Application</title>
        <meta
          name="description"
          content="
          Thanks for your interest in considering a partnership with Dream Girls NFT and your company! We know this is going to be a mutually beneficial relationship. We're going to keep it really simple.


           1. There's no fee to become a partner company or brand with Dream Girls NFT.

           2. We will give access to our global community and market your products and services.

           3. All we ask is that you offer some sort of special deal, discount or benefit to our NFT holders and/or Dream Girls. This is at your discretion and needs to make sense for you.

           4. As the relationship grows we can explore additional ways to help you grow your business.

           If that sounds good to you please fill out a Partnership form and arepresentative will be in contact right away!"
        />
      </Helmet>
      <Navbar />
      <BusinessForm />
      <Footer />
    </>
  );
};

export default Business;
