import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { Alert, Box, Button, Snackbar, Typography } from "@mui/material";
import axios from "axios";
import Loader from "../../Loader/Loader";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    // padding: "1rem",
    border: "none",
    "&::-webkit-scrollbar": {
      width: 0,
    },
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: "1rem", color: "#800080" }} {...other}>
      {children}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const DeleteImage = ({
  deleteOpen,
  setDeleteOpen,
  imageId,
  fetchPhotographerUsers,
}) => {
  //   const [showSnackbar, setShowSnackbar] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleDelete = async (id) => {
    setLoading(true);
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND}/upload/files/${imageId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );

      if (response.status === 200) {
        setDeleteOpen(false);
        fetchPhotographerUsers();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <BootstrapDialog
        onClose={() => setDeleteOpen(false)}
        aria-labelledby="customized-dialog-title"
        open={deleteOpen}
        sx={{
          //   "& .MuiPaper-root": {
          //     overflowY: "inherit",
          //   },
          "& .MuiDialog-paper": {
            width: "500px",
            maxWidth: "900px",
            background: "#1d1e1f",
            border: "1px solid rgba(255, 255, 255, 0.2)",
            borderRadius: "10px",
          },
          "& .MuiDialog-container": {
            background: "rgb(0 0 0 / 86%)",
          },
        }}
      >
        <BootstrapDialogTitle id="customized-dialog-title">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                fontSize: "18px",
                lineHeight: "22.24px",
                fontWeight: "400",
                color: "rgba(255, 255, 255, 0.8)",
                display: "flex",
                gap: "10px",
                alignItems: "center",
                fontFamily: "Exo 2 !important",
              }}
            >
              Delete
            </Typography>
            <CloseIcon
              sx={{ fontSize: "1.5rem", color: "#fff", cursor: "pointer" }}
              onClick={() => setDeleteOpen(false)}
              title="Close"
            />
          </Box>
        </BootstrapDialogTitle>
        <DialogContent
          dividers
          sx={{
            p: "1rem",
          }}
        >
          <Box color={"#fff"}>
            <Typography
              sx={{
                fontSize: "18px",
                lineHeight: "22.24px",
                fontWeight: "400",
                color: "rgba(255, 255, 255, 0.8)",
                fontFamily: "Exo 2",
                pb: "2rem",
                textAlign: "center",
              }}
            >
              Are you sure you want to delete the image?
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                pb: "1rem",
                "& button": {
                  color: "#fff !important",
                  lineHeight: "19.92px !important",
                  fontSize: "14px !important",
                  height: "45px",
                  width: "30%",
                  fontWeight: "400 !important",
                  fontFamily: "Exo 2 !important",
                  transition: "0.1s all !important",
                  borderRadius: "8px !important",
                  "&.Mui-disabled": {
                    cursor: "not-allowed !important",
                    pointerEvents: "auto !important",
                    color: "rgb(255 255 255 / 38%) !important",
                    "&:hover": {
                      opacity: "1",
                    },
                  },
                },
              }}
            >
              <Button
                // className={classes.btn}
                disableRipple
                sx={{
                  background: "green",
                  "&:hover": {
                    background: "green !important",
                  },
                }}
                onClick={() => handleDelete(imageId)}
              >
                {loading ? <Loader /> : "Yes"}
              </Button>
              <Button
                sx={{
                  background: "red",
                  "&:hover": {
                    background: "red !important",
                  },
                }}
                // className={classes.btn}
                disableRipple
                onClick={() => setDeleteOpen(false)}
              >
                No
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};

export default DeleteImage;
