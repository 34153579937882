import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useSearchParams } from "react-router-dom";
import { useAccount } from "wagmi";
import { Box, Typography } from "@mui/material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const ErrorModal = ({ handleClose, open, errorArray }) => {
  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{
          "& .MuiPaper-root": {
            width: "500px !important",
            background: "#1d1e1f",
            color: "#fff",
            border: " 1px solid #ffffff59",
          },
          "& .MuiDialogContent-root": {
            borderBottom: "none",
            borderTop: " 1px solid #ffffff59",
            "::-webkit-scrollbar": { width: "6px" },
            "::-webkit-scrollbar-track": { background: "#ffffff66" },
            "::-webkit-scrollbar-thumb": { background: "#3b3b3b" },
          },
          "& .MuiDialog-container": {
            backdropFilter: "blur(8px)",
          },
        }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <div style={{ color: "#fff9e6" }}>Errors</div>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <ol style={{ "margin-left": "15px" }}>
            {errorArray?.map((v, index) =>
              v?.path?.map(
                (e) =>
                  (e === "email" && (
                    <Typography
                      key={index}
                      sx={{ color: "red", fontSize: "1rem" }}
                    >
                      <li> An account with this email already exists.</li>
                    </Typography>
                  )) ||
                  (e === "wallet" && (
                    <Typography
                      key={index}
                      sx={{ color: "red", fontSize: "1rem" }}
                    >
                      <li>
                        {" "}
                        An account with this wallet address already exists.
                      </li>
                    </Typography>
                  )) ||
                  (e === "username" && (
                    <Typography
                      key={index}
                      sx={{ color: "red", fontSize: "1rem" }}
                    >
                      <li> An account with this username already exists.</li>
                    </Typography>
                  )),
              ),
            )}
          </ol>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};

export default ErrorModal;
