import React, { memo, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Input from "../../Input/Input";
import Button from "../../Button/Button";
import styles from "./Forms.module.css";
import axios from "axios";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { TOKEN } from "../../../Helper/Constants";
import { Box, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import Select from "react-select";
import { Country } from "country-state-city";

const AffiliateForm = ({ profile }) => {
  const [errorText, setErrorText] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState();

  const formik = useFormik({
    initialValues: {
      username: profile?.username,
      firstName: profile?.firstName,
      lastName: profile?.lastName,
      email: profile?.email,
      phoneNumber: profile?.phoneNumber,
      instagram_handle: profile?.instagram_handle,
      country: profile?.country || "", // Initialize country with the name
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .trim("Username should not contain spaces")
        .matches(
          "^[a-zA-Z0-9_]{6,16}$",
          "Username should contain letters, number or underscores"
        )
        .min(6, "Username must be at least 6 characters")
        .max(16, "Username must be at most 16 characters")
        .required("Required"),
      firstName: Yup.string()
        .trim("First Name should not contain spaces")
        .required("Required"),
      lastName: Yup.string()
        .trim("Last Name should not contain spaces")
        .required("Required"),
      email: Yup.string()
        .trim("Email should not contain spaces")
        .email("Invalid email address")
        .required("Required"),
      country: Yup.string().required("Select at least one country"),
      phoneNumber: Yup.string().trim("Phone number should not contain spaces"),
      instagram_handle: Yup.string().trim(
        "Social media handle should not contain spaces"
      ),
    }),
    onSubmit: async (values, { setErrors, setSubmitting, resetForm, errors }) => {
      if (errors) {
        setErrors(errors);
      }
      const updateProfile = `${process.env.REACT_APP_BACKEND}/users/${profile?.id}`;
      const jwt = localStorage.getItem("jwt");

      const config = {
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem(TOKEN),
          Authorization: `Bearer ${jwt}`,
        },
      };

      try {
        const res = await axios.put(updateProfile, values, config);

        if (res.status === 200) {
          setSubmitting(false);
          window.location.reload();
        }
      } catch (err) {
        console.log(err);
        setErrorText(err.response.data.error.message);
      }
    },
  });

  const countries = Country.getAllCountries();

  useEffect(() => {
    if (!isEmpty(profile)) {
      formik.setValues({
        username: profile?.username || "",
        firstName: profile?.firstName || "",
        lastName: profile?.lastName || "",
        email: profile?.email || "",
        phoneNumber: profile?.phoneNumber || "",
        instagram_handle: profile?.instagram_handle || "",
        country: profile?.country || "", // Set country value if it exists in profile
      });
       // Set selectedCountry based on profile.country
       const initialCountry = countries.find(
        (country) => country?.name === profile?.country
      );
      if (initialCountry) {
        setSelectedCountry(initialCountry);
      }
    
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  return (
    <>
      <div className={styles.formContainer} style={{ margin: "0 auto" }}>
        <form onSubmit={formik.handleSubmit}>
          <div className={styles.inputs}>
            <Input
              id="username"
              name="username"
              type="text"
              label="Username*"
              placeholder="@username"
              error={formik.errors.username}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.username}
              touched={formik.touched.username ? 1 : 0}
            />
            <Input
              id="firstName"
              name="firstName"
              type="text"
              label="First Name*"
              placeholder="First Name"
              error={formik.errors.firstName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.firstName}
              touched={formik.touched.firstName ? 1 : 0}
            />
            <Input
              id="lastName"
              name="lastName"
              type="text"
              label="Last Name*"
              placeholder="Last Name"
              error={formik.errors.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.lastName}
              touched={formik.touched.lastName ? 1 : 0}
            />
            <Input
              id="email"
              name="email"
              type="email"
              label="Email*"
              placeholder="name@example.com"
              error={formik.errors.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              touched={formik.touched.email ? 1 : 0}
            />
            <Box className="select">
              <Typography
                sx={{
                  color: "#9f9f9f",
                  fontSize: "14px",
                }}
              >
                Country*
              </Typography>
              <Select
                id="country"
                name="country"
                options={countries}
                getOptionLabel={(options) => {
                  return (
                    <Box>
                      {options.flag} {options.name}
                    </Box>
                  );
                }}
                getOptionValue={(options) => {
                  return options["isoCode"];
                }}
                value={selectedCountry}
                onChange={(item) => {
                  setSelectedCountry(item);
                  formik.setFieldValue("country", item?.name); // Set the country name
                }}
                onBlur={formik.handleBlur}
                placeholder="Select country..."
                isClearable
              />
              {formik.touched.country && formik.errors.country && (
                <Typography
                  variant="body1"
                  sx={{
                    color: "red",
                    fontSize: "12px",
                    lineHeight: "16px",
                    mb: "12px",
                  }}
                >
                  {formik.errors.country}
                </Typography>
              )}
            </Box>
            <Input
              id="phoneNumber"
              name="phoneNumber"
              type="tel"
              label="Phone number"
              placeholder="Enter your phone"
              error={formik.errors.phoneNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phoneNumber}
              touched={formik.touched.phoneNumber ? 1 : 0}
            />
            <Input
              id="instagram_handle"
              name="instagram_handle"
              type="text"
              label="Instagram Handle"
              placeholder="Your Instagram Handle"
              error={formik.errors.instagram_handle}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.instagram_handle}
              touched={formik.touched.instagram_handle ? 1 : 0}
            />
          </div>
          {errorText && (
            <Box sx={{ p: "1rem 1.5rem", display: "flex" }}>
              <Typography sx={{ color: "red", fontSize: "1rem" }}>
                {errorText}
                <Typography component={"span"} title="For More Info">
                  <InfoIcon
                    sx={{
                      fontSize: "1.2rem",
                      cursor: "pointer",
                      verticalAlign: "sub",
                    }}
                  />
                </Typography>
              </Typography>
            </Box>
          )}
          <div className={styles.buttonContainer}>
            <Link className={styles.changePassword} to="/change-password">
              Change Password
            </Link>
            <Link></Link>
            <Button disabled={formik.isSubmitting} type="submit">
              {formik.isSubmitting ? "Submitting..." : "Save Changes"}
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default memo(AffiliateForm);

const isEmpty = (obj) => {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};

