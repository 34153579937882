import React from "react";
import success from "../../Assets/svg/success.svg";
import styles from "./Forms.module.css";

const Success = ({dark}) => {
  return (
    <div className={dark ? styles.darksuccess : styles.success} style={{padding:"5rem 1.5rem"}}>
      <div>
        <img src={success} alt="success" />
        <p>
          Your account has been
          <br />
          created successfully!
          <br />
          Please check your mail 
          <br />
          for confirmation email to use your account.
          <br />
          Please check spam if needed.
        </p>
      </div>
    </div>
  );
};

export default Success;
