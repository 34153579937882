import React from "react";
import styles from "./Header.module.css";
import { Breadcrumbs, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import success from "../../../Assets/svg/success.svg"
import verify from "../../../Assets/png/kyc.png";

const Header = ({ breadcrumbs, message,title,profile }) => {
  return (
    <div className={styles.header}>
      <Breadcrumbs className={styles.breadcrumb} aria-label="breadcrumb">
        <Link className={styles.links} to="/dashboard">
         {title?title: "Dashboard"}
        </Link>
        {breadcrumbs}
      </Breadcrumbs>
    
      <p className={styles.headerText} >{message}{" "}{profile?.infoApprove &&
       <span style={{lineHeight:"0"}}>        
       <Typography component={"img"} src={success} width={"18px"} sx={{verticalAlign:"middle"}}/>{" "}<span style={{fontSize:"18px",lineHeight:"0"}}>Verified</span>
       </span>
       }  {profile?.kycStatus === "Approved"&&   <img
        src={verify}
        alt="KYC Verified"
        style={{ width:"30px" }}
      />}</p>
    </div>
  );
};

export default Header;
