import React from "react";
import styles from "./Team.module.css";
import anuj from "../../Assets/png/anuj.png";
import dave from "../../Assets/png/dave.png";
import stephane from "../../Assets/png/stephane.png";
import rey from "../../Assets/png/rey.png";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const Team = () => {
  const [open, setOpen] = React.useState(false);
  const [content, setContent] = React.useState({
    name: "",
    desc: "",
  });

  const handleClickOpen = ({ name, desc }) => {
    setContent({ name, desc });
    setOpen(true);
  };

  const handleClose = () => {
    setContent({ name: "", desc: "" });
    setOpen(false);
  };
  return (
    <div className={styles["container"]}>
      <div
        onClick={() =>
          handleClickOpen({
            name: "Dave Karshaw",
            desc: "Dave has been a driving force in the Vancouver nightlife scene since 1996, owning and operating some of the city's most iconic venues. His success is due in part to his vast knowledge in areas such as marketing, social media management, team leadership, and design and concept development. In recent years, Dave has become a well-renowned crypto enthusiast, investor, and trader. He is now looking to bring his same level of Dedication and entrepreneurial skill set to the crypto industry.",
          })
        }
        className={styles["box-callout"]}
      >
        <figure>
          <img src={dave} alt="" className={styles["img-responsive"]} />
          <div className={styles["box-title"]}>
            <h3>Dave Kershaw</h3>
            <h3 className={styles.subtitle}>CEO / Co-Founder</h3>
          </div>
        </figure>
      </div>
{/* 
      <div
        onClick={() =>
          handleClickOpen({
            name: "Stéphane Himbert",
            desc: "Stephane was born and raised in France and holds a master's degree in Systems and Networking. He has worked for multinational information technology services and consulting company Capgemini, where he participated in the organization of the 2007 Rugby World Cup in France. Since then, he has held multiple positions in different startups, helping them realize their full potential. He has been involved in the blockchain/crypto world since 2016 and developed a real passion for it. With this project, Stephane believes that the team will offer a niche product where customers will be able to collect Dreamgirls NFT cards and receive real-life benefits.",
          })
        }
        className={styles["box-callout"]}
      >
        <figure>
          <img src={stephane} alt="" className={styles["img-responsive"]} />
          <div className={styles["box-title"]}>
            <h3>Stéphane Himbert</h3>
            <h3 className={styles.subtitle}>CTO / Co-Founder</h3>
          </div>
        </figure>
      </div> */}

      <div
        onClick={() =>
          handleClickOpen({
            name: "Reyfemel Damasco",
            desc: "Reyfemel, also known as Rey Rey, is an experienced entrepreneur and film producer with a proven track record of success. He has over 20 years of experience in various industies and has been involved in the development of many different technologies that are the foundation for today's companies. He is now turning his attention to the Dreamgirls NFT project, which he believes has the potential to be a truly exceptional project.",
          })
        }
        className={styles["box-callout"]}
      >
        <figure>
          <img src={rey} alt="" className={styles["img-responsive"]} />
          <div className={styles["box-title"]}>
            <h3>Reyfemel Damasco</h3>
            <h3 className={styles.subtitle}>COO / Co-Founder</h3>
          </div>
        </figure>
      </div>

      <div
        onClick={() =>
          handleClickOpen({
            name: "Anuj Chopra",
            desc: "As the CMO of DreamGirls, Anuj takes an active role in guiding innovation and strategy within the organization, and has extensive E-Commerce experience understanding latest online trends and customer behaviours. Besides being a successful entrepreneur, Anuj is a commercial pilot, licensed to fly Airbus 320.",
          })
        }
        className={styles["box-callout"]}
      >
        <figure>
          <img src={anuj} alt="" className={styles["img-responsive"]} />
          <div className={styles["box-title"]}>
            <h3>Anuj Chopra</h3>
            <h3 className={styles.subtitle}>CMO / Co-Founder</h3>
          </div>
        </figure>
      </div>

      <div
        onClick={() =>
          handleClickOpen({
            name: "Dave Karshaw",
            desc: "is the approximate order of frequency of the twelve most commonly used letters in the English language, best known as a nonsense phrase that sometimes appeared in print in the days of publishing due to a custom of Linotype machine operators.",
          })
        }
        className={styles["box-callout"]}
        style={{ visibility: "hidden" }}
      >
        <figure>
          <img src={anuj} alt="" className={styles["img-responsive"]} />
          <div className={styles["box-title"]}>
            <h3>Anuj</h3>
            <h3 className={styles.subtitle}>CMO / Co-Founder</h3>
          </div>
        </figure>
      </div>

      <div
        onClick={() =>
          handleClickOpen({
            name: "Dave Karshaw",
            desc: "is the approximate order of frequency of the twelve most commonly used letters in the English language, best known as a nonsense phrase that sometimes appeared in print in the days of publishing due to a custom of Linotype machine operators.",
          })
        }
        style={{ visibility: "hidden" }}
        className={styles["box-callout"]}
      >
        <figure>
          <img src={anuj} alt="" className={styles["img-responsive"]} />
          <div className={styles["box-title"]}>
            <h3>Anuj</h3>
            <h3 className={styles.subtitle}>CMO / Co-Founder</h3>
          </div>
        </figure>
      </div>

      <Dialog open={open} onClose={handleClose}>
        <div className={styles.dialog}>
          <DialogTitle>
            <span>{content.name}</span>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <span className={styles.popupText}>{content.desc}</span>
            </DialogContentText>
          </DialogContent>
        </div>
      </Dialog>
    </div>
  );
};

export default Team;
