import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import styles from "./Stats.module.css";
import CopyToClipboard from "react-copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    // padding: "1rem",
    border: "none",
    "&::-webkit-scrollbar": {
      width: 0,
    },
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{ m: 0, p: "1rem", color: "#800080", borderBottom: "1px solid #00000045" }}
      {...other}
    >
      {children}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const ReferralLinks = ({ referralModal, setReferralModal, username }) => {
  const [isCopied, setIsCopied] = useState(false);
  const [isCopied_1, setIsCopied_1] = useState(false);
  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  };
  const onCopyText_1 = () => {
    setIsCopied_1(true);
    setTimeout(() => {
      setIsCopied_1(false);
    }, 1500);
  };

  
  return (
    <div>
      <BootstrapDialog
        onClose={() => setReferralModal(false)}
        aria-labelledby="customized-dialog-title"
        open={referralModal}
        sx={{
          //   "& .MuiPaper-root": {
          //     overflowY: "inherit",
          //   },
          "& .MuiDialog-paper": {
            width: "500px",
            maxWidth: "900px",
            background: "#fff",
            border: "1px solid rgba(255, 255, 255, 0.2)",
            borderRadius: "10px",
          },
          "& .MuiDialog-container": {
            background: "rgb(0 0 0 / 36%)",
          },
        }}
      >
        <BootstrapDialogTitle id="customized-dialog-title">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                fontSize: "18px",
                lineHeight: "22.24px",
                fontWeight: "400",
                color: "#000",
                display: "flex",
                gap: "10px",
                alignItems: "center",
                fontFamily: "Exo 2 !important",
              }}
            >
              Referral Links
            </Typography>
            <CloseIcon
              sx={{ fontSize: "1.5rem", color: "#000", cursor: "pointer" }}
              onClick={() => setReferralModal(false)}
              title="Close"
            />
          </Box>
        </BootstrapDialogTitle>
        <DialogContent
          dividers
          sx={{
            px: "1rem",
            py: "2rem",
          }}
        >
          <Box color={"#000"}>
            <Typography
              sx={{
                fontSize: {sm:"18px",xs:"15px"},
                lineHeight: "22.24px",
                fontWeight: "400",
                color: "#000",
                display: "flex",
                gap: "10px",
                alignItems: "center",
                fontFamily: "Exo 2 !important",
                textDecoration: "underline",
              }}
            >
              Referral Link for girl
            </Typography>
            <Typography
              className={styles.referralCode}
              sx={{
                fontSize: "1.5rem",
                fontWeight: "600",
                "@media (max-width:600px)": {
                  fontSize: "1rem",
                },
              }}
            >
              <span
                style={{
                  pointerEvents: "none",
                }}
              >{`dreamgirlsnft.com/..${username}`}</span>

              {/* <button className={styles.copyButton} onClick={handleClick}>
              <img src={copy} alt="Copy" />
            </button> */}
              <CopyToClipboard
                text={`${window.location.hostname}/dreamgirl-application?referredBy=${username}`}
                onCopy={onCopyText}
              >
                <Tooltip
                  arrow
                  title={isCopied ? "Copied" : "Copy"}
                  placement="top"
                >
                  <ContentCopyIcon sx={{ cursor: "pointer", ml: "5px", fontSize: {sm:"22px",xs:"16px"}, }} />
                </Tooltip>
              </CopyToClipboard>
            </Typography>
          </Box>
          <Box color={"#000"} mt={"1rem"}>
            <Typography
              sx={{
                fontSize: {sm:"18px",xs:"15px"},
                lineHeight: "22.24px",
                fontWeight: "400",
                color: "#000",
                display: "flex",
                gap: "10px",
                alignItems: "center",
                fontFamily: "Exo 2 !important",
                textDecoration: "underline",
              }}
            >
              Referral Link for photographer
            </Typography>
            <Typography
              className={styles.referralCode}
              sx={{
                fontSize: "1.5rem",
                fontWeight: "600",
                "@media (max-width:600px)": {
                  fontSize: "1rem",
                },
              }}
            >
              <span
                style={{
                  pointerEvents: "none",
                }}
              >{`dreamgirlsnft.com/..${username}`}</span>

              {/* <button className={styles.copyButton} onClick={handleClick}>
              <img src={copy} alt="Copy" />
            </button> */}
              <CopyToClipboard
                text={`${window.location.hostname}/photograph?referredBy=${username}`}
                onCopy={onCopyText_1}
              >
                <Tooltip
                  arrow
                  title={isCopied_1 ? "Copied" : "Copy"}
                  placement="top"
                >
                  <ContentCopyIcon sx={{ cursor: "pointer", ml: "5px",fontSize: {sm:"22px",xs:"16px"}}} />
                </Tooltip>
              </CopyToClipboard>
            </Typography>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};

export default ReferralLinks;
