import React from "react";
import Slider from "../Slider/Slider";
import styles from "./EarlyAccess.module.css";
import { Link } from "react-router-dom";

const EarlyAccess = () => {
  return (
    <div className={styles.earlyAccess}>
      <div className={styles.header}>
        <div>
          <h3 className={styles.heading}>EARLY ACCESS</h3>
        </div>
      </div>
      <br />
      <div className={styles.header}>
        <Link className={styles.link} to="/whitelist">
          <button className={styles.apply}>Get On Whitelist</button>
        </Link>
      </div>
      <br />
      <br />
      <Slider />
    </div>
  );
};

export default EarlyAccess;
