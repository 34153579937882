import React from 'react'
import styles from "./PhotosList.module.css";
import Delete from "../../../Assets/svg/Delete.svg";
import DeleteImage from "./DeleteImage";
import { Box, Button, Grid, Typography } from "@mui/material";

const ImageList = ({image,setGirlsImages,profile}) => {
    const [deleteOpen, setDeleteOpen] = React.useState(false);
  return (
    <div>
           <Box  position={"relative"} className={styles.image}>
           <Typography component={"img"} className={styles.imageComp}
           sx={{
            borderRadius:"5px"
           }}
                  src={`${image?.url}`}
                  alt={image.name}
                    width={"100%"}
                   
                  />
             {profile?.status == "Incomplete" && 
               <button
                //  onClick={() => handleDelete(image?.id)}
                onClick={()=>setDeleteOpen(true)}
                style={{
                  position:"absolute",
                  top:"10px",
                  right:"10px",
                }}
                title="Delete"
                 >
                  <img src={Delete} alt="Delete" />
                </button>}
           </Box>
                {/* <img
                  className={styles.imageComp}
                  src={`${image?.url}`}
                  alt={image.name}
                /> */}
          
              <DeleteImage deleteOpen={deleteOpen} setDeleteOpen={setDeleteOpen} imageId={image?.id} setGirlsImages={setGirlsImages}/>
    </div>
  )
}

export default ImageList