import React from "react";
import Navbar from "../Component/Navbar/Navbar";
import Hero from "../Component/Hero/Hero";
import Number from "../Component/Numbers/Number";
import Testimonial from "../Component/Testimonial/Testimonial";
import Footer from "../Component/Footer/Footer";
import Partner from "../Component/Partner/Partner";
import Timeline from "../Component/Timeline/Timeline";
import Utility from "../Component/Utility/Utility";
import EarlyAccess from "../Component/EarlyAccess/EarlyAccess";
const Home = () => {
  return (
    <>
      <Navbar />
      <Hero />
      <Number />
      <EarlyAccess />
      <Timeline para={"none"} />
      <Testimonial />
      <Partner />
      <Utility />
      <Footer />
    </>
  );
};

export default Home;
