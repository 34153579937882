import React from "react";
import Navbar from "../Component/Navbar/Navbar";
import Login from "../Component/Auth/Login";

const Signin = () => {
  return (
    <>
      <Navbar />
      <Login />
    </>
  );
};

export default Signin;
