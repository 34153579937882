import React, { useEffect, useState } from "react";
import styles from "./SalesList.module.css";
import axios from "axios";
import { TOKEN } from "../../../Helper/Constants";
import { TableCell, TableRow } from "@mui/material";
const SaleListRow = ({ index, user }) => {
  const [earningData, setEarningData] = useState([]);
  const [affiliate, setAffiliate] = useState(0);
  const [earningRef, setEarningRef] = useState(0);

  const getAllEarning = async (next) => {
    try {
      const config = {
        headers: {
          authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      };
      const data = await axios.get(
        `${process.env.REACT_APP_BACKEND}/get-aff-earning-by-userid/${user?.id}`,
        config
      );
      // setEarningData(data.data);
      setEarningRef(data.data.amount)
    } catch (error) {
      console.log("catch", error);
    }
  };
  // const affiliateEarnings =
  //   earningData?.girlEarning?.map(
  //     (e) => parseInt(e.girl_earning) / 1e1 ** process.env.REACT_APP_DECIMAL,
  //   ) || [];

  // useEffect(() => {
  //   const calculateTotalEarnings = (earnings, setEarnings) => {
  //     const totalEarnings = earnings.reduce(
  //       (accumulator, currentValue) => accumulator + currentValue,
  //       0,
  //     );
  //     setEarnings(totalEarnings);
  //   };
  //   calculateTotalEarnings(affiliateEarnings, setAffiliate);
  // }, [affiliateEarnings]);

  useEffect(() => {
    getAllEarning();
  }, [user?.id]);
  return (
    <>
     <TableRow
              key={user.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 }, }}
            >
              <TableCell scope="row">
              {user.username}
              </TableCell>
              <TableCell align="right"> {user.firstName} {user.lastName}</TableCell>
              <TableCell align="right"> {user.status}</TableCell>
              <TableCell align="right">{user.confirmed ? "Yes" : "No"}</TableCell>
              <TableCell align="right">${parseFloat(earningRef).toFixed(2)}</TableCell>
              
            </TableRow>
    </>
  );
};
export default SaleListRow;
