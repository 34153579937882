import React from "react";
import styles from "./Testimonial.module.css";
import partner from "../../Assets/png/partner.png";
import { Link } from "react-router-dom";

const Testimonial = () => {
  return (
    <div className={styles.Testimonial}>
      <div className={styles.display}>
        <div>
          <h3 className={styles.show}>Become a</h3>
          <h3 className={styles.show}>Dream Girl</h3>
          <p className={styles.desc}>We'll take care of everything else</p>
          <Link className={styles.link} to="/dreamgirl-application">
            <button className={styles.apply}>Apply now</button>
          </Link>
        </div>
        <div>
          <img src={partner} alt="Partner" />
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
