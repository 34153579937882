import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Input from "../Input/Input";
import Button from "../Button/Button";

import styles from "./Forms.module.css";
import axios from "axios";
import Success from "./Success";
import Loader from "../Loader/Loader";

const AffiliateForm = () => {
  const [status, setStatus] = React.useState("idle");
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      wallet: "",
      description: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
      email: Yup.string().email("Invalid email address").required("Required"),
      phone: Yup.string().required("Required"),
      wallet: Yup.string().required("Required"),
      description: Yup.string(),
    }),
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      const whitelist = `${process.env.REACT_APP_BACKEND}/whitelists`;
      try {
        const res = await axios.post(whitelist, { data: values });

        if (res.status === 200) {
          setStatus("success");
          resetForm();
        }
      } catch (err) {
        console.log(err);
      }
    },
  });

  return (
    <div className={styles.container}>
      <div className={styles.childContainer}>
        <div className={styles.heroText}>
          <h1>Get on whitelist for Early Access</h1>
          <p>
            By completing this form, you will be one of the first to have access
            to our Dream Girls NFT mint. Simply complete and submit the form and
            your wallet will be whitelisted. Don't miss your chance to get in on
            the action early!
          </p>
          <br />
          <p>
            You will receive a FREE NFT for everyone you purchase! But a pack of
            5 and receive a FREE pack of 5 additional Dream Girls NFTs at no
            cost!
          </p>
        </div>
      </div>
      <div className={styles.childContainer}>
        <div className={styles.formContainer}>
          {status === "idle" ? (
            <form onSubmit={formik.handleSubmit}>
              <div className={styles.inputs}>
                <Input
                  id="name"
                  name="name"
                  type="text"
                  label="Full Name*"
                  placeholder="Miss Lee"
                  error={formik.errors.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  touched={formik.touched.name ? 1 : 0}
                />
                <Input
                  id="email"
                  name="email"
                  type="email"
                  label="Email Address*"
                  placeholder="name@example.com"
                  error={formik.errors.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email.trim()}
                  touched={formik.touched.email ? 1 : 0}
                />
                <Input
                  id="phone"
                  name="phone"
                  type="tel"
                  label="Phone number*"
                  placeholder="Enter your phone"
                  error={formik.errors.phone}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phone.trim()}
                  touched={formik.touched.phone ? 1 : 0}
                />
                <Input
                  id="wallet"
                  name="wallet"
                  type="text"
                  label="Polygon wallet address*"
                  placeholder="yourwalletaddress"
                  error={formik.errors.wallet}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.wallet.trim()}
                  touched={formik.touched.wallet ? 1 : 0}
                />
                <Input
                  id="description"
                  name="description"
                  type="text"
                  label="Anything you'd like to tell us?"
                  placeholder="Type here..."
                  error={formik.errors.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.description.trim()}
                  touched={formik.touched.description ? 1 : 0}
                />
              </div>
              <div className={styles.buttonContainer}>
                <Button disabled={formik.isSubmitting} type="submit">
                  {formik.isSubmitting ? <Loader /> : "Submit"}
                </Button>
              </div>
            </form>
          ) : (
            <Success />
          )}
        </div>
      </div>
    </div>
  );
};

export default AffiliateForm;
