import { Box, Typography } from '@mui/material'
import React from 'react'
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteImage from './DeleteImage';


const ImageList = ({data,fetchPhotographerUsers,user}) => {
    
    const [deleteOpen, setDeleteOpen] = React.useState(false);
  return (
  <Box position={"relative"} lineHeight={0}>
      <Typography component={"img"} src={data?.url} width={"100%"}/>
      <Box sx={{position:"absolute",top:"10px",right:"10px",cursor:"pointer",color:"red"}} onClick={()=>setDeleteOpen(true)}>
      {user?.status == "Incomplete" && <DeleteIcon/>}
      </Box>
      <Box>
        <DeleteImage imageId={data?.id} deleteOpen={deleteOpen} setDeleteOpen={setDeleteOpen} fetchPhotographerUsers={fetchPhotographerUsers}/>
      </Box>
  </Box>
  )
}

export default ImageList