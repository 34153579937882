import React, { createContext, useState } from "react";

export const ContractContext = createContext();
const ContractContextProvider = ({ children }) => {
  const [value, setValue] = useState(0);

  return (
    <ContractContext.Provider value={{ value, setValue }}>
      {children}
    </ContractContext.Provider>
  );
};
export default ContractContextProvider;
