import React from "react";
import AffiliateForm from "../Component/Forms/AffiliateForm";
import Navbar from "../Component/Navbar/Navbar";
import Footer from "../Component/Footer/Footer";
import { Helmet } from "react-helmet-async";

const Affiliate = () => {
  return (
    <>
      <Helmet>
        <title>Affiliate Application</title>
        <meta
          name="description"
          content="
          Thanks for your interest in becoming a Dream Girls Affiliate! You will earn a percentage of every NFT sold from every girl you directly refer to our platform. NFTs will be sold singularly for $20 USD or in packs of 5, 10, 25 or 50!  Customers can purchase the NFTs with crypto or credit card.

          For every girl you refer that decides to partner with us you will receive 10% of her NFT sales.  Refer 5 girls and become a Gold Partner and receive 12% of all the NFT sales from your referrals.  Refer 10, become a Platinum Partner and receive 15%.  When your referrals NFT collection sells out you can earn up to  $6240 USD! As a Gold Partner this number would be $7480 and as a Platinum Partner $9360. You will have your choice of receiving payment via direct deposit or through crypto.

          To get stated as an affiliate please complete the application form and a representative will be in touch with you ASAP!"
        />
      </Helmet>
      <Navbar />
      <AffiliateForm />
      <Footer />
    </>
  );
};

export default Affiliate;
