import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Input from "../Input/Input";
import Button from "../Button/Button";
import styles from "./Auth.module.css";
import axios from "axios";
import { TOKEN, USERTYPE, USER, JWT } from "../../Helper/Constants";
import Loader from "../Loader/Loader";

const Changepassword = () => {
  const formik = useFormik({
    initialValues: {
      password: "",
      currentPassword: "",
      passwordConfirmation: "",
    },
    validationSchema: Yup.object({
      currentPassword: Yup.string()
      .trim("Password should not contain spaces")
      .min(8, "Password must be at least 8 characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        "Password should contain at least one uppercase letter, one lowercase letter, one number, one special character."
      )
      .required("Required"),

      password: Yup.string()
      .trim("Password should not contain spaces")
      .min(8, "Password must be at least 8 characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        "Password should contain at least one uppercase letter, one lowercase letter, one number, one special character."
      )
      .required("Required"),

      // passwordConfirmation must match password
      passwordConfirmation: Yup.string()
        .oneOf([Yup.ref("password"), null], "Password must match")
        .min(8, "Password must be at least 8 characters")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
          "Password should contain at least one uppercase letter, one lowercase letter, one number, one special character."
        )
        .required("Required"),
    }),
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      const ChangePassword = `${process.env.REACT_APP_BACKEND}/auth/change-password`;

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(JWT)}`,
          "Content-Type": "application/json",
        },
      };

      //call api to ChangePassword
      try {
        const response = await axios.post(
          ChangePassword,
          {
            password: values.password,
            currentPassword: values.currentPassword,
            passwordConfirmation: values.passwordConfirmation,
          },
          config,
        );

        console.log("response", response.status);
        // check if response is ok
        if (response.status === 200) {
          // set token in local storage
          localStorage.setItem(TOKEN, response.data.jwt);
          // set usertype in local storage
          localStorage.setItem(USERTYPE, response.data.user.userType);
          // set user in local storage
          localStorage.setItem(USER, JSON.stringify(response.data.user));
          // redirect to dashboard
          window.location.href = "/dashboard";
        }
      } catch (e) {
        setSubmitting(false);
        console.log("e", e.code);
        if (e.code === "ERR_NETWORK") {
          alert("Network Error");
        }
        if (e.code === "ERR_BAD_REQUEST") {
          setErrors({ password: "Invalid usernmae or password!" });
        }
      }
    },
  });

  return (
    <div className={styles.container}>
      <div className={styles.formContainer}>
        <h1>Please enter following details to change your password!</h1>
        <form onSubmit={formik.handleSubmit}>
          <Input
            id="currentPassword"
            name="currentPassword"
            type="password"
            label="Current Password*"
            placeholder="Enter your current password"
            error={formik.errors.currentPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.currentPassword.trim()}
            touched={formik.touched.currentPassword ? 1 : 0}
            width={"372px"}
            eye={true}
          />

          <Input
            id="password"
            name="password"
            type="password"
            label="Password*"
            placeholder="Enter your password"
            error={formik.errors.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password.trim()}
            touched={formik.touched.password ? 1 : 0}
            width={"372px"}
            eye={true}
          />

          <Input
            id="passwordConfirmation"
            name="passwordConfirmation"
            type="password"
            label="Confirm Password*"
            placeholder="Enter your password"
            error={formik.errors.passwordConfirmation}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.passwordConfirmation.trim()}
            touched={formik.touched.passwordConfirmation ? 1 : 0}
            width={"372px"}
            eye={true}
          />

          <div className={styles.buttonContainer}>
            <Button
              width={"372px"}
              disabled={formik.isSubmitting}
              type="submit"
            >
              {formik.isSubmitting ? <Loader /> : "Change Password"}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Changepassword;
