import React from "react";
import styles from "./Disclaimer.module.css";

const DisclaimerContent = () => {
  return (
    <div className={styles.disclaimer}>
      <h3>Disclaimer</h3>
      <p>
        KINDLY READ THIS SECTION CAREFULLY. NOTHING IN THIS WHITEPAPER
        CONSTITUTES LEGAL, TAX, FINANCIAL, AND/OR PROFESSIONAL ADVICE. AND YOU
        SHOULD CONSULT A PROFESSIONAL ADVISOR BEFORE ENGAGING IN ACTIVITY
        CONNECTED WITH THIS DOCUMENT. NEITHER Taboo Token, ANY OF THE PROJECT
        TEAM MEMBERS WHO HAVE WORKED ON THE Taboo Token PLATFORM, NOR SERVICE
        PROVIDERS SHALL BE LIABLE FOR ANY DAMAGE OR LOSS WHATSOEVER WHICH YOU
        MAY SUFFER IN CONNECTION WITH THIS DOCUMENT, THE WEBSITE, OR OTHER
        MATERIALS PUBLISHED UNDER Dream Girls NFT or Dream Girls Token.
      </p>
      <h4>ELIGIBILITY</h4>
      <p>
        You are not eligible to purchase any Dream Girls Token Initial
        Decentralized Offering and Public Sale (as referred to in this
        Whitepaper) if you are a citizen, resident (tax or otherwise), or green
        card holder of the United States of America or Singapore, or any country
        where trading of cryptocurrency token is Illegal.
      </p>
      <h4>NO ADVICE</h4>
      <p>
        This Whitepaper does not constitute or form part of any opinion on any
        advice to sell, or any solicitation of any offer by the Team to sell any
        Taboo tokens nor shall it or any part of it nor the fact of its
        presentation form the basis of, or be relied upon in connection with,
        any contract or investment decision. No information in this Whitepaper
        should be considered to be business, legal, and financial or tax advice
        regarding Dream Girls Token, any additional tokens developed or
        launched, and the Dream Girls Initial Token Sale. You should consult
        your own legal, financial, tax, or other professional advisers regarding
        Dream Girls Token and its respective businesses and operations, the
        Dream Girls tokens, the Dream Girls Private and Public Token Sale, and
        the Dream Girls Token and all upcoming tokens, platforms, pools, or
        other interactive platform aspects. You should be aware that you may be
        required to bear the financial risk of any purchase of Dream Girls
        tokens for an indefinite period.
      </p>
      <h4>NO FURTHER INFORMATION OR UPDATE</h4>
      <p>
        No person has been or is authorized to give any information or
        representation not contained in this Whitepaper in connection with Dream
        Girls Token and its respective businesses and operations, the Dream
        Girls tokens, the Dream Girls Private and Public Token Sales, any
        additional tokens that may be developed and, if given, such information
        or representation must not be relied upon as having been authorized by
        or on behalf of Dream Girls Token. The Dream Girls Private and Public
        Token Sale (as referred to in the Whitepaper) shall not, under any
        circumstances, constitute a continuing representation or create any
        suggestion or implication that there has been no change, or development
        reasonably likely to involve a material change in the affairs,
        conditions and prospects of Taboo Token or in any statement of fact or
        information contained in this Whitepaper since the date hereof.
      </p>
      <h4>NO OFFER OF SECURITIES OR REGISTRATION</h4>
      <p>
        {`No person has been or is authorized to give any information or representation not contained in this Whitepaper in connection with Dream Girls Token and its respective businesses and operations, the Dream Girls tokens, the Dream Girls Private and Public Token Sales, any additional tokens that may be developed and, if given, such information or representation must not be relied upon as having been authorized by or on behalf of Dream Girls Token. The Dream Girls Private and Public Token Sale (as referred to in the Whitepaper) shall not, under any circumstances, constitute a continuing representation or create any suggestion or implication that there has been no change, or development reasonably likely to involve a material change in the affairs, conditions and prospects of Taboo Token or in any statement of fact or information contained in this Whitepaper since the date hereof.`}
      </p>
      <h4>REGULATION</h4>
      <p>
        No regulatory authority has examined or approved of any of the
        information set out in this Whitepaper. No such action has been or will
        be taken under the laws, regulatory requirements, or rules of any
        jurisdiction. The publication, distribution, or dissemination of this
        Whitepaper does not imply that the applicable laws, regulatory
        requirements, or rules have been complied with
      </p>
    </div>
  );
};

export default DisclaimerContent;
