import React from "react";
import styles from "./VideoButton.module.css";
import youtube from "../../../Assets/png/youtube.png";
import { Link } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";

const VideoButton = ({ userData, profile }) => {
  const userType = profile?.user_type;
  return (
    <>
      {/* <div className={styles.videoButton}>
      <div className={styles.heroText}>
        <p className={styles.header}>
          Level up your game by watching our video tutorials
        </p>
        <Link to="/watch-video">
          <button className={styles.button}>Let's Go</button>
        </Link>
      </div>
      <div className={styles.videoImageContainer}>
        <img className={styles.videoImage} src={youtube} alt="video" />
      </div>
    </div> */}
      <Box
        sx={{
          background: "#303034",
          border: "1px solid #eeeeee",
          boxShadow: "0px 4px 16px rgba(103, 103, 103, 0.04)",
          borderRadius: "16px",
          p: "1rem 2rem",
        }}
      >
        <div>
          <Typography
            sx={{
              fontSize: "28px",
              lineHeight: "34px",
              color: "#f2f2f2",
              width: "500px",
              margin: "0 auto",
              textAlign: "center",
              paddingTop: "0.5rem",
              "@media (max-width:800px)": {
                width: "100%",
              },
            }}
          >
            Level up your game by watching our video tutorials
          </Typography>
          {/* <Link to="/watch-video">
            <button className={styles.button} style={{ marginTop: "20px" }}>
              Let's Go
            </button>
          </Link> */}
          <Grid container spacing={2} mb={"2rem"} mt={"1rem"}>
            {userData
              ?.filter((user) => user?.user_type === userType)
              .map((filteredUser) =>
                filteredUser?.videoData?.map((videoUrl, i) => (
                  <Grid item md={6} sm={12} xs={12} key={i}>
                    <iframe
                      width="100%"
                      height="300px"
                      style={{ borderRadius: "10px" }}
                      src={videoUrl}
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                  </Grid>
                ))
              )}
          </Grid>
        </div>

        {/* <img src={youtube} style={{ width: "200px" }} alt="video" /> */}
      </Box>
    </>
  );
};

export default VideoButton;
