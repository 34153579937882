import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import styles from "./Forms.module.css";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Box, Typography } from "@mui/material";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function Dropzone({
  onchange,
  error = null,
  styles,
  result,
  setIsCropped,
  isCropped,
  setResult,
  ...other
}) {
  const [src, selectFile] = useState(null);
  const [uploadErr, setUploadErr] = useState("");
  
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      handleFileChange(acceptedFiles[0]);
    },
  });

  // const handleFileChange = (file) => {
  //   selectFile(URL.createObjectURL(file));
  //   setIsCropped(false);
  //   const base64Image = URL.createObjectURL(file);
  //   setResult(base64Image);
  //   onchange(base64Image);
  // };
  const handleFileChange = (file) => {
    selectFile(null)
    const reader = new FileReader();
    reader.onload = function (event) {
        const image = new Image();
        image.src = event.target.result;
        image.onload = function () {
            // if (image.width === 780 && image.height === 1140) {
                selectFile(URL.createObjectURL(file));
                setIsCropped(false);
                const base64Image = URL.createObjectURL(file);
                setResult(base64Image);
                onchange(base64Image);
            // } else {
            //     // Display error message or take appropriate action
            //     setUploadErr("Please upload an image with dimensions 780px x 1140px.");
            // }
        };
    };
    reader.readAsDataURL(file);
};


  return (
    <>
      {src == null ? (
        <div {...getRootProps()}>
          <input {...getInputProps()} accept="image/*" />
          <Box
            sx={{
              textAlign: "center",
              borderRadius: "10px",
              border: "2px dashed #00000030",
              padding: "4rem 1rem",
              background: "#0000000a",
            }}
          >
            <Typography>
              <CloudUploadIcon
                sx={{
                  color: "#00000030",
                  fontSize: "3.5rem",
                }}
              />
            </Typography>
            <Typography>
              Drag & Drop or Click{" "}
              <span style={{ color: "blueviolet", cursor: "pointer" }}>
                here
              </span>{" "}
              to Upload
            </Typography>
          </Box>
          { uploadErr &&<Typography sx={{
            color:"red"
          }}>
              {uploadErr}
            </Typography>}
        </div>
      ) : (
        <div {...getRootProps()}>
          <input {...getInputProps()} accept="image/*" />
          <Button
            variant="outlined"
            sx={{
              mb: "1rem",
              width: "150px",
              background: "linear-gradient(180deg, #3b3b3b 0%, #1d1e1f 100%)",
              border: "1px solid transparent",
              color: "#fff",
            }}
            startIcon={<CloudUploadIcon />}
          >
            Upload
          </Button>
        </div>
      )}

      {src && (
        <div>
          {/* <Box
            sx={{
              fontSize: "18px",
              fontWeight: "600",
            }}
          >
            Original Image:
          </Box> */}
          <Box
            sx={{
              width: "340px",
              height: "570px",
              lineHeight: "0",
            }}
          >
            <img src={src} alt="original" style={{ width: "100%", height: "auto" }} />
          </Box>
          {/* <Typography
            sx={{
              fontSize: "18px",
              pt: "1rem",
            }}
          >
            <span style={{ fontWeight: "700" }}>Note:</span> Please crop your
            image before uploading.
          </Typography> */}
        </div>
      )}
    </>
  );
}

const ImagesPreview = ({ files }) => {
  return (
    <>
      <Box
        sx={{
          fontSize: "18px",
          fontWeight: "600",
        }}
      >
        Image Preview:
      </Box>
      <ImageList
        sx={{ width: "100%", mt: "1rem" }}
        variant="quilted"
        cols={1}
        rowHeight={"auto"}
      >
        <ImageListItem>
          <img src={files} alt="cropped image" />
        </ImageListItem>
      </ImageList>
    </>
  );
};

export default Dropzone;
