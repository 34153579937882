import React from 'react';
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

//screens to render
import Home from "./screens/Home";
import About from "./screens/About";
import Disclaimer from "./screens/Disclaimer";
import Teams from "./screens/Team";
import Whitelist from "./screens/Whitelist";
import Business from "./screens/Business";
import SignIn from "./screens/SignIn";

import { Fragment, useLayoutEffect } from "react";
import ScrollToTop from "./ScrollToTop";
import Affiliate from "./screens/Affiliate";
import Photograph from "./screens/Photograph";
import DreamGirl from "./screens/DreamGirl";

import Dashboard from "./screens/Dashboard";
import PrivateRoute from "./Helper/PrivateRoute";
import Profile from "./screens/Profile";
import ChangePassword from "./screens/ChangePassword";
import Videos from "./screens/Videos";
import ForgotPassword from "./screens/ForgotPassword";
import ResetPassword from "./screens/ResetPassword";
import { HelmetProvider } from "react-helmet-async";
import WithdrawalList from './screens/WithdrwalList';
import Earnings from './screens/Earnings';
import Marketplace from './screens/Marketplace/Marketplace';
import NftDetail from './screens/Marketplace/NftDetail/NftDetail';
import GirlNftDetail from './screens/Marketplace/GirlNftDetail/GirlNftDetail';
import KycPage from './screens/KycPage/KycPage';

function App() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <HelmetProvider>
      <Router>
        <Fragment>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/roadmap" element={<About />} />
            <Route path="/disclaimer" element={<Disclaimer />} />
            <Route path="/team" element={<Teams />} />
            <Route path="/whitelist" element={<Whitelist />} />
            <Route path="/business" element={<Business />} />
            <Route path="/affiliate" element={<Affiliate />} />
            <Route path="/photograph" element={<Photograph />} />
            <Route path="/dreamgirl-application" element={<DreamGirl />} />
            <Route path="/watch-video" element={<Videos />} />
            <Route path="/collections" element={<Marketplace />} />
            {/* <Route path="/marketplace/:id" element={<NftDetail />} /> */}
            <Route path="/collection/:id/:collectionId" element={<GirlNftDetail />} />
            {/* Dashboard */}

            <Route path="/signin" element={<SignIn />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />

            {/* private routes */}

            <Route
              path="/dashboard"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/profile"
              element={
                <PrivateRoute>
                  <Profile />
                </PrivateRoute>
              }
            />
            <Route path="/withdrawl-list" element={<PrivateRoute>
              <WithdrawalList />
                </PrivateRoute>} />
            <Route path="/earnings" element={<PrivateRoute>
              <Earnings />
                </PrivateRoute>} />
            <Route path="/kyc" element={<PrivateRoute>
              <KycPage />
                </PrivateRoute>} />

            <Route
              path="/change-password"
              element={
                <PrivateRoute>
                  <ChangePassword />
                </PrivateRoute>
              }
            />
          </Routes>
        </Fragment>
      </Router>
    </HelmetProvider>
  );
}

export default App;
