import React, { useContext, useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
// import { Button, Container, Grid } from "@mui/material";
// import { useNavigate, useLocation } from "react-router-dom";
// import { useAccount } from "wagmi";
import EditDreamGirlForm from "../../Component/Dashboard/Forms/EditDreamGirlForm";
import EditAffiliateForm from "../../Component/Dashboard/Forms/EditAffiliateForm";
// import { useProfile } from "../../Component/Dashboard/Hooks/useDashboard";
import { TOKEN } from "../../Helper/Constants";
import axios from "axios";
import EditPhotographer from "../../Component/Dashboard/Forms/EditPhotographer";
import { useProfile } from "../../Component/Dashboard/Hooks/useDashboard";
import FinancialForm from "../../Component/Dashboard/Forms/FinancialForm";
import ProfileCategoryForm from "../../Component/Dashboard/Forms/ProfileCategoryForm";
import { ContractContext } from "../../Context/ContractContext";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ProfileTabs = () => {
  const profile = useProfile();
  const { value, setValue } = useContext(ContractContext);
  // const [value, setValue] = useState(0
  // //   () => {
  // //   const tabValue = parseInt(localStorage.getItem("selectedTab"));
  // //   return isNaN(tabValue) ? 0 : tabValue;
  // // }
  // );

  const handleChange = (event, newValue) => {
    setValue(newValue);
    // navigate(`/profile/${tabNames[newValue]}`);
  };

  // useEffect(() => {
  //   localStorage.setItem("selectedTab", value); 
  // }, [value]);

  return (
    <>
      <Box
        sx={{
          mt:"-20px",
          "& input": {
            color: "#000 !important",
          },
          position: "relative",
          "@media (max-width:991px)": {
            padding: "20px",
          },
          "@media (max-width:600px)": {
            padding: "10px",
            // "& .MuiTabs-scroller":{
            //   overflowX:"scroll !important"
            // }
          },
          "& .MuiButtonBase-root.Mui-selected": {
            color: "#c8c862 !important",
            textShadow: "inherit !important",
            fontSize: {md:"18px !important",xs:"16px !important"},
            padding: "10px !important",
          },
          "& .MuiButtonBase-root": {
            color: "#fff !important",
            fontSize: {md:"18px !important",xs:"16px !important"},
          },
          "& .MuiTabs-indicator": {
            backgroundColor: "#c8c862 !important",
          },
          "& .MuiTouchRipple-root": {
            display: "none",
          },
        }}
      >
        <Box>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                sx={{
                  fontSize: "18px !important",
                  padding: "10px !important",
                }}
                label="Basic Info"
                {...a11yProps(0)}
              />
              <Tab
                sx={{
                  fontSize: "18px !important",
                  padding: "10px !important",
                }}
                label="Payment Info"
                {...a11yProps(1)}
              />
              {profile?.user_type == "Girl" && (
                <Tab
                  sx={{
                    fontSize: "18px !important",
                    padding: "10px !important",
                  }}
                  label="My Look"
                  {...a11yProps(2)}
                />
              )}
            </Tabs>
          </Box>

          <TabPanel value={value} index={0}>
            <EditAffiliateForm profile={profile} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <FinancialForm />
          </TabPanel>
          {profile?.user_type == "Girl" && (
            <TabPanel value={value} index={2}>
              <ProfileCategoryForm />
            </TabPanel>
          )}
        </Box>
      </Box>
    </>
  );
};

export default ProfileTabs;
