import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import styles from "../../../Component/Dashboard/Container/Container.module.css";
import Navbar from "../../../Component/Navbar/Navbar";
import Footer from "../../../Component/Footer/Footer";
import { useParams } from "react-router-dom";
import axios from "axios";
import GirlCollection from "./GirlCollection";
import { CrossmintPayButton } from "@crossmint/client-sdk-react-ui";

const GirlNftDetail = () => {
  const { id, collectionId } = useParams();

  const [girlData, setGirlData] = useState({});

  useEffect(() => {
    getGirlData();
  }, []);
  const [girlNft, setGirlNft] = useState("");
  const handleGirlNft = (e) => {
    setGirlNft(e.target.value);
  };
  const getGirlData = async () => {
    await axios({
      method: "GET",
      url: `${process.env.REACT_APP_BACKEND}/getSingleCollection/${id}/${collectionId}`,
      headers: {
        "Accept-Encoding": "application/json",
        // 'Origin' : process.env.ORIGIN
      },
    })
      .then((_response) => {
        setGirlData(_response.data.collection?.[0]);
      })
      .catch((err) => {
        //  throw err
        console.log(err);
      });
  };
  console.log(girlData);
  const imageArray = [];
  const arrayLength = 52;

  for (let i = 0; i < arrayLength; i++) {
    imageArray.push(i);
  }
  return (
    <>
      <Navbar />
      <div
        className={styles.mainContent_marketplace}
        style={{ marginTop: "1rem" }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: { sm: "space-between", xs: "center" },
            flexDirection: { sm: "row", xs: "column" },
            gap: "0.5rem",
          }}
        >
          <Typography
            sx={{
              color: "#fff",
              fontSize: { sm: "20px", xs: "18px" },
            }}
          >
            Mint single NFTS or packs
          </Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            color: "#fff",
            "& a": {
              fontWeight: "600",
              color: "#5138ee",
            },
          }}
        >
          <GirlCollection data={girlData} />
          {/*           
          {imageArray &&
              <GirlCollection girlCollection={imageArray}   data={girlData}/>
          } */}
        </Box>
      </div>
      <Footer />
    </>
  );
};

export default GirlNftDetail;
