import React, { memo, useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { Alert, Box, Snackbar, Typography } from "@mui/material";
import WireTransfer from "./WireTransfer";
import PaypalEmail from "./PaypalEmail";
import CryptoForm from "./CryptoForm";
import EpaymentEmail from "./EpaymentEmail";

const FinancialForm = () => {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [profile, setProfile] = React.useState({});
  const fetchProfile = async (next) => {
   const jwt = localStorage.getItem('jwt') ; 
   const config = {
     headers: {
       Authorization: `Bearer ${jwt}`,
     },
   };
   try {
     const data = await axios.get(
       `${process.env.REACT_APP_BACKEND}/users/me?populate=*`,
       config,
     );
     setProfile( data.data);
   } catch (error) {
     console.log("catch", error);
     // handle401(error.response, next);
     // handle403(error.response, next);
   }
 };
 useEffect(()=>{
   fetchProfile()
 },[])


  return (
    <>
      {/* <div className={styles.container}>
      <div className={styles.childContainer}> */}
      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        sx={{
          "& .MuiSvgIcon-root": {
            color: "#fff",
          },
        }}
      >
        <Alert
          severity={"success"}
          style={{
            backgroundColor: "green",
            color: "white",
            fontSize: "14px",
            fontFamily: "ABLE",
            display: "flex",
            alignItems: "center",
            fontWeight: "600",
          }}
        >
          Profile Updated successfully.
        </Alert>
      </Snackbar>
       <Box mb={"2rem"}>
       <WireTransfer profile={profile} fetchProfile={fetchProfile} setShowSnackbar={setShowSnackbar}/>
        <PaypalEmail profile={profile} fetchProfile={fetchProfile} setShowSnackbar={setShowSnackbar}/>
        <EpaymentEmail profile={profile} fetchProfile={fetchProfile} setShowSnackbar={setShowSnackbar}/>
        <CryptoForm profile={profile} fetchProfile={fetchProfile} setShowSnackbar={setShowSnackbar}/>
       </Box>
      {/* </div>
    </div> */}
    </>
  );
};

export default memo(FinancialForm);

const isEmpty = (obj) => {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};
