import { Navigate } from "react-router-dom";
import { TOKEN } from "./Constants";
import axios from "axios";
import { useAccount } from "wagmi";

function PrivateRoute({ children }) {
  const { address } = useAccount();

  if (typeof window === "undefined") {
    return null;
  }

  // verify(process.env.REACT_APP_ADDRESS);

  // console.log(response);
  // result should decide value of auth
  const jwt = localStorage.getItem('jwt');
  console.log(jwt);
  const id = localStorage.getItem('id');
  const auth = (jwt && id) ? 1 : 0;
  // alert(auth)
  return auth ? children : <Navigate to="/signin" />;
}

// const verify = async (address) => {
//   //verify token
//   const config = {
//     headers: {
//       authorization: localStorage.getItem(TOKEN),
//     },
//   };

//   try {
//     const response = await axios.post(
//       `${process.env.REACT_APP_BACKEND}/verify-token`,
//       {},
//       config,
//     );

//     if (response.data.wallet != address) {
//       localStorage.clear();
//       window.location.href = "/signin";
//     }
//   } catch (e) {
//     localStorage.clear();
//     window.location.href = "/signin";
//   }
// };
export default PrivateRoute;
