import React from "react";
import styles from "./Utility.module.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Utility = () => {
  return (
    <div className={styles.utility}>
      <h3 className={styles.heading}>UTILITY & BENEFITS</h3>
      <br />
      <br />

      <Accordion className={styles.accordion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <p>What makes us stand out?</p>
        </AccordionSummary>
        <AccordionDetails>
          <Typography color={"#b1b5c4"}>
            If offering digital collectibles of beautiful women was enough, the
            answer is gamification & real-world utility.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className={styles.accordion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <p>Gamification</p>
        </AccordionSummary>
        <AccordionDetails>
          <Typography color={"#b1b5c4"}>
            What will make our NFT’s unique is that each one will represent a
            card in a deck like 8 of Hearts or Ace of Spades. Collectors will be
            able to assemble “hands” like 3 of kind or a full house to win
            prizes like Dream Girls Swag and entrance to special events.
            <br />
            Furthermore, collectors will be able to both buy, sell and trade
            their “NFT cards” on a secondary marketplace to create winning
            hands.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className={styles.accordion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <p>Real World Utility</p>
        </AccordionSummary>
        <AccordionDetails>
          <Typography color={"#b1b5c4"}>
            NFT holders will have access to event to meet Dream Girls, holders
            of winning hands receive special access and VIP perks.
            <ul>
              <li>Access to Dream Girls Grotto in the Metaverse</li>
              <li>Prizes for collecting the best hands</li>
              <li>Staking of NFTs to earn DG tokens</li>
              <li>VIP perks with partner businesses and brands</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default Utility;
