import React from "react";
import success from "../../Assets/svg/success.svg";
import styles from "./Forms.module.css";

const AddGirlSuccess = ({dark}) => {
  return (
    <div className={dark ? styles.darksuccess : styles.success} style={{padding:"5rem 1.5rem"}}>
      <div>
        <img src={success} alt="success" />
        <p>
        Congratulations!
          <br />
          Your Girl's account has been <br/>
           created successfully. 
          <br />
          Please ask user to check inbox for confirmation email 
          <br />
          and ask them to reset password using Forgot Password.
          <br />
          Please check spam if needed.
        </p>
      </div>
    </div>
  );
};

export default AddGirlSuccess;
