import React from "react";
import styles from "./Hero.module.css";
import HeroImage from "../../Assets/png/heroimage-new.png";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <div className={styles.hero}>
      <div className={styles.text}>
        <h3>Digital Collectibles</h3>
        <h3>Of The Worlds</h3>
        <h3>Most Beautiful Women</h3>
        <p>Collect. Create. Cash In.</p>
        <div>
          <Link className={styles.link} to="/collections">
            <button className="buy_btn">BUY NOW</button>
            {/* <button>Become a DreamGirl</button> */}
          </Link>
        </div>
      </div>
      <div className={styles.banner}>
        <img src={HeroImage} alt="Hero" />
      </div>
    </div>
  );
};

export default Hero;
