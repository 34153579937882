import React, { memo } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Input from "../../Input/Input";
import Button from "../../Button/Button";
import styles from "./Forms.module.css";
import axios from "axios";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { TOKEN } from "../../../Helper/Constants";
import { Box, Grid, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

const WireTransfer = ({profile,fetchProfile,setShowSnackbar}) => {
  const [errorText, setErrorText] = React.useState(null);

  const formik = useFormik({
    initialValues: {
        bankName: profile?.bankName,
        bankAddress: profile?.bankAddress,
        accountName: profile?.accountName,
        accountNumber: profile?.accountNumber,
        swiftCode: profile?.swiftCode,
        branchNumber: profile?.branchNumber,
    },
    validationSchema: Yup.object({
        bankName: Yup.string()
        .trim("bankName should not contain spaces").required("Required"),
        bankAddress: Yup.string()
        .trim("bankAddress should not contain spaces").required("Required"),
        accountName: Yup.string()
        .trim("accountName should not contain spaces").required("Required"),
        accountNumber: Yup.number().required("Required"),
        swiftCode: Yup.string().trim(
        "swiftCode should not contain spaces").required("Required"),
        branchNumber: Yup.string().trim(
        "branchNumber should not contain spaces").required("Required"),
    }),
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      const updateProfile = `${process.env.REACT_APP_BACKEND}/users/${profile?.id}`;
      const jwt = localStorage.getItem('jwt') ; 
    
      const config = {
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem(TOKEN),
          Authorization: `Bearer ${jwt}`,

        },
      };

      try {
        const res = await axios.put(updateProfile, {
            username: profile?.username,
            firstName: profile?.firstName,
            lastName: profile?.lastName,
            email: profile?.email,
            phoneNumber: profile?.phoneNumber,
            instagram_handle: profile?.instagram_handle,
            bankName: values.bankName,
            bankAddress: values.bankAddress,
            accountName: values.accountName,
            accountNumber: values.accountNumber || 0,
            swiftCode: values.swiftCode,
            branchNumber: values.branchNumber,
            paypalEmail: profile?.paypalEmail,
            cryptoWallet: profile?.cryptoWallet,
        }, config);

        if (res.status === 200) {
          setSubmitting(false);
          fetchProfile()
          setShowSnackbar(true)
          setTimeout(() => {
            setShowSnackbar(false)
          }, 2000);
        }
      } catch (err) {
        console.log(err);
        setErrorText(err.response.data.error.message)

      }
    },
  });

  useEffect(() => {
    if (!isEmpty(profile)) {

      formik.setValues({
        bankName: profile?.bankName || "",
        bankAddress: profile?.bankAddress || "",
        accountName: profile?.accountName || "",
        accountNumber: profile?.accountNumber || "",
        swiftCode: profile?.swiftCode || "",
        branchNumber: profile?.branchNumber || "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  return (
    <>
      {/* <div className={styles.container}>
      <div className={styles.childContainer}> */}
      <div className={styles.formContainer} style={{ margin: "0 auto" }}>
        <form onSubmit={formik.handleSubmit}>
          <div className={styles.inputs}>
      <p style={{fontSize:"20px",fontWeight:"bold",paddingBottom:"0.5rem"}}>Wire Transfer Detail</p>
      <Grid container spacing={2} sx={{
        mt:"0rem",
        "& .MuiGrid-item":{
            pt:"0px"
        }
      }}>
        <Grid item md={6} sm={12} xs={12}>
        <Input
              id="bankName"
              name="bankName"
              type="text"
              label="Bank Name"
              placeholder="Bank Name"
              error={formik.errors.bankName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.bankName}
              touched={formik.touched.bankName ? 1 : 0} 
            />
        </Grid>
  
      <Grid item md={6} sm={12} xs={12}>
            <Input
              id="bankAddress"
              name="bankAddress"
              type="text"
              label="Bank Address"
              placeholder="Bank Address"
              error={formik.errors.bankAddress}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.bankAddress}
              touched={formik.touched.bankAddress ? 1 : 0}
            />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
            <Input
              id="accountName"
              name="accountName"
              type="text"
              label="Account Name"
              placeholder="Account Name"
              error={formik.errors.accountName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.accountName}
              touched={formik.touched.accountName ? 1 : 0} 
            />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
            <Input
              id="accountNumber"
              name="accountNumber"
              type="number"
              label="Account Number"
              placeholder="Account Number"
              error={formik.errors.accountNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.accountNumber}
              touched={formik.touched.accountNumber ? 1 : 0}
            />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
            <Input
              id="swiftCode"
              name="swiftCode"
              type="text"
              label="Swift Code"
              placeholder="Swift Code"
              error={formik.errors.swiftCode}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.swiftCode}
              touched={formik.touched.swiftCode ? 1 : 0}
            />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
            <Input
              id="branchNumber"
              name="branchNumber"
              type="text"
              label="Branch Number"
              placeholder="Branch Number"
              error={formik.errors.branchNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.branchNumber}
              touched={formik.touched.branchNumber ? 1 : 0}
            />
            </Grid>
            </Grid>
          </div>
          {
                errorText &&
              <Box sx={{ p: "1rem 1.5rem", display: "flex" }}>
                  <Typography sx={{ color: "red", fontSize: "1rem" }}>
                    {errorText}  
                    <Typography
                      component={"span"}
                      title="For More Info" 
                    >
                      <InfoIcon
                        sx={{
                          fontSize: "1.2rem",
                          cursor: "pointer",
                          verticalAlign: "sub",
                        }}
                      />
                    </Typography>
                  </Typography>
                </Box>
              }
          <div className={styles.buttonContainer} style={{justifyContent:"flex-end"}}>
            <Button disabled={formik.isSubmitting} type="submit">
              {formik.isSubmitting ? "Submitting..." : "Save Changes"}
            </Button>
          </div>
        </form>
      </div>
      {/* </div>
    </div> */}
    </>
  );
};

export default memo(WireTransfer);

const isEmpty = (obj) => {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};
