import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { JWT } from "../../Helper/Constants";
import Loader from "../Loader/Loader";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    // padding: "1rem",
    border: "none",
    "&::-webkit-scrollbar": {
      width: 0,
    },
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: "1rem", color: "#800080" }} {...other}>
      {children}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const Withdraw = ({
  withdrawModal,
  setWithdrawModal,
  earningData,
  maticPrice,
}) => {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [request, setRequest] = useState(0);
  const [withdrwalAmount, setWithdrwalAmount] = useState(null);
  const [errMsg, setErrMsg] = useState(null);
  const [method, setMethod] = useState(null);
  const handlePaymentMethod = (e) => {
    setMethod(e.target.value);
    setErrMsg("");
  };
  const getPendingRequest = async () => {
    try {
      const data = await axios.get(
        `${
          process.env.REACT_APP_BACKEND
        }/get-Pending-Request/${localStorage.getItem("id")}`
      );
      if (data.status === 200) {
        setRequest(data.data.data);
      }
    } catch (error) {
      console.log("catch", error);
    }
  };

  useEffect(() => {
    getPendingRequest();
  }, []);

  const withdrawlFunc = async () => {
    setLoading(true);
    const withdrawalRoute = `${process.env.REACT_APP_BACKEND}/withdrawal-request`;
    const userId = localStorage.getItem("id");
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(JWT)}`,
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post(
        withdrawalRoute,
        {
          userid: userId,
          amount: withdrwalAmount,
          method: method,
          status: "Requested",
        },
        config
      );
      if (res.status === 200) {
        console.log(res);
        setShowSnackbar(true);
        setLoading(false);
        setTimeout(() => {
          setShowSnackbar(false);
          setWithdrawModal(false);
        }, 2000);
      }
    } catch (err) {
      if (err.response.status === 400) {
        setLoading(false);
        setErrMsg(err.response.data.error.message);
      } else {
        setErrMsg("");
      }
    }
  };
  const handleAmount = (e) => {
    const amount = e.target.value;

    setWithdrwalAmount(amount);
    if (amount <= 20) {
      setErrMsg("Amount should be greater than 20");
    } else if (parseFloat(amount) > earningData) {
      setErrMsg("Insufficient Balance.");
    } else {
      setErrMsg("");
    }
  };
  useEffect(() => {
    if (!withdrawModal) {
      setErrMsg("");
      setWithdrwalAmount("");
      setMethod(null);
    }
  }, [withdrawModal]);
  return (
    <div>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        sx={{
          "& .MuiSvgIcon-root": {
            color: "#fff",
          },
        }}
      >
        <Alert
          severity={"success"}
          style={{
            backgroundColor: "green",
            color: "white",
            fontSize: "14px",
            fontFamily: "ABLE",
            display: "flex",
            alignItems: "center",
            fontWeight: "600",
          }}
        >
          Withdrawal requested successfully
        </Alert>
      </Snackbar>
      <BootstrapDialog
        onClose={() => setWithdrawModal(false)}
        aria-labelledby="customized-dialog-title"
        open={withdrawModal}
        sx={{
          //   "& .MuiPaper-root": {
          //     overflowY: "inherit",
          //   },
          "& .MuiDialog-paper": {
            width: "500px",
            maxWidth: "900px",
            background: "#1d1e1f",
            border: "1px solid rgba(255, 255, 255, 0.2)",
            borderRadius: "10px",
          },
          "& .MuiDialog-container": {
            background: "rgb(0 0 0 / 86%)",
          },
        }}
      >
        <BootstrapDialogTitle id="customized-dialog-title">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                fontSize: "18px",
                lineHeight: "22.24px",
                fontWeight: "400",
                color: "rgba(255, 255, 255, 0.8)",
                display: "flex",
                gap: "10px",
                alignItems: "center",
                fontFamily: "Exo 2 !important",
              }}
            >
              Withdraw
            </Typography>
            <CloseIcon
              sx={{ fontSize: "1.5rem", color: "#fff", cursor: "pointer" }}
              onClick={() => setWithdrawModal(false)}
              title="Close"
            />
          </Box>
        </BootstrapDialogTitle>
        <DialogContent
          dividers
          sx={{
            p: "1rem",
          }}
        >
          <Box color={"#fff"}>
            <Box>
              <Typography
                sx={{
                  fontSize: "16px",
                  lineHeight: "22.24px",
                  fontWeight: "400",
                  color: "rgba(255, 255, 255, 0.8)",
                  fontFamily: "Exo 2",
                  pb: "0.5rem",
                }}
              >
                Balance: $ {isNaN(earningData) ? 0 : earningData}
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  lineHeight: "22.24px",
                  fontWeight: "400",
                  color: "rgba(255, 255, 255, 0.8)",
                  fontFamily: "Exo 2",
                  pb: "0.5rem",
                }}
              >
                Enter Amount to withdraw
              </Typography>
            </Box>

            <TextField
              autoComplete="off"
              fullWidth
              value={withdrwalAmount}
              onChange={handleAmount}
              sx={{
                background: "rgba(42, 41, 52, 1)",
                color: "#fff",
                marginBottom: "0.5rem !important",
                width: "100%",
                border: "0.5px solid rgb(255 255 255 / 28%) !important",
                borderRadius: "8px ",
                background: "#1d1e1f !important",
                color: "#fff",
                "& input[type=number]": {
                  "-moz-appearance": "textfield",
                },
                "& input[type=number]::-webkit-outer-spin-button": {
                  "-webkit-appearance": "none",
                  margin: 0,
                },
                "& input[type=number]::-webkit-inner-spin-button": {
                  "-webkit-appearance": "none",
                  margin: 0,
                },
                "&:focus-Visible": {
                  outline: "none !important",
                  border: "none !important",
                },
                "&:hover": {
                  border: "none",
                },
                "& .MuiInputBase-root": {
                  "&::before": {
                    display: "none",
                  },
                  "&::after": {
                    display: "none",
                  },
                },

                "&:focus": {
                  boxShadow: "none !important",
                  border: "0.5px solid #fff !important",
                  borderRadius: "10px !important ",
                },
                "& input": {
                  padding: "12px 15px",
                  fontSize: "16px",
                  borderRadius: "10px",
                  fontWeight: "400",
                  color: "rgb(255 255 255 / 100%) !important",
                  "&::placeholder": {
                    color: "rgb(255 255 255 / 100%) !important",
                    fontSize: "16px",
                    fontWeight: "400",
                  },
                },
              }}
              placeholder="Enter Amount"
              variant="standard"
            />

            <FormControl
              sx={{
                my: "0.5rem",
                minWidth: "100%",
                // display: "block",
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "& .MuiSvgIcon-root": {
                  color: "#fff",
                },

                "& .MuiInputBase-root": {
                  border: "1px solid rgb(255 255 255 / 28%)",
                  borderRadius: "8px",
                  maxWidth: "100% !important",
                  mr: "0px !important",
                },
                "& .MuiSelect-select": {
                  padding: "0.8rem",
                  color: "#fff",
                  fontFamily: "Inter",
                  paddingRight: "2.5rem !important",
                },
              }}
            >
              <Select
                value={method}
                onChange={handlePaymentMethod}
                style={{ marginRight: "10px", maxWidth: "200px" }}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value={null}>---Choose Method---</MenuItem>
                <MenuItem value={"Wire Transfer"}>Wire Transfer</MenuItem>
                <MenuItem value={"PayPal"}>PayPal</MenuItem>
                <MenuItem value={"Crypto"}>
                  ERC 20 Crypto Wallet Address
                </MenuItem>
                <MenuItem value={"eTransfer"}>eTransfer</MenuItem>
              </Select>
            </FormControl>
            {errMsg && (
              <Typography
                sx={{
                  color: "red",
                  fontSize: "14px",
                }}
              >
                {errMsg}
              </Typography>
            )}
            {request?.length > 0 && (
              <Typography
                sx={{
                  color: "red",
                  fontSize: "14px",
                }}
              >
                Already Requested.
              </Typography>
            )}
            <Box
              sx={{
                mt: "1rem",
                textAlign: "center",
                py: "1rem",
              }}
            >
              <Button
                onClick={withdrawlFunc}
                disabled={
                  errMsg ||
                  withdrwalAmount === null ||
                  withdrwalAmount <= 20 ||
                  request?.length > 0
                }
                sx={{
                  color: "#fff !important",
                  lineHeight: "19.92px !important",
                  fontSize: "14px !important",
                  height: "45px",
                  width: "40%",
                  fontWeight: "400 !important",
                  fontFamily: "Exo 2 !important",
                  transition: "0.1s all !important",
                  // border: "0.5px solid rgb(255 255 255 / 50%) !important",
                  borderRadius: "8px !important",
                  background: "#000 !important",
                  "&:hover": {
                    background: "#000 !important",
                  },
                  "&.Mui-disabled": {
                    cursor: "not-allowed !important",
                    pointerEvents: "auto !important",
                    color: "rgb(255 255 255 / 38%) !important",
                    "&:hover": {
                      opacity: "1",
                    },
                  },
                }}
              >
                {loading ? <Loader /> : "Withdraw"}
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};

export default Withdraw;
