import React, { useEffect, useState } from "react";
import Container from "../Component/Dashboard/Container/Container";
import Navbar from "../Component/Dashboard/Navbar/Navbar";
import Header from "../Component/Dashboard/Header/Header";
import { useProfile } from "../Component/Dashboard/Hooks/useDashboard";
import Content from "../Component/Dashboard/Container/Content";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { JWT } from "../Helper/Constants";
import axios from "axios";
import VideoButton from "../Component/Dashboard/Video/VideoButton";
import styles from "../Component/Dashboard/Container/Container.module.css";
import { Box } from "@mui/material";
import RecordChart from "./RecordChart";

const Earnings = () => {
  const [earningData, setEarningData] = useState([]);
  const [maticPrice, setMaticPrice] = useState(null);
  
 
  const userId = parseInt(localStorage.getItem("id"));
  const getEarning = async (next) => {
    const config = {
      headers: {
        // authorization: localStorage.getItem(JWT),
        Authorization: `Bearer ${localStorage.getItem(JWT)}`,
      },
    };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/get-all-earning`,
        config
      );
      if(response.data?.creditTxn?.length > 0 ){
        const filteredData = response.data.creditTxn 

      setEarningData(filteredData);
      }
    } catch (error) {
      console.log("catch", error);
    }
  };
  const getMaticPrice = async (next) => {
    const config = {
      headers: {
        // authorization: localStorage.getItem(JWT),
        // Authorization: `Bearer ${localStorage.getItem(JWT)}`,
      },
    };
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_BACKEND}/get/maticPrice`,
        config
      );
      setMaticPrice(data.data.formatValue);
    } catch (error) {
      console.log("catch", error);
    }
  };
  useEffect(() => {
    getEarning();
    getMaticPrice()
  }, []);

  const profile = useProfile();
  return (
    <Container>
      {profile && (
        <Navbar profile={profile} name={`${profile?.firstName} ${profile?.lastName}`} />
      )}
      <Header message={`Welcome back, ${profile?.firstName} ${profile?.lastName}`} title="Earnings" />
      <div className={styles.mainContent_custom}>
        <TableContainer
          component={Paper}
          // sx={{
          //   width:{md:"70%",xs:"100%"},
          //   margin:"0 auto"
          // }}
        >
          <Table sx={{ minWidth: 550 }} aria-label="simple table">
            <TableHead>
              <TableRow
                sx={{
                  "& th": {
                    fontWeight: "bold",
                  },
                }}
              >
                <TableCell align="left">Date</TableCell>
                <TableCell align="left">Amount</TableCell>
                <TableCell align="left">Txn ID</TableCell>
                <TableCell align="left">Remarks</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {earningData?.length > 0 && 
              earningData?.map((row) => (
                <TableRow
                  key={row.userid}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="left">
                    {new Date(row.createdAt).toDateString()}
                  </TableCell>
                  <TableCell style={{color : row.type == "Credit" ? "green" : "red" }} align="left">{row.type == "Credit" ? "+" : "-" }$ {row.amount} 
                  {/* ~ $ {(row.amount)*maticPrice} */}
                  </TableCell>
                  <TableCell align="left">
                  <a style={{color:"#0066FF"}} href={`${process.env.REACT_APP_EXPLORE_URL}/${row.txn_id}`} target="_blank">{row.txn_id?.substring(0,5)+"..."+row.txn_id?.substring(row.txn_id?.length-4)}</a>
                   
                  </TableCell>
                  <TableCell align="left">{row.remarks}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {earningData?.length===0 &&  <Box sx={{
                fontSize:"18px",
                fontWeight:"600",
                textAlign:"center",
                py:"3rem"
              }}>
                No data found.
              </Box>}
        </TableContainer>
        <RecordChart data={earningData} maticPrice={maticPrice}/>
      </div>
      <Content>
        <VideoButton flex={1} />
      </Content>
    </Container>
  );
};

export default Earnings;
