import React from "react";
import { Box,  Typography } from "@mui/material";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent"; 
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as Yup from "yup";
import Input from "../Input/Input";
import Button from "../Button/Button";
import styles from "../Forms/Forms.module.css";
import axios from "axios";
import Success from "../Forms/Success";
import Dropzone from "../Forms/DragnDrop";
import { useSearchParams } from "react-router-dom";
import Loader from "../Loader/Loader";
import { useAccount } from "wagmi"; 
import InfoIcon from "@mui/icons-material/Info";
import ErrorModal from "../ErrorModal/ErrorModal";
import { ethers } from "ethers";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const AddGirlModal = ({ handleClose, open, getDraftGirls }) => {
  const [status, setStatus] = React.useState("idle");

  const [files, setFiles] = React.useState([]);
  const [fileError, setFileError] = React.useState("");
  // const [girl,setGirl] = React.useState([])
  const [errorArray, setErrorArray] = React.useState([{}]);
  const [errModal, setErrModal] = React.useState(false);
  const handleClickErrOpen = () => {
    setErrModal(true);
  };
  const handleClickErrClose = () => {
    setErrModal(false);
  };

  const [searchParams] = useSearchParams();
  const { address, isConnected } = useAccount();

  const formik = useFormik({
    initialValues: {
      username: "",
      name: "",
      email: "",
      instagramUrl: "",
      social: "",
      description: "",
      wallet: "",
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .trim("Username should not contain spaces")
        .matches(
          "^[a-zA-Z0-9_]{6,16}$",
          "Username should contain letters, number or underscores",
        )
        .min(6, "Username must be at least 6 characters")
        .max(16, "Username must be at most 16 characters")
        .required("Required"),
      name: Yup.string()
        .trim("Name should not contain spaces")
        .required("Required"),
      email: Yup.string()
        .trim("Email should not contain spaces")
        .email("Invalid email address")
        .required("Required"),
      instagramUrl: Yup.string().trim(
        "Instagram url should not contain spaces",
      ),
      social: Yup.string().trim(
        "Social media handle should not contain spaces",
      ),
      wallet: Yup.string()
        .trim("Wallet should not contain spaces")
        .test("is-valid-address", "Invalid Address", function (value) {
          if (!value) {
            // If the field is empty, consider it as valid
            return true;
          }

          // Check if it's a valid Ethereum address
          return ethers.utils.isAddress(value);
        }),
    }),
    onSubmit: async (
      values,
      { setErrors, setSubmitting, resetForm, errors },
    ) => {
      if (errors) {
        setErrors(errors);
      }

      // check if files is empty
      if (files.length === 0) {
        setFileError("Please upload at least 1 image");
        return;
      }
      // check if files is more than 1
      if (files.length > 1) {
        setFileError("Please upload only one image");
        return;
      }

      // check file type and if its not image/* setError
      for (let i = 0; i < files.length; i++) {
        if (!files[i].type.match("image.*")) {
          setFileError("Please upload only image files");
          return;
        } else {
        }
      }

      let imagesData = new FormData();
      imagesData.append("username", values.username);
      imagesData.append("name", values.name);
      imagesData.append("email", values.email);
      imagesData.append("instagramUrl", values.instagramUrl);
      imagesData.append("social", values.social);
      imagesData.append("description", values.description);
      imagesData.append("photographer", process.env.REACT_APP_ADDRESS);
      imagesData.append("wallet", values.wallet);
      imagesData.append("draft", true);

      // let _temp =  girls ;
      // _temp.push({name: values.name, username: values.username});
      // setGirls(_temp);

      files.forEach((file) => {
        imagesData.append("files", file);
      });
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const imgRes = await axios.post(
        `${process.env.REACT_APP_BACKEND}/create-girl-profile`,
        imagesData,
        config,
      );
      if (imgRes.data.status === 201) {
        handleClose();
        resetForm();
        getDraftGirls();
      }
      if (imgRes.data.status === 500) {
        setErrorArray(imgRes.data.error.details.errors);
      }

      // values = rec({
      //   ...values,
      //   referralCode: generateId(),
      // });
      // try {
      //   const res = await axios.post(girlsRoute, {
      //     data: values,
      //   });

      //   if (res.status === 200) {
      //     const config = {
      //       headers: {
      //         "Content-Type": "multipart/form-data",
      //       },
      //     };
      //     let imagesData = new FormData();
      //     imagesData.append("ref", "api::girl.girl");
      //     imagesData.append("field", "images");

      //     const imgRes = await axios.post(
      //       `${process.env.REACT_APP_BACKEND}/upload`,
      //       imagesData,
      //       config
      //     );

      //     if (imgRes.status === 200) {
      //       setStatus("success");
      //       resetForm();
      //     }
      //   }
      // } catch (err) {
      //   console.log("Error", err);
      // }
      // setTimeout(() => {

      // }, 1000);
    },
  });
  const newErrArray = errorArray?.map((e) => e.path)?.[0];
  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{
          "& .MuiPaper-root": {
            width: "500px !important",
            background: "#1d1e1f",
            color: "#fff",
            border: " 1px solid #ffffff59",
          },
          "& .MuiDialogContent-root": {
            borderBottom: "none",
            borderTop: " 1px solid #ffffff59",
            "::-webkit-scrollbar": { width: "6px" },
            "::-webkit-scrollbar-track": { background: "#ffffff66" },
            "::-webkit-scrollbar-thumb": { background: "#3b3b3b" },
          },
          "& .MuiDialog-container": {
            backdropFilter: "blur(8px)",
          },
        }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <div style={{ color: "#fff9e6" }}>Add Girl</div>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <div
            style={{ backgroundColor: "#282828", borderRadius: "12px" }}
            className="custom"
          >
            {status === "idle" ? (
              <form onSubmit={formik.handleSubmit}>
                <div
                  className={styles.inputs}
                  style={{
                    paddingTop: "1rem",
                    margin: "0 20px",
                    paddingBottom: "0",
                  }}
                >
                  <Input
                    id="username"
                    name="username"
                    type="text"
                    label="Username*"
                    placeholder="@username"
                    error={formik.errors.username}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.username}
                    touched={formik.touched.username ? 1 : 0}
                  />
                  <Input
                    id="name"
                    name="name"
                    type="text"
                    label="Full Name*"
                    placeholder="Miss Lee"
                    error={formik.errors.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                    touched={formik.touched.name ? 1 : 0}
                  />
                  <Input
                    id="email"
                    name="email"
                    type="email"
                    label="Email*"
                    placeholder="name@example.com"
                    error={formik.errors.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    touched={formik.touched.email ? 1 : 0}
                  />
                  {/* <Input
                  id="affiliate"
                  name="affiliate"
                  type="text"
                  label="Affiliate Address"
                  placeholder="yourAffiliateAddress"
                  error={formik.errors.affiliate}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.affiliate}
                  touched={formik.touched.affiliate ? 1 : 0}
                /> */}
                  <Input
                    id="instagramUrl"
                    name="instagramUrl"
                    type="text"
                    label="Instagram Handle"
                    placeholder="yourInstagramHandle"
                    error={formik.errors.instagramUrl}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.instagramUrl}
                    touched={formik.touched.instagramUrl ? 1 : 0}
                  />
                  <Input
                    id="social"
                    name="social"
                    type="text"
                    label="Social Media Handle"
                    placeholder="yourSocialMediaHandle"
                    error={formik.errors.social}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.social}
                    touched={formik.touched.social ? 1 : 0}
                  />
                  <Input
                    id="wallet"
                    name="wallet"
                    type="text"
                    label="Girl Wallet Address"
                    placeholder="Girls' wallet address"
                    error={formik.errors.wallet}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.wallet}
                    touched={formik.touched.wallet ? 1 : 0}
                  />
                  <Input
                    id="description"
                    name="description"
                    type="text"
                    label="Anything else you'd like to share?"
                    placeholder="Type here..."
                    error={formik.errors.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.description}
                    touched={formik.touched.description ? 1 : 0}
                  />
                  {/* {girl.length} */}
                  <Dropzone onChange={setFiles} error={fileError} />
                </div>
                {newErrArray && (
                  <Box sx={{ p: "1rem", display: "flex" }}>
                    <Typography sx={{ color: "red", fontSize: "1rem" }}>
                      {errorArray?.length} Error Ocuured.{" "}
                      <Typography
                        component={"span"}
                        title="For More Info"
                        onClick={handleClickErrOpen}
                      >
                        <InfoIcon
                          sx={{
                            fontSize: "1.2rem",
                            cursor: "pointer",
                            verticalAlign: "sub",
                          }}
                        />
                      </Typography>
                    </Typography>
                  </Box>
                )}
                <ErrorModal
                  handleClose={handleClickErrClose}
                  open={errModal}
                  errorArray={errorArray}
                />
                <div className={styles.buttonContainer}>
                  {/* {isConnected?   <Button disabled={formik.isSubmitting} type="submit">
                  {formik.isSubmitting ? <Loader /> : "Submit"}
                </Button>:
                <ConnectWalletBtn/>
                } */}
                  <Button disabled={formik.isSubmitting} type="submit">
                    {formik.isSubmitting ? <Loader /> : "Submit"}
                  </Button>
                </div>
              </form>
            ) : (
              <Success />
            )}
          </div>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};

export default AddGirlModal;
const generateId = () => {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < 10; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
function rec(obj) {
  for (let key of Object.keys(obj)) {
    if (obj[key] === "") {
      delete obj[key];
    } else if (typeof obj[key] === "object") {
      obj[key] = rec(obj[key]);
      if (Object.keys(obj[key]).length === 0) delete obj[key];
    }
  }
  return Array.isArray(obj) ? obj.filter((val) => val) : obj;
}
