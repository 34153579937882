import React, { useEffect } from "react";
import Container from "../Component/Dashboard/Container/Container";
import Navbar from "../Component/Dashboard/Navbar/Navbar";
import Header from "../Component/Dashboard/Header/Header";
import Grid from "../Component/Dashboard/Container/Grid";
import { Referral } from "../Component/Dashboard/Stats/Stats";
import styles from "../Component/Dashboard/Container/Container.module.css";
import {
  usePhotographerUsers,
  useProfile,
  useReferredPhotographerUsers,
  useReferredUsers,
} from "../Component/Dashboard/Hooks/useDashboard";
import Content from "../Component/Dashboard/Container/Content";
import SalesList from "../Component/Dashboard/SalesList/SalesList";
import PhotosList from "../Component/Dashboard/PhotosList/PhotosList";
import VideoButton from "../Component/Dashboard/Video/VideoButton";
import { TOKEN } from "../Helper/Constants";
import axios from "axios";
import { Navigate } from "react-router-dom";
import PhotographerList from "../Component/Dashboard/SalesList/PhotographerList";
import AffiliateList from "../Component/Dashboard/AffiliateList/AffiliateList";

//check if obejct is empty
const isEmpty = (obj) => {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};

const Dashboard = () => {
  const profile = useProfile();

  const referredUsers = useReferredUsers();
  const referredPhotographerUsers = useReferredPhotographerUsers();
  const photographerUsers = usePhotographerUsers();
  const userType = localStorage.getItem("userType");
  // console.log(localStorage.getItem("token"));;

  useEffect(() => {
    document.title = "Dashboard";
  });
  if (isEmpty(profile))
  return (
    <div style={{ color: "#fff", textAlign: "center", padding: "2rem" }}>
      Loading...
    </div>
  );
  const userData = [
    {
      user_type: "Girl",
      videoData: [
        "https://www.youtube.com/embed/aDktZgf2fo8?si=sI2jv_d4sN32L2bP",
        "https://www.youtube.com/embed/ZmGFUhfk_uI?si=_90cLT71tPPDHxvD"
      ]
    },
    {
      user_type: "Affiliate",
      videoData: [
        "https://www.youtube.com/embed/jtRCYZcFxRI?si=LsPFOaKN1mn9mnYL",
        "https://www.youtube.com/embed/RMDFL3qIMnY?si=Cv1nY7vSZSXCs0xu"
      ]
    },
    {
      user_type: "Photographer",
      videoData: [
        "https://www.youtube.com/embed/9-FQ06iCfMA?si=8uSDpSY1evJ1WuWO",
        "https://www.youtube.com/embed/BJ4ozeKmR28?si=fPbhRZG4gbHhgR40"
      ]
    }
  ];
  return (
    <Container>
     {profile && (
       <Navbar profile={profile} name={`${profile?.firstName} ${profile?.lastName}`} />
      )}
      <Header message={`Welcome back, ${profile?.firstName} ${profile?.lastName}`} profile={profile}/>
      <div className="custom_grid">
        {/* <Grid> */}
        <Referral
          approved={profile?.status}
          user_type = {profile.user_type}
          username={profile?.username}
          referralCode={profile?.referredBy}
          profile={profile}
        />
        {/* </Grid> */}
      </div>
      {profile.user_type ==  "Girl"  && (
      <Content display={"block"}>
        <PhotosList profile={profile} flex={2} />
      </Content>
  )}
      <Content display={"block"}>
        {profile.user_type ==  "Affiliate"  && (
          <AffiliateList flex={2} referredUsers={referredUsers} referredPhotographerUsers={referredPhotographerUsers} />
        )}
      </Content>
      {(profile.user_type ==  "Photographer" )  && (
      <div className={styles.mainContent_custom}>
          <PhotographerList profile={profile} flex={2} photographerUsers={photographerUsers} />
      </div>
        )}

      <Content display={"block"}>
        <VideoButton flex={1} userData={userData} profile={profile}/>
      </Content>
    </Container>
  );
};

export default Dashboard;
