import React from "react";

import first from "../../Assets/png/Nikkifirst.png";
import second from "../../Assets/png/Seanna2.png";
import third from "../../Assets/png/Amanda3.png";
import fourth from "../../Assets/png/Kelly4.png";
import fifth from "../../Assets/png/Lauren5.png";

import Marquee from "react-fast-marquee";

import styles from "./styles.module.css";

const Slider = () => {
  return (
    <Marquee pauseOnHover={true} speed={60} gradient={false}>
      <img className={styles.img} src={first} alt="first" />

      <img className={styles.img} src={second} alt="second" />

      <img className={styles.img} src={third} alt="third" />

      <img className={styles.img} src={fourth} alt="fourth" />

      <img className={styles.img} src={fifth} alt="fifth" />
    </Marquee>
  );
};

export default Slider;
