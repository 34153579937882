import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Input from "../../Input/Input";
import Button from "../../Button/Button";
import styles from "./../Forms/Forms.module.css";
import axios from "axios";
import Success from "../../Forms/Success";
import dg from "../../../Assets/png/dgform.png";
import Dropzone from "../../Forms/DragnDrop";
import { useSearchParams } from "react-router-dom";
import Loader from "../../Loader/Loader";
import { useAccount } from "wagmi";
import { ethers } from "ethers";
import { Box, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import ErrorModal from "../../ErrorModal/ErrorModal";
import AddGirlSuccess from "../../Forms/AddGirlSuccess";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = { 
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const GirlForm = ({ profile }) => {
  const [status, setStatus] = React.useState("idle");
  const [errorText, setErrorText] = React.useState(null);
  const [phoneNumErr, setPhoneNumErr] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [files, setFiles] = React.useState([]);
  const [errorArray, setErrorArray] = React.useState([{}]);
  const [fileError, setFileError] = React.useState("");
  const [searchParams] = useSearchParams();
  const { address, isConnected } = useAccount();
  const [ethnicity, setEthnicity] = React.useState([]);
  const [bodyTypeValue, setBodyTypeValue] = React.useState([]);
  const [hairColorValue, setHairColorValue] = React.useState("");
  const [eyeColorValue, setEyeColorValue] = React.useState("");
  const ethnicityOptions = [
    "Caucasian/White",
    "Asian",
    "Spanish/Latino",
    "Black/ African",
    "Southeast Asian / Indian",
    "Middle Eastern",
    "Mixed",
    "Native",
  ];
  const bodyType = [
    "Skinny",
    "Slim",
    "Fit & Toned",
    "Muscular",
    "Slim Thick",
    "Curves For Days",
    "BBW",
  ];
  const hairColor = [
    "Blond",
    "Brunette",
    "Black",
    "Brown",
    "Red Head",
    "Colorful AF",
    "Other",
  ];
  const eyeColor = ["Blue", "Green", "Brown", "Hazel", "Other"];
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const generateId = () => {
    let result = "";
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < 10; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const formik = useFormik({
    initialValues: {
      username: "",
      firstName: "",
      lastName: "",
      password: generateId(),
      email: "",
      phoneNumber: "",
      instagram_handle: "",
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .trim("Username should not contain spaces")
        .min(6, "Username must be at least 6 characters")
        .max(16, "Username can be at most 16 characters")
        .matches(
          "^[a-zA-Z_0-9]{6,16}$",
          "Username should contain letters, number or underscores"
        )
        .required("Required"),
      password: Yup.string()
        .trim("Password should not contain spaces")
        .matches(
          "^[a-zA-Z0-9_]{6,16}$",
          "Password should contain letters, number or underscores"
        )
        .min(6, "Password must be at least 6 characters")
        .max(16, "Password can be at most 16 characters")
        .required("Required"),
      firstName: Yup.string()
        .trim("First Name should not contain spaces")
        .required("Required"),
      lastName: Yup.string()
        .trim("Last Name should not contain spaces")
        .required("Required"),
      email: Yup.string()
        .trim("Email should not contain spaces")
        .email("Invalid email address")
        .required("Required"),
      phoneNumber: Yup.string().trim("Phone number should not contain spaces"),
      instagram_handle: Yup.string().trim(
        "Social media handle should not contain spaces"
      ),
    }),
    onSubmit: async (
      values,
      { setErrors, setSubmitting, resetForm, errors }
    ) => {
      // alert("Submitted")
      // const girlsRoute = `${process.env.REACT_APP_BACKEND}/create-girl-profile`;
      const girlsRoute = `${process.env.REACT_APP_BACKEND}/auth/local/register`;

      try {
        const res = await axios.post(girlsRoute, {
          user_type: "Girl",
          referredBy: profile.username,
          ...values,
          ethnicity: ethnicity.join(","), // Convert array to string
          bodyType: bodyTypeValue.join(","), // Convert array to string
          hairColor: hairColorValue,
          eyeColor: eyeColorValue,
          // data: { wallet: address , ...values },
        });
        // console.log(res)
        if (res.status === 200) {
          setStatus("success");
          setTimeout(() => {
            window.location.reload();
          }, 20000);
        } else {
          // setErrorArray(res.data.error.message);
          setErrorText(res.data.error.message);
        }
      } catch (err) {
        console.log(err);
        // setErrorArray([err.response.data.error.message]);
        setPhoneNumErr(
          err.response.data.error.details.errors?.[0].path?.[0] ===
            "phoneNumber"
        );
        setErrorText(err.response.data.error.message);
      }

      // try {
      //   let imagesData = new FormData();
      //   files.forEach((file) => {
      //     imagesData.append("files", file);
      //   });
      //   imagesData.append("username", values.username);
      //   imagesData.append("name", values.name);
      //   imagesData.append("email", values.email);
      //   imagesData.append("referredBy", values.referredBy);
      //   imagesData.append("photographer", values.photograph);
      //   imagesData.append("instagramUrl", values.instagramUrl);
      //   imagesData.append("social", values.social);
      //   imagesData.append("description", values.description);
      //   imagesData.append("wallet", values.wallet);

      // const res = await axios.post(girlsRoute, {
      //   data: values,
      // });

      // if (res.status === 200) {

      // const config = {
      //   headers: {
      //     "Content-Type": "multipart/form-data",
      //   },
      // };

      //   const imgRes = await axios.post(
      //     `${process.env.REACT_APP_BACKEND}/create-girl-profile`,
      //     imagesData,
      //     config,
      //   );

      //   // alert(imgRes.status)

      //   if (imgRes.data.status === 201) {
      //     setStatus("success");
      //     resetForm();
      //   }
      //   if (imgRes.data.status === 500) {
      //     setErrorArray(imgRes.data.error.details.errors);
      //   }
      // } catch (err) {
      //   console.log("Error", err);
      // }
    },
  });
  const newErrArray = errorArray?.map((e) => e.path)?.[0];
  const handleHairColor = (event) => {
    setHairColorValue(event.target.value);
  };
  const handleEyeColor = (event) => {
    setEyeColorValue(event.target.value);
  };
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    // Check if the selected value length is greater than 2
    if (value.length > 2) {
      // If more than 2 options are selected, keep only the first two selections
      setEthnicity(value.slice(0, 2));
    } else {
      setEthnicity(value);
    }
  };

  const handleBodyTypeValue = (event) => {
    const {
      target: { value },
    } = event;
    if (value.length > 2) {
      // If more than 2 options are selected, keep only the first two selections
      setBodyTypeValue(value.slice(0, 2));
    } else {
      setBodyTypeValue(value);
    }
    // setBodyTypeValue(
    //   // On autofill we get a stringified value.
    //   typeof value === 'string' ? value.split(',') : value
    // );
  };
  return (
    <div className={styles.childContainer}>
      <div className={styles.formContainer}>
        {status === "idle" ? (
          <form onSubmit={formik.handleSubmit}>
            <Box
              sx={{
                "& input": {
                  color: "#000 !important",
                },
              }}
              className={styles.inputs}
            >
              {/* {profile.username} */}
              <Input
                id="username"
                name="username"
                type="text"
                label="Username*"
                placeholder="username"
                error={formik.errors.username}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.username}
                touched={formik.touched.username ? 1 : 0}
              />

              <Input
                id="firstName"
                name="firstName"
                type="text"
                label="First Name*"
                placeholder="Miss Lee"
                error={formik.errors.firstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.firstName}
                touched={formik.touched.firstName ? 1 : 0}
              />
              <Input
                id="lastName"
                name="lastName"
                type="text"
                label="Last Name*"
                placeholder="Last Name"
                error={formik.errors.lastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lastName}
                touched={formik.touched.lastName ? 1 : 0}
              />
              <Input
                id="email"
                name="email"
                type="email"
                label="Email*"
                placeholder="name@example.com"
                error={formik.errors.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                touched={formik.touched.email ? 1 : 0}
              />
              {/* <Input
          id="affiliate"
          name="affiliate"
          type="text"
          label="Affiliate Address"
          placeholder="yourAffiliateAddress"
          error={formik.errors.affiliate}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.affiliate}
          touched={formik.touched.affiliate ? 1 : 0}
        /> */}
              {/* <Input
          id="photograph"
          name="photograph"
          type="text"
          label="Photograph Address"
          placeholder="yourPhotographAddress"
          error={formik.errors.photograph}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.photograph}
          touched={formik.touched.photograph ? 1 : 0}
        /> */}
              <Input
                id="phoneNumber"
                name="phoneNumber"
                type="text"
                label="Phone Number"
                placeholder="Your Phone Number"
                error={formik.errors.phoneNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phoneNumber}
                touched={formik.touched.phoneNumber ? 1 : 0}
              />

              <Input
                id="instagram_handle"
                name="instagram_handle"
                type="text"
                label="Instagram Handle"
                placeholder="Your Instagram Handle"
                error={formik.errors.instagram_handle}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.instagram_handle}
                touched={formik.touched.instagram_handle ? 1 : 0}
              />
              <Box color={"#fff"} pb="0.5rem">
                <Typography pb={"0.2rem"} fontSize={"0.8rem"} color={"#9f9f9f"}>
                  Ethnicity
                </Typography>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="demo-multiple-checkbox-label">
                    Ethnicity
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    placeholder="Ethnicity"
                    multiple
                    value={ethnicity}
                    onChange={handleChange}
                    renderValue={(selected) => selected.join(", ")}
                    MenuProps={MenuProps}
                    sx={{
                      color: "#000",
                      "& fieldset":{
                        border: "0.5px solid #3b3b3b",
                      borderRadius: "10px",
                      },
                      "& .MuiSelect-select": {
                        p: "12px",
                      },
                    }}
                    input={<OutlinedInput label="Ethnicity" />}
                  >
                    {ethnicityOptions.map((name) => (
                      <MenuItem key={name} value={name}>
                        <Checkbox checked={ethnicity?.includes(name)} />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box color={"#fff"} pb="0.5rem">
                <Typography pb={"0.2rem"} fontSize={"0.8rem"} color={"#9f9f9f"}>
                  Body type
                </Typography>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="demo-multiple-checkbox-label">
                    Body type
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    placeholder="Body type"
                    multiple
                    value={bodyTypeValue}
                    sx={{
                      color: "#000",
                      "& fieldset":{
                        border: "0.5px solid #3b3b3b",
                      borderRadius: "10px",
                      },
                      "& .MuiSelect-select": {
                        p: "12px",
                      },
                    }}
                    onChange={handleBodyTypeValue}
                    renderValue={(selected) => selected.join(", ")}
                    MenuProps={MenuProps}
                    input={<OutlinedInput label="Body type" />}
                  >
                    {bodyType.map((name) => (
                      <MenuItem key={name} value={name}>
                        <Checkbox checked={bodyTypeValue?.includes(name)} />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box color={"#fff"} pb="0.5rem">
                <Typography pb={"0.2rem"} fontSize={"0.8rem"} color={"#9f9f9f"}>
                  Hair color
                </Typography>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Hair color
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={hairColorValue}
                    label="Hair color"
                    onChange={handleHairColor}
                    sx={{
                      color: "#000",
                      "& fieldset":{
                        border: "0.5px solid #3b3b3b",
                      borderRadius: "10px",
                      },
                      "& .MuiSelect-select": {
                        p: "12px",
                      },
                    }}
                  >
                    {hairColor?.map((e, index) => (
                      <MenuItem value={e} key={index}>
                        {e}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box color={"#fff"} pb="0.5rem">
                <Typography pb={"0.2rem"} fontSize={"0.8rem"} color={"#9f9f9f"}>
                  Eye color
                </Typography>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Eye color
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={eyeColorValue}
                    label="Eye color"
                    sx={{
                      color: "#000",
                      "& fieldset":{
                        border: "0.5px solid #3b3b3b",
                      borderRadius: "10px",
                      },
                      "& .MuiSelect-select": {
                        p: "12px",
                      },
                    }}
                    onChange={handleEyeColor}
                  >
                    {eyeColor?.map((e, index) => (
                      <MenuItem value={e} key={index}>
                        {e}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              {/* <Input
          id="social"
          name="social"
          type="text"
          label="Social Media Handle"
          placeholder="yourSocialMediaHandle"
          error={formik.errors.social}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.instagram_handle}
          touched={formik.touched.instagram_handle ? 1 : 0}
        /> */}
              {/* <Input
          id="description"
          name="description"
          type="text"
          label="Anything else you'd like to share?"
          placeholder="Type here..."
          error={formik.errors.description}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.description}
          touched={formik.touched.description ? 1 : 0}
        /> */}
              {/* <Dropzone onChange={setFiles} error={fileError} /> */}
            </Box>
            {errorText && (
              <Box sx={{ p: "1rem", display: "flex" }}>
                <Typography sx={{ color: "red", fontSize: "1rem" }}>
                  {phoneNumErr ? "Phone Number must be unique" : errorText}
                  <Typography component={"span"} title="For More Info">
                    <InfoIcon
                      sx={{
                        fontSize: "1.2rem",
                        cursor: "pointer",
                        verticalAlign: "sub",
                      }}
                    />
                  </Typography>
                </Typography>
              </Box>
            )}
            {/* {newErrArray && (
        <Box sx={{ p: "1rem", display: "flex" }}>
          <Typography sx={{ color: "red", fontSize: "1rem" }}>
            {errorArray?.length} Error Ocuured.{" "}
            <Typography
              component={"span"}
              title="For More Info"
              onClick={handleClickOpen}
            >
              <InfoIcon
                sx={{
                  fontSize: "1.2rem",
                  cursor: "pointer",
                  verticalAlign: "sub",
                }}
              />
            </Typography>
          </Typography>
        </Box>
      )}
      <ErrorModal
        handleClose={handleClose}
        open={open}
        errorArray={errorArray}
      /> */}

            <div className={styles.buttonContainer}>
              {/* {isConnected?   <Button disabled={formik.isSubmitting} type="submit">
          {formik.isSubmitting ? <Loader /> : "Submit"}
        </Button>:
        <ConnectWalletBtn/>
        } */}
              <Button disabled={formik.isSubmitting} type="submit">
                {formik.isSubmitting ? <Loader /> : "Submit"}
              </Button>
            </div>
          </form>
        ) : (
          <AddGirlSuccess dark={true} />
        )}
      </div>
    </div>
  );
};

export default GirlForm;
