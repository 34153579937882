import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Input from "../Input/Input";
import Button from "../Button/Button";
import styles from "./Forms.module.css";
import axios from "axios";
import Success from "./Success";
import Loader from "../Loader/Loader";
import { ConnectWalletBtn } from "../Wallet/ConnectWallet";
import { useAccount, useFeeData } from "wagmi";
import AddGirlModal from "../AddGirlModal/AddGirlModal";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoIcon from "@mui/icons-material/Info";
import ErrorModal from "../ErrorModal/ErrorModal";
import { Box, Typography } from "@mui/material";
import { ethers } from "ethers";
import { useSearchParams } from "react-router-dom";

const PhotographForm = () => {
  const [status, setStatus] = React.useState("idle");
  const [open, setOpen] = React.useState(false);
  const [errorText, setErrorText] = React.useState(null);
  const [phoneNumErr, setPhoneNumErr] = React.useState(null);

  const [girls, setGirls] = React.useState([]);
  const [errorArray, setErrorArray] = React.useState([]);
  const [girlsName, setGirlsName] = React.useState([]);
  const [errModal, setErrModal] = React.useState(false);
  const handleClickErrOpen = () => {
    setErrModal(true);
  };
  const handleClickErrClose = () => {
    setErrModal(false);
  };
  // useEffect(() => {
  //   getDraftGirls();
  // }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [referalErr, setReferalErr] = React.useState(null);
  const [searchParams] = useSearchParams();
  const checkValidity = async (_referalCode) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/get-all-user?referalCode=${_referalCode}`
      );
      // setReferalCode(data.data.data));
      console.log(response);
      return response.data.count;
    } catch (error) {
      console.log("catch", error);
      return false;
    }
  };
  const { address, isConnected } = useAccount();
  const formik = useFormik({
    initialValues: {
      referredBy: searchParams.get("referredBy") || "",
      username: "",
      firstName: "",
      lastName: "",
      password: "",
      email: "",
      phoneNumber: "",
      instagram_handle: "",
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .trim("Username should not contain spaces")
        .min(6, "Username must be at least 6 characters")
        .max(16, "Username can be at most 16 characters")
        .matches(
          "^[a-zA-Z_0-9]{6,16}$",
          "Username should contain letters, number or underscores"
        )
        .required("Required"),
      firstName: Yup.string()
        .trim("Fisrt Name should not contain spaces")
        .required("Required"),
      lastName: Yup.string()
        .trim("Last Name should not contain spaces")
        .required("Required"),
      password: Yup.string()
        .trim("Password should not contain spaces")
        .min(8, "Password must be at least 8 characters")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
          "Password should contain at least one uppercase letter, one lowercase letter, one number, one special character."
        )
        .required("Required"),
      email: Yup.string()
        .trim("Email should not contain spaces")
        .email("Invalid email address")
        .required("Required"),
      phoneNumber: Yup.string()
        .trim("Phone number should not contain spaces"),       
      instagram_handle: Yup.string().trim(
        "Social media handle should not contain spaces"
      ),
      referredBy: Yup.string().trim(
        "Referral code should not contain spaces",
        function (value) {
          if (!value) {
            // If the field is empty, consider it as valid
            return true;
          }
        }
      ),
    }),
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      // const photographerRoute = `${process.env.REACT_APP_BACKEND}/create-photographer-profile`;
      const photographerRoute = `${process.env.REACT_APP_BACKEND}/auth/local/register`;
      setReferalErr(false);
      try {
        if (values.referredBy !== "") {
          const valid = await checkValidity(values.referredBy);
          if (!valid) {
            setReferalErr(true);
            return false;
          }
        }
        const res = await axios.post(photographerRoute, {
          user_type: "Photographer",
          ...values,
          // data: { wallet: address , ...values },
        });
        console.log(res);
        if (res.status === 200) {
          setStatus("success");
          // window.location.href = "/dashboard";
          // localStorage.setItem('jwt', res.data.jwt);
          // localStorage.setItem('id', res.data.user.id);
        } else {
          // setErrorArray(res.data.error.message);
          setErrorText(res.data.error.message);
        }
      } catch (err) {
        console.log(err);
        // setErrorArray([err.response.data.error.message]);
        setPhoneNumErr(
          err.response.data.error.details.errors?.[0].path?.[0] ===
            "phoneNumber"
        );
        setErrorText(err.response.data.error.message);
      }
      //   const config = {
      //     headers: {
      //       "Content-Type": "multipart/form-data",
      //     },
      //   };

      //   let formData = new FormData();
      //   formData.append("username", values.username);
      //   formData.append("name", values.name);
      //   formData.append("email", values.email);
      //   formData.append("phone", values.phone);
      //   formData.append("social", values.social);

      //   formData.append("wallet", values.wallet);
      //   try {
      //     const res = await axios.post(photographerRoute, formData, config);

      //     if (res.data.status === 200) {
      //       setStatus("success");
      //       resetForm();
      //     }

      //     if (res.data.status === 500) {
      //       setErrorArray(res.data.error.details.errors);
      //     }
      //   } catch (err) {
      //     console.log(err);
      //   }
    },
  });

  // const getDraftGirls = async () => {
  //   const draftGirlRoute = `${process.env.REACT_APP_BACKEND}/girls?publicationState=preview&filters[publishedAt][$null]=true&photographer=${process.env.REACT_APP_ADDRESS}`;
  //   try {
  //     const res = await axios.get(draftGirlRoute);

  //     if (res.data.data) {
  //       const updatedPhotographer = res.data.data.map(function (item) {
  //         return {
  //           name: item.attributes["name"],
  //           wallet: item.attributes["wallet"],
  //           username: item.attributes["username"],
  //         };
  //       });

  //       setGirls(updatedPhotographer);
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  // const handleDelete = async (username, wallet) => {
  //   const draftGirlRoute = `${process.env.REACT_APP_BACKEND}/photographer/girls/delete/draft?username=${username}&wallet=${wallet}&photographer=${address}`;
  //   try {
  //     const res = await axios.post(draftGirlRoute);

  //     if (res.data.status == 200) {
  //       getDraftGirls();
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };
  // const newErrArray = errorArray?.map((e) => e.path)?.[0];
  useEffect(() => {
    if (formik.values.referredBy === "") {
      setReferalErr(false);
    }
  }, [formik.values.referredBy]);
  return (
    <div className={styles.container}>
      <div className={styles.childContainer}>
        <div className={styles.heroText}>
          <h1>Sign up as a Photographer</h1>
          <p>
            Thanks for your interest in partnering with us! This is an
            opportunity to turn the thousands of pictures you’ve taken of
            beautiful women over the years into a serious stream of income!
          </p>
          <br />
          <p>
            We will turn your photographs into digital collectibles (also known
            as NFTs) and make them available for sale across the globe. 1
            picture can create thousands of digital collectibles. A collection
            of pictures can turn into thousands, tens of thousand or even
            hundreds of thousands of dollars you would be paid in commission.
            The women featured in the photos would be paid a commission as well,
            an expense that we take care of completely. <br />
            <br />
            For more details please fill out the uptake form and a
            representative will contact you shortly.
          </p>
          <br />
        </div>
      </div>
      <div className={styles.childContainer}>
        <div className={styles.formContainer}>
          {status === "idle" ? (
            <form onSubmit={formik.handleSubmit}>
              <div className={styles.inputs}>
                <Input
                  id="username"
                  name="username"
                  type="text"
                  label="Username*"
                  placeholder="username"
                  error={formik.errors.username}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.username}
                  touched={formik.touched.username ? 1 : 0}
                />
                <Input
                  id="password"
                  name="password"
                  type="password"
                  label="Password*"
                  placeholder="password"
                  error={formik.errors.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  touched={formik.touched.password ? 1 : 0}
                  eye={true}
                />
                <Input
                  id="firstName"
                  name="firstName"
                  type="text"
                  label="First Name*"
                  placeholder="First Name"
                  error={formik.errors.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.firstName}
                  touched={formik.touched.firstName ? 1 : 0}
                />
                <Input
                  id="lastName"
                  name="lastName"
                  type="text"
                  label="Last Name*"
                  placeholder="Last Name"
                  error={formik.errors.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.lastName}
                  touched={formik.touched.lastName ? 1 : 0}
                />
                <Input
                  id="email"
                  name="email"
                  type="email"
                  label="Email*"
                  placeholder="name@example.com"
                  error={formik.errors.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  touched={formik.touched.email ? 1 : 0}
                />
                <Input
                  id="phoneNumber"
                  name="phoneNumber"
                  type="tel"
                  label="Phone number"
                  placeholder="Enter your phone"
                  error={formik.errors.phoneNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phoneNumber}
                  touched={formik.touched.phoneNumber ? 1 : 0}
                />
                <Input
                  id="instagram_handle"
                  name="instagram_handle"
                  type="text"
                  label="Instagram Handle"
                  placeholder="Your Instagram Handle"
                  error={formik.errors.instagram_handle}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.instagram_handle}
                  touched={formik.touched.instagram_handle ? 1 : 0}
                />
   <Input
                  id="referredBy"
                  name="referredBy"
                  type="text"
                  label="Referred By"
                  placeholder="referral code"
                  disabled={searchParams.get("referredBy")}
                  error={formik.errors.referredBy}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.referredBy}
                  touched={formik.touched.referredBy ? 1 : 0}
                />
                {/* <div style={{ textAlign: "end", padding: "1rem 0 1.5rem" }}>
                  <button
                    title="Add Girl"
                    type="button"
                    style={{
                      background:
                        "radial-gradient(48.33% 48.33% at 50% 24.8%,rgba(255, 255, 255, 0.2) 0%,rgba(255, 255, 255, 0) 100%),radial-gradient( 61.85% 61.85% at 50% 8.33%,rgba(251, 241, 202, 0.35) 0%,rgba(255, 226, 73, 0.5)100%),#9b8021",
                      fontWeight: "600",
                      fontSize: "16px",
                      padding: "14px",
                      width: "150px",
                      color: "#fff",
                      borderRadius: "4px",
                      border: "none",
                      cursor: "pointer",
                    }}
                    onClick={handleClickOpen}
                  >
                    Add Girl
                  </button>
                </div> */}
                {/* {girls &&
                  girls?.map((v, index) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          border: "2px dashed #a6a6a6",
                          borderRadius: "12px",
                          padding: "1rem",
                          marginBottom: "1rem",
                        }}
                      >
                        <p
                          style={{
                            color: "rgb(181 176 176)",
                            textAlign: "start",
                            fontSize: "18px",
                          }}
                        >
                          {index + 1}. {v?.name}
                        </p>
                        {/* <div
                          title="Delete"
                          onClick={() => handleDelete(v?.username, v?.wallet)}
                        >
                          <DeleteIcon
                            sx={{ color: "red", cursor: "pointer" }}
                          />
                        </div>  
                      </div>
                    );
                  })} */}
                {/* <AddGirlModal
                  handleClose={handleClose}
                  open={open}
                  getDraftGirls={getDraftGirls}
                /> */}
              </div>
              {errorText && (
                <Box sx={{ p: "1rem", display: "flex" }}>
                  <Typography sx={{ color: "red", fontSize: "1rem" }}>
                    {phoneNumErr ? "Phone Number must be unique" : errorText}
                    <Typography component={"span"} title="For More Info">
                      <InfoIcon
                        sx={{
                          fontSize: "1.2rem",
                          cursor: "pointer",
                          verticalAlign: "sub",
                        }}
                      />
                    </Typography>
                  </Typography>
                </Box>
              )}
              {referalErr && (
                <Box sx={{ p: "1rem", display: "flex" }}>
                  <Typography sx={{ color: "red", fontSize: "1rem" }}>
                    {referalErr && "Referal Code is not valid."}
                  </Typography>
                </Box>
              )}
              {/* {errorArray.length > 0 && (
                <Box sx={{ p: "1rem", display: "flex" }}>
                  <Typography sx={{ color: "red", fontSize: "1rem" }}>
                    {errorArray?.length} Error Ocuured.{" "}
                    <Typography
                      component={"span"}
                      title="For More Info"
                      onClick={handleClickErrOpen}
                    >
                      <InfoIcon
                        sx={{
                          fontSize: "1.2rem",
                          cursor: "pointer",
                          verticalAlign: "sub",
                        }}
                      />
                    </Typography>
                  </Typography>
                </Box>
              )} */}
              {/* <ErrorModal
                handleClose={handleClickErrClose}
                open={errModal}
                errorArray={errorArray}
              /> */}
              <div className={styles.buttonContainer}>
                {/* {isConnected?   <Button disabled={formik.isSubmitting} type="submit">
                  {formik.isSubmitting ? <Loader /> : "Submit"}
                </Button>:
                <ConnectWalletBtn/>
                } */}
                <Button disabled={formik.isSubmitting} type="submit">
                  {formik.isSubmitting ? <Loader /> : "Submit"}
                </Button>
              </div>
            </form>
          ) : (
            <Success />
          )}
        </div>
      </div>
    </div>
  );
};

export default PhotographForm;

const generateId = () => {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < 10; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
