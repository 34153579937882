import React, { useEffect } from "react";
import Container from "../Component/Dashboard/Container/Container";
import Navbar from "../Component/Dashboard/Navbar/Navbar";
import Header from "../Component/Dashboard/Header/Header";
import { useProfile } from "../Component/Dashboard/Hooks/useDashboard";
import { Link } from "react-router-dom";

import ProfileTabs from "./ProfileTabs/ProfileTabs";
import EditAffiliateForm from "../Component/Dashboard/Forms/EditAffiliateForm";

const Profile = () => {
  const profile = useProfile();

  useEffect(() => {
    document.title = "My Profile";
  }, []);

  // if (isEmpty(profile)) return <div>Loading...</div>;

  return (
    <Container>
      <Navbar profile={profile} name={`${profile?.firstName} ${profile?.lastName}`} />
      <Header
        message={"My Profile"}
        breadcrumbs={
          <Link style={{ textDecoration: "none", opacity: 0.8 }} to="/profile">
            Profile
          </Link>
        }
      />
      <ProfileTabs />
      {/* <EditAffiliateForm profile={profile} /> */}

      {/* {userType === "girl" ? (
        <EditDreamGirlForm profile={profile} />
      ) : userType === "affiliate" ? (
        <EditAffiliateForm profile={profile} />
      ) : null} */}
    </Container>
  );
};

export default Profile;
