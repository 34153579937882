import React, { useEffect, useState } from "react";
import Container from "../Component/Dashboard/Container/Container";
import Navbar from "../Component/Dashboard/Navbar/Navbar";
import Header from "../Component/Dashboard/Header/Header";
import { useProfile } from "../Component/Dashboard/Hooks/useDashboard";
import Content from "../Component/Dashboard/Container/Content";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { JWT } from "../Helper/Constants";
import axios from "axios";
import VideoButton from "../Component/Dashboard/Video/VideoButton";
import styles from "../Component/Dashboard/Container/Container.module.css";
import { Box } from "@mui/material";
import RecordChart from "./RecordChart";

const WithdrawalList = () => {
 
  const [requestList, setRequestList] = useState([]);

  const [maticPrice, setMaticPrice] = useState(null);
  const getAllRequest = async () => {
    try {
      const data = await axios.get(
        `${
          process.env.REACT_APP_BACKEND
        }/get-all-request/${localStorage.getItem("id")}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      if (data.status === 200) {
        // console.log(data);
        setRequestList(data.data.data);
      }
    } catch (error) {
      console.log("catch", error);
    }
  };
  const getMaticPrice = async (next) => {
    const config = {
      headers: {
        // authorization: localStorage.getItem(JWT),
        // Authorization: `Bearer ${localStorage.getItem(JWT)}`,
      },
    };
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_BACKEND}/get/maticPrice`,
        config
      );
      setMaticPrice(data.data.formatValue);
    } catch (error) {
      console.log("catch", error);
    }
  };
  useEffect(() => {
    getAllRequest();
    getMaticPrice()
  }, []);
  const profile = useProfile();
  return (
    <Container>
      {profile && (
        <Navbar profile={profile} name={`${profile?.firstName} ${profile?.lastName}`} />
      )}
      <Header message={`Welcome back, ${profile?.firstName} ${profile?.lastName}`} title="Withdrawals" />
      <div className={styles.mainContent_custom}>
        <TableContainer
          component={Paper}
          // sx={{
          //   width:{md:"70%",xs:"100%"},
          //   margin:"0 auto"
          // }}
        >
          <Table sx={{ minWidth: 550 }} aria-label="simple table">
            <TableHead>
              <TableRow
                sx={{
                  "& th": {
                    fontWeight: "bold",
                  },
                }}
              >
                <TableCell align="left">Date</TableCell>
                <TableCell align="left">Amount</TableCell>
                <TableCell align="left">Txn ID</TableCell>
                <TableCell align="left">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {requestList?.map((row) => (
                <TableRow
                  key={row.userid}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="left">
                    {new Date(row.createdAt).toDateString()}
                  </TableCell>
                  <TableCell align="left">$ {row.amount}
                   {/* ~ $ {(row.amount)*maticPrice} */}
                   </TableCell>
                  <TableCell align="left">
               

                    {requestList?.method=="Crypto"?<a style={{color:"#0066FF"}} href={`${process.env.REACT_APP_EXPLORE_URL}/${row.txn_id}`} target="_blank">{row.txn_id?.substring(0,5)+"..."+row.txn_id?.substring(row.txn_id?.length-4)}</a>:row.txn_id ? row.txn_id : "NA"} ({row.method=="Crypto"?"ERC 20 Crypto Wallet Address":row.method})
                  </TableCell>
                  <TableCell align="left">{row.status}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        {requestList?.length===0 &&  <Box sx={{
                fontSize:"18px",
                fontWeight:"600",
                textAlign:"center",
                py:"3rem"
              }}>
                No data found.
              </Box>}
        </TableContainer>
     <RecordChart requestList={requestList} />
      </div>
      <Content>
        <VideoButton flex={1} />
      </Content>
    </Container>
  );
};

export default WithdrawalList;
