import React, { useState } from 'react'
import { Box, Button, Grid, Typography } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import SalesList from '../SalesList/SalesList';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AffiliateList = ({referredUsers,referredPhotographerUsers}) => {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    // navigate(`/profile/${tabNames[newValue]}`);
  };
  return (
    <Box sx={{
      "& input":{
        color:"#000 !important"
      },
      position: "relative",
  "@media (max-width:991px)": {
    padding: "20px",
  },
  "@media (max-width:600px)": {
    padding: "10px",
    // "& .MuiTabs-scroller":{
    //   overflowX:"scroll !important"
    // }
  },
  "& .MuiButtonBase-root.Mui-selected": {
    color: "#c8c862 !important",
    textShadow: "inherit !important",
    fontSize: "18px !important",
    padding: "10px !important",
  },
  "& .MuiButtonBase-root": {
    color: "#000 !important",
    textTransform:"capitalize"
  },
  "& .MuiTabs-indicator": {
    backgroundColor: "#c8c862 !important",
  },
  "& .MuiTouchRipple-root": {
    display: "none",
  },
  "& .MuiTabs-scroller": {
    overflow: "auto !important",
  },
    }}>
      <Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
              <Tab
                sx={{
                  fontSize: "18px !important",
                  padding: "10px !important",
                }}
                label={`Referred girl`}
                {...a11yProps(0)}
              />
              <Tab
                sx={{
                  fontSize: "18px !important",
                  padding: "10px !important",
                }}
                label="Referred photographers/studios"
                {...a11yProps(1)}
              />
             
              
       
          </Tabs>
        </Box>

      
          <TabPanel value={value} index={0}>
          <SalesList flex={2} referredUsers={referredUsers} display={"none"}/>
          </TabPanel>
          <TabPanel value={value} index={1}>
          <SalesList flex={2} referredUsers={referredPhotographerUsers} display={"none"}/>
          </TabPanel>
   
    
      </Box>
    </Box>
  )
}

export default AffiliateList