import React from "react";
import Footer from "../Component/Footer/Footer";
import Navbar from "../Component/Navbar/Navbar";

import Timeline from "../Component/Timeline/Timeline";

const About = () => {
  return (
    <>
      <Navbar />
      <Timeline />
      <Footer />
    </>
  );
};

export default About;
