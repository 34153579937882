import React from "react";
import styles from "./Footer.module.css";
import DreamGirls from "../../Assets/png/DreamGirls.png";
import insta from "../../Assets/svg/insta.svg";
import discord from "../../Assets/svg/discord.svg";
import linkedin from "../../Assets/svg/linkedin.svg";
import telegram from "../../Assets/svg/telegram.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerLayout}>
        <div className={styles.company}>
          <div>
            <Link to="/">
              <img src={DreamGirls} alt="" />
            </Link>
          </div>
          <div>
            <div className={styles.desc}>
              <p>Join DreamGirls Community</p>
            </div>
            <div className={styles.social}>
              <div>
                <img src={discord} alt="" />
              </div>
              <div>
                <img src={telegram} alt="" />
              </div>
              <div>
                <img src={insta} alt="" />
              </div>
              <div>
                <img src={linkedin} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.community}>
          <div>
            <h3>About us</h3>
          </div>
          <div>
            <Link className={styles.link} to="/">
              <p>Home</p>
            </Link>
          </div>
          <div>
            <Link className={styles.link} to="/roadmap">
              <p>Roadmap</p>
            </Link>
          </div>
          <div>
            <Link className={styles.link} to="/team">
              <p>Team</p>
            </Link>
          </div>
          <div>
            <Link className={styles.link} to="/disclaimer">
              <p>Disclaimer</p>
            </Link>
          </div>
        </div>
        <div className={styles.links}>
          <div>
            <h3>Community</h3>
          </div>
          <div>
            <Link to="/dreamgirl-application" className={styles.link}>
              <p>Become a DreamGirl</p>
            </Link>
          </div>
          <div>
            <Link className={styles.link} to="/affiliate">
              <p>Affiliate</p>
            </Link>
          </div>
          <div>
            <Link className={styles.link} to="/business">
              <p>Partner</p>
            </Link>
          </div>
          <div>
            <Link className={styles.link} to="/whitelist">
              <p>Early Access</p>
            </Link>
          </div>
        </div>
      </div>
      <div className={styles.copyright}>
        © 2022 Dream Girls @ All Rights Reserved
      </div>
    </footer>
  );
};

export default Footer;
