import { Box, Typography } from '@mui/material'
import React from 'react'


const GirlImages = ({girlId,colelctionId,index,classes}) => {
// alert(imageData?.url)
  return (
    <div>
         <Typography
            >
              {" "}
              <Box
                sx={{
                  textAlign: "center",
                  borderRadius: "15px",
                //   height: "255px",
                }}
              >
                <Typography
                  component={"img"}
                  src={`${process.env.REACT_APP_META_URL}/public/collection-${girlId}-${colelctionId}/${index}.jpg`}
                  className={classes.nft_img}
                  sx={{
                    textAlign: "center",
                    borderRadius: "15px",
                    height: "100%",
                    width: "100%",
                  }}
                />
              </Box>
            </Typography>
    </div>
  )
}

export default GirlImages