import React, { useEffect, useState } from "react";
import referral from "../../../Assets/png/referral.png";
import copy from "../../../Assets/svg/Copy.svg";
import verify from "../../../Assets/png/kyc.png";
import styles from "./Stats.module.css";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import PaidIcon from "@mui/icons-material/Paid";
import axios from "axios";
import { useAccount } from "wagmi";
import { JWT, TOKEN } from "../../../Helper/Constants";
import Withdraw from "../../Withdraw/Withdraw";
import { Link } from "react-router-dom";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ReferralLinks from "./ReferralLinks";
import CopyToClipboard from "react-copy-to-clipboard";
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { useProfile } from "../Hooks/useDashboard";

export const Referral = ({ approved, user_type, username, referralCode }) => {
  const { address } = useAccount();
  const [earningData, setEarningData] = useState([]);
  const [maticPrice, setMaticPrice] = useState(null);
  const [withdrawModal, setWithdrawModal] = useState(false);
  const [referralModal, setReferralModal] = useState(false);
  const [affiliate, setAffiliate] = useState(0);
  const [girl, setGirl] = useState(0);
  const [photograph, setPhotograph] = useState(0);
  const profile = useProfile()

  const getAllEarning = async (next) => {
    const config = {
      headers: {
        // authorization: localStorage.getItem(JWT),
        Authorization: `Bearer ${localStorage.getItem(JWT)}`,
      },
    };
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_BACKEND}/get-all-earning`,
        config
      );
      setEarningData(data.data.balance);
    } catch (error) {
      console.log("catch", error);
    }
  };
  const getMaticPrice = async (next) => {
    const config = {
      headers: {
        // authorization: localStorage.getItem(JWT),
        // Authorization: `Bearer ${localStorage.getItem(JWT)}`,
      },
    };
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_BACKEND}/get/maticPrice`,
        config
      );
      setMaticPrice(data.data.formatValue);
    } catch (error) {
      console.log("catch", error);
    }
  };

  // const affiliateEarnings =
  //   earningData?.affiliateEarning?.map(
  //     (e) => parseInt(e.affilaite_earning) / 1e10,
  //   ) || [];

  // const photographerEarnings =
  //   earningData?.photographerEarning?.map(
  //     (e) =>
  //       parseInt(e.photographer_earning) / 1e1 ** process.env.REACT_APP_DECIMAL,
  //   ) || [];

  // const girlEarnings =
  //   earningData?.girlEarning?.map(
  //     (e) => parseInt(e.girl_earning) / 1e1 ** process.env.REACT_APP_DECIMAL,
  //   ) || [];
  // useEffect(() => {
  //   const calculateTotalEarnings = (earnings, setEarnings) => {
  //     const totalEarnings = earnings.reduce(
  //       (accumulator, currentValue) => accumulator + currentValue,
  //       0,
  //     );
  //     setEarnings(totalEarnings);
  //   };
  //   calculateTotalEarnings(affiliateEarnings, setAffiliate);
  //   calculateTotalEarnings(photographerEarnings, setPhotograph);
  //   calculateTotalEarnings(girlEarnings, setGirl);
  // }, [affiliateEarnings, photographerEarnings, girlEarnings]);

  useEffect(() => {
    getAllEarning();
    getMaticPrice();
  }, []);
  const handleClick = () => {
    navigator.clipboard.writeText(
      `${window.location.hostname}/dreamgirl-application?referredBy=${username}`
    );
  };
  const [isCopied, setIsCopied] = useState(false);
  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  };
  
  return (
    <>
      {(user_type == "Affiliate" ||
        user_type == "Photographer" ||
        user_type == "Girl") && (
        <Box
          sx={{
            p: "1.3rem",
            background: "#ffffff",
            border: "1px solid #eeeeee",
            boxShadow: "0px 4px 16px rgba(103, 103, 103, 0.04)",
            borderRadius: "4px",
            width: {
              // sm: user_type == "Affiliate" ? "280px" : "auto",
              // xs: user_type == "Affiliate" ? "100%" : "auto",
            },
          }}
        >
        <Box sx={{
          display:"flex",
          alignItems:"center",
          gap:"1rem",
          mb:"0.5rem"
        }}>
        <img
            src={referral}
            alt="referral"
            // style={{ marginBottom: "0.5rem" }}
          />
     
          <Typography sx={{
               fontSize: "1.3rem",
                fontWeight: "600",
                "@media (max-width:600px)": {
                  fontSize: "1rem",
                },
          }}>
          My Referral Links
          </Typography>
     
        </Box>
          {/* {(user_type == "Photographer" || user_type == "Girl") && ( */}
            <Typography
              className={styles.referralCode}
              sx={{
                fontSize: "1.5rem",
                fontWeight: "600",
                "@media (max-width:600px)": {
                  fontSize: "1rem",
                },
              }}
            >
              <span
                style={{
                  pointerEvents: "none",
                }}
              >{`dreamgirlsnft.com/..${username}`}</span>

              {/* <button className={styles.copyButton} onClick={handleClick}>
              <img src={copy} alt="Copy" />
            </button> */}
              <CopyToClipboard
                text={`${window.location.hostname}/dreamgirl-application?referredBy=${username}`}
                onCopy={onCopyText}
              >
                <Tooltip
                  arrow
                  title={isCopied ? "Copied" : "Copy"}
                  placement="top"
                >
                  <ContentCopyIcon sx={{ cursor: "pointer", ml: "5px" }} />
                </Tooltip>
              </CopyToClipboard>
            </Typography>
          {/* )} */}
          <span
            className={styles.label}
            style={{ cursor: "pointer" }}
            onClick={
              user_type === "Affiliate"
                ? () => setReferralModal(true)
                : undefined
            }
          >
           Use above link to refer Girls.
          </span>

          {(user_type == "Affiliate") && (
              <>
                 <Typography
              className={styles.referralCode}
              sx={{
                fontSize: "1.5rem",
                fontWeight: "600",
                "@media (max-width:600px)": {
                  fontSize: "1rem",
                },
              }}
            >
              <span
                style={{
                  pointerEvents: "none",
                }}
              >{`dreamgirlsnft.com/..${username}`}</span>
 
              {/* <button className={styles.copyButton} onClick={handleClick}>
              <img src={copy} alt="Copy" />
            </button> */}
              <CopyToClipboard
                text={`${window.location.hostname}/photograph?referredBy=${username}`}
                onCopy={onCopyText}
              >
                <Tooltip
                  arrow
                  title={isCopied ? "Copied" : "Copy"}
                  placement="top"
                >
                  <ContentCopyIcon sx={{ cursor: "pointer", ml: "5px" }} />
                </Tooltip>
              </CopyToClipboard>
            </Typography>
          {/* )} */}
          <span
            className={styles.label}
            style={{ cursor: "pointer" }}
            onClick={
              user_type === "Affiliate"
                ? () => setReferralModal(true)
                : undefined
            }
          >
            Use above link to refer Photographers/Studios.

          </span>
              </>
          )}
          
       
        </Box>
      )}
      <Box
        sx={{
          p: "1.3rem",
          background: "#ffffff",
          border: "1px solid #eeeeee",
          boxShadow: "0px 4px 16px rgba(103, 103, 103, 0.04)",
          borderRadius: "4px",
          "@media (min-width:900px)": {
            width: "60%",
          },
          "@media (max-width:700px)": {
            width: "100%",
          },
        }}
      >
          <Box sx={{
          display:"flex",
          alignItems:"center",
          gap:"1rem",
          mb:"0.5rem"
        }}>
        {/* <img
            src={referral}
            alt="referral"
            style={{ marginBottom: "0.5rem" }}
          /> */}
     
       <Box sx={{
        lineHeight:"0",
        border:"1px solid #dcc4dc",
        width:"52px",
        height:"52px",
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        borderRadius:"5px",
        background:"#f6ddf6"

       }}>
       <MonetizationOnIcon sx={{
           fontSize:"2.2rem",
           color:"#4e1f4f"
          }} />
       </Box>
  
          <Typography sx={{
               fontSize: "1.3rem",
                fontWeight: "600",
                "@media (max-width:600px)": {
                  fontSize: "1rem",
                },
          }}>
          My Earnings
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "1rem",
            alignItems: "center",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography
              className={styles.referralCode}
              sx={{
                fontSize: "1.5rem",
                fontWeight: "600",
                "@media (max-width:600px)": {
                  fontSize: "1rem",
                },
              }}
            >
              $ {isNaN(earningData) ? 0 : parseFloat(earningData).toFixed(2)}
              {/* ~ $ {isNaN(earningData) ? 0 : earningData*maticPrice} */}
            </Typography>
            <span className={styles.label}>
              {/* {user_type}  */}
             Available to withdraw
            </span>
          </Box>

          <Box>
            <Button
              sx={{
                background: "#1d1e1f",
                color: "#fff",
                p: "0.5rem 1rem",
                "&:hover": {
                  background: "#1d1e1f",
                },
                "&.Mui-disabled": {
                  cursor: "not-allowed !important",
                  pointerEvents: "auto !important",
                  color: "rgb(255 255 255 / 60%) !important",
                  background: "#1d1e1fbd",
                  "&:hover": {
                    opacity: "1",
                  },
                },
              }}
              disabled={approved != "Approved" || earningData == 0}
              onClick={() => setWithdrawModal(true)}
            >
              Withdraw
            </Button>
            {/* <Typography sx={{textAlign:"right",display:"block",color:"black !important"}} component={Link} to="/withdrawl-list">History</Typography> */}
            <Withdraw
              setWithdrawModal={setWithdrawModal}
              withdrawModal={withdrawModal}
              earningData={earningData}
              maticPrice={maticPrice}
            />
            <ReferralLinks
              referralModal={referralModal}
              setReferralModal={setReferralModal}
              username={username}
            />
          </Box>
        </Box>
      </Box>

      {/* <div className={styles.cards}>
      <img src={referral} alt="referral" />
      <h3 className={styles.referralCode}>
        <span
          style={{
            pointerEvents: "none",
          }}>{`dreamgirlsnft.com/${username}`}</span>

        <button className={styles.copyButton} onClick={handleClick}>
          <img src={copy} alt="Copy" />
        </button>
      </h3>
      <span className={styles.label}>My Referral Link</span>
    </div> */}
    </>
  );
};
