import React from "react";
import styles from "./SalesList.module.css";
import { Box } from "@mui/material";
import SaleListRow from "./SaleListRow";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const SalesList = ({ flex, referredUsers,display}) => {
  return (
    <div style={{ flex: flex }}>
      <div className={styles.headerContainer} style={{display:display&&display}}>
        <p className={styles.header}>Referred Users</p>
      </div>
      <Box>
      <TableContainer component={Paper} sx={{mt:"2rem","& th":{
        color:"rgb(0 0 0 / 49%)",
        fontWeight:"600"
      },
      "& tr":{
                borderBottom:"1px solid rgb(224 224 224)"
              },
              
              }} >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Username</TableCell>
            <TableCell align="right">Name</TableCell>
            <TableCell align="right">Status</TableCell>
            <TableCell align="right">Confirmed</TableCell>
            <TableCell align="right">Earning</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {referredUsers?.length > 0 ? (
          referredUsers?.map((user, index) => {
            return <SaleListRow index={index} user={user} />;
          })
        ) : (
          <TableRow sx={{ p: "3rem", textAlign: "center" }}>
          <TableCell colSpan={5} style={{textAlign: "center"}}>
          No referred users  found.
          </TableCell>
        </TableRow>
        )}
       
        </TableBody>
      </Table>
    </TableContainer>
    </Box>
    </div>
  );
};

export default SalesList;
