import React, { memo, useEffect, useState } from "react";
import Button from "../../Button/Button";
import styles from "./Forms.module.css";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import axios from "axios";
import { Alert, Grid, Snackbar, Typography } from "@mui/material";
import { TOKEN } from "../../../Helper/Constants";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ethnicityOptions = [
  'Caucasian/White',
  'Asian',
  'Spanish/Latino',
  'Black/ African',
  'Southeast Asian / Indian',
  'Middle Eastern',
  'Mixed',
  'Native',
];
const bodyType = [
 'Skinny',
 'Slim',
 'Fit & Toned',
 'Muscular',
 'Slim Thick',
 'Curves For Days',
 'BBW'
];
const hairColor = [
 'Blond',
 'Brunette',
 'Black',
 'Brown',
 'Red Head',
 'Colorful AF',
 'Other',
];
const eyeColor = [
'Blue',
'Green',
'Brown',
'Hazel',
'Other'
];

const ProfileCategoryForm = () => {
  const [profile, setProfile] = React.useState({});
  const [ethnicity, setEthnicity] = React.useState([]);
  const [bodyTypeValue, setBodyTypeValue] = React.useState([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [hairColorValue, setHairColorValue] = React.useState('');
  const [eyeColorValue, setEyeColorValue] = React.useState('');
  const fetchProfile = async (next) => {
   const jwt = localStorage.getItem('jwt') ; 
   const config = {
     headers: {
       Authorization: `Bearer ${jwt}`,
     },
   };
   try {
     const data = await axios.get(
       `${process.env.REACT_APP_BACKEND}/users/me?populate=*`,
       config,
     );
     setProfile( data.data);
     setEthnicity(data.data.ethnicity.split(','));
    setBodyTypeValue(data.data.bodyType.split(','));
   } catch (error) {
     console.log("catch", error);
     // handle401(error.response, next);
     // handle403(error.response, next);
   }
 };
 useEffect(()=>{
   fetchProfile()
 },[])

useEffect(()=>{
if(profile?.hairColor){
setHairColorValue(profile?.hairColor)
}
},[profile?.hairColor])
useEffect(()=>{
if(profile?.eyeColor){
setEyeColorValue(profile?.eyeColor)
}
},[profile?.eyeColor])
  const handleHairColor = (event) => {
    setHairColorValue(event.target.value);
  };
  const handleEyeColor = (event) => {
    setEyeColorValue(event.target.value);
  };
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
      if (value.length > 2) {
      // If more than 2 options are selected, keep only the first two selections
      setEthnicity(value.slice(0, 2));
    } else {
      setEthnicity(value);
    }
  };
  const handleBodyTypeValue = (event) => {
    const {
      target: { value },
    } = event;
    if (value.length > 2) {
      // If more than 2 options are selected, keep only the first two selections
      setBodyTypeValue(value.slice(0, 2));
    } else {
      setBodyTypeValue(value);
    }
  };
const handleSubmit= async()=>{
  const updateProfile = `${process.env.REACT_APP_BACKEND}/users/${profile?.id}`;
  const jwt = localStorage.getItem('jwt') ; 
    setSubmitted(true)
  const config = {
    headers: {
      "Content-Type": "application/json",
      authorization: localStorage.getItem(TOKEN),
      Authorization: `Bearer ${jwt}`,

    },
  };

  try {
    const res = await axios.put(updateProfile, {
      ethnicity: ethnicity.join(','), // Convert array to string
      bodyType: bodyTypeValue.join(','), // Convert array to string
      hairColor:hairColorValue,
      eyeColor:eyeColorValue

    }, config);
    setSubmitted(false)

    if (res.status === 200) {

      fetchProfile()
      setShowSnackbar(true)
      setTimeout(() => {
        setShowSnackbar(false)
      }, 2000);
    }
  } catch (err) {
    console.log(err);
    setSubmitted(false)

  }
}
  return (
    <>
      {/* <div className={styles.container}>
      <div className={styles.childContainer}> */}
      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        sx={{
          "& .MuiSvgIcon-root": {
            color: "#fff",
          },
        }}
      >
        <Alert
          severity={"success"}
          style={{
            backgroundColor: "green",
            color: "white",
            fontSize: "14px",
            fontFamily: "ABLE",
            display: "flex",
            alignItems: "center",
            fontWeight: "600",
          }}
        >
          Profile Updated successfully.
        </Alert>
      </Snackbar>
      <div className={styles.formContainer} style={{ margin: "0 auto",display:"block" }}>
        <div>
          <div className={styles.inputs}>
          <Grid container spacing={2} mb={"1.5rem"} mt={"0.5rem"} sx={{
            "& .MuiOutlinedInput-root":{
              "&:hover":{
                border:"none"
              }
            }
          }}>
          <Grid item md={12} sm={12} xs={12} >
            
          <p style={{fontSize:"20px",fontWeight:"bold",paddingBottom:"0.2rem"}}>Tell us about your look to boost your sales and help our collectors find their perfect Dreamgirl</p>
          </Grid>
          <Grid item md={6} sm={12} xs={12} >
          <p style={{fontSize:"20px",fontWeight:"bold",paddingBottom:"0.2rem"}}>Ethnicity</p>
          <FormControl sx={{ width: "100%" }}>
    <InputLabel id="demo-multiple-checkbox-label">Ethnicity</InputLabel>
        <Select
        labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          placeholder="Ethnicity"
          multiple
          value={ethnicity}
          onChange={handleChange}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
          input={<OutlinedInput label="Ethnicity" />}
        >
          {ethnicityOptions.map((name) => 
         (
            
            <MenuItem key={name} value={name}>
              <Checkbox checked={ethnicity?.includes(name)} />
              <ListItemText primary={name} />
            </MenuItem>
        
          )
          )}
        </Select>
      </FormControl>
          </Grid>
          <Grid item md={6} sm={12} xs={12} >
          <p style={{fontSize:"20px",fontWeight:"bold",paddingBottom:"0.2rem"}}>Body type</p>
          <FormControl sx={{ width: "100%" }}>
    <InputLabel id="demo-multiple-checkbox-label">Body type</InputLabel>
        <Select
        labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          placeholder="Body type"
          multiple
          value={bodyTypeValue}
          onChange={handleBodyTypeValue}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
          input={<OutlinedInput label="Body type" />}
        >
          {bodyType.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={bodyTypeValue?.includes(name)} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
          </Grid>
          <Grid item md={6} sm={12} xs={12} >
          <p style={{fontSize:"20px",fontWeight:"bold",paddingBottom:"0.2rem"}}>Hair color</p>
          <FormControl fullWidth>
  <InputLabel id="demo-simple-select-label">Hair color</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={hairColorValue}
    label="Hair color"
    onChange={handleHairColor}
  >
  {
    hairColor?.map((e,index)=>(
      <MenuItem value={e} key={index}>{e}</MenuItem>
    ))
  }
  </Select>
</FormControl>
          </Grid>
          <Grid item md={6} sm={12} xs={12} >
          <p style={{fontSize:"20px",fontWeight:"bold",paddingBottom:"0.2rem"}}>Eye color</p>
          <FormControl fullWidth>
  <InputLabel id="demo-simple-select-label">Eye color</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={eyeColorValue}
    label="Eye color"
    onChange={handleEyeColor}
  >
  {
    eyeColor?.map((e,index)=>(
      <MenuItem value={e} key={index}>{e}</MenuItem>
    ))
  }
  </Select>
</FormControl>
          </Grid>
          </Grid>
          
          </div>
       
              <div className={styles.buttonContainer} style={{justifyContent:"flex-end"}}>
            <Button type="submit"  disabled={submitted} onClick={handleSubmit}>
            {submitted ? "Submitted" : "Save Changes"}
            </Button>
          </div>
        </div>
      </div>
      {/* </div>
    </div> */}
    </>
  );
};

export default memo(ProfileCategoryForm);

const isEmpty = (obj) => {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};
