import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const RecordChart = ({ data,maticPrice }) => {
  const _amounts =
    data?.length > 0
      ? data.map((e) => {
          return (e.amount*maticPrice);
        })
      : [];
  // console.log(_amounts);
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (data?.length > 0) {
      setChartData({
        options: {
          theme: {
            mode: "light",
          },
          chart: {
            type: "bar",
            background: "#fff", // Set white background
            foreColor: "#333", // Set text color

            toolbar: {
              show: false,
            },
            borderRadius: 8, // Set border radius for rounded corners
          },
          dataLabels: {
            enabled: false, // Enable data labels
            style: {
              colors: ["#333"], // Set the color for data labels to black
            },
          },
          grid: {
            show: false,
          },
          xaxis: {
            type: "datetime",
            categories: data.map((e) => {
              return e.createdAt;
            }),
          },
          yaxis: {
            labels: {
              formatter: (value) => `$${value}`,
            },
          },
          plotOptions: {
            bar: {
              borderRadius: 0, // Set to 0 to remove bar corner radius
              barWidth: 1, // Adjust the width of the bars
              columnWidth: "50%",

              colors: {
                ranges: [
                  {
                    from: 0,
                    to: 50,
                    color: "#f9cd31", // Red for values 0-50
                  },
                  {
                    from: 51,
                    to: 100,
                    color: "#00FF00", // Green for values 51-100
                  },
                  // Add more ranges as needed
                ],
              },
              borderRadius: {
                topLeft: 8, // Set radius for the top left corner
                topRight: 8, // Set radius for the top right corner
                bottomLeft: 0, // Set radius for the bottom left corner
                bottomRight: 0, // Set radius for the bottom right corner
              },
            },
          },
        },

        series: [
          {
            name: "Earning",
            data: _amounts,
          },
        ],
      });
    }
  }, [data]);
  return (
    <div>
      {chartData?.options && chartData?.series && (
        <ReactApexChart
          options={chartData?.options}
          series={chartData?.series}
          type="bar"
          height={300}
          style={{
            boxShadow:
              "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
          }}
        />
      )}
    </div>
  );
};

export default RecordChart;
