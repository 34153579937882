import React from "react";
import Footer from "../Component/Footer/Footer";
import Navbar from "../Component/Navbar/Navbar";
import Team from "../Component/Team/Team";
const Teams = () => {
  return (
    <>
      <Navbar />
      <Team />
      <Footer />
    </>
  );
};

export default Teams;
