import React, { useEffect, useState } from "react";
import styles from "./SalesList.module.css";
import axios from "axios";
import { JWT, TOKEN } from "../../../Helper/Constants";
import ImageIcon from "@mui/icons-material/Image";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box, Button, Grid, TableCell, TableRow, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import Dropzone from "../../Forms/DragnDrop";
import Loader from "../../Loader/Loader";
import ImageList from "./ImageList";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
const PhotographerLIstRow = ({ index, user, fetchPhotographerUsers }) => {
  console.log(user);
  const [open, setOpen] = React.useState(false);
  const [imageOpen, setImageOpen] = React.useState(false);
  const [open_notification, setOpen_notification] = React.useState(false);
  const [approvedImages, setApprovedImages] = useState([]);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleImageOpen = () => {
    setImageOpen(true);
  };
  const handleImageClose = () => {
    setImageOpen(false);
  };
  const handleToggleNotifcation = () => {
    setOpen_notification((prevOpen) => !prevOpen);
  };
  const anchorRef = React.useRef(null);
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      // anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  const anchorRef_1 = React.useRef(null);
  const handleCloseNotification = (event) => {
    if (anchorRef_1.current && anchorRef_1.current.contains(event.target)) {
      return;
    }

    setOpen_notification(false);
  };
  function handleListKeyDown_1(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen_notification(false);
    } else if (event.key === "Escape") {
      setOpen_notification(false);
    }
  }
// console.log(localStorage.getItem("jwt"));
  const [earningData, setEarningData] = useState([]);
  const [earningRef, setEarningRef] = useState(0);
  const getAllEarning = async (next) => {
    try {
      const config = {
        headers: {
         'Authorization': `Bearer ${localStorage.getItem("jwt")}`
        },
      };
      const data = await axios.get(
        `${process.env.REACT_APP_BACKEND}/get-aff-earning-by-userid/${user?.id}`,
        config
      );
      // setEarningData(data.data);
      setEarningRef(data.data.amount)
    } catch (error) {
      console.log("catch", error);
    }
  };
  const sendForApproval = async () => {
    const config = {
      headers: {
        // authorization: localStorage.getItem(JWT),
        Authorization: `Bearer ${localStorage.getItem(JWT)}`,
      },
    };
    try {
      const data = await axios.put(
        `${process.env.REACT_APP_BACKEND}/users/request-approval`,
        {id:user?.id},
        config
      );
      // console.log(data);
      if (data.data.status == "Pending") {
        window.location.reload();
      }
      // console.log(data);
      // setEarningData(data.data);
    } catch (error) {
      console.log("catch", error);
    }
  };

  const getAllCollection = async () => {
    await axios({
      method: 'GET',
      url: `${process.env.REACT_APP_BACKEND}/getSingleCollectionAdmin/${user?.id}`,
      headers: {
        'Accept-Encoding': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem("jwt")}`,

        // 'Origin' : process.env.ORIGIN
      },
    })
      .then((_data) => {
        setApprovedImages(_data.data.collection);
      })
      .catch((err) => {
        //  throw err
        console.log(err);
      });
  };
  // console.log(approvedImages);
  useEffect(()=>{
    if(user?.id){
      getAllCollection()
    }
  },[user?.id])
 

  // useEffect(() => {
  //   const calculateTotalEarnings = (earnings, setEarnings) => {
  //     const totalEarnings = earnings.reduce(
  //       (accumulator, currentValue) => accumulator + currentValue,
  //       0
  //     );
  //     setEarnings(totalEarnings);
  //   };
  //   calculateTotalEarnings(photographerEarnings, setEarningRef);
  // }, [photographerEarnings]);

  useEffect(() => {
  if(user?.id){
    getAllEarning();
  }
  }, [user?.id]);
  return (
    <>
      <TableRow
              key={user.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 }, }}
            >
              <TableCell scope="row">
              {user.username}
              </TableCell>
              <TableCell align="right"> {user.firstName} {user.lastName}</TableCell>
              <TableCell align="right"> {user.status}</TableCell>
              <TableCell align="right">{user.confirmed ? "Yes" : "No"}</TableCell>
              <TableCell align="right">${parseFloat(earningRef).toFixed(2)}</TableCell>
              <TableCell align="right"> <Box
          ref={anchorRef_1}
          id="composition-button"
          aria-controls={open_notification ? "composition-menu" : undefined}
          aria-expanded={open_notification ? "true" : undefined}
          aria-haspopup="true"
        >
          {user?.status == "Incomplete" && (
            <CheckCircleIcon
              titleAccess="Request for approval"
              sx={{ cursor: "pointer", fontSize: "1.4rem" }}
              onClick={handleToggleNotifcation}
            />
          )}{" "}
          <ImageIcon
            titleAccess={approvedImages?.length>0?"Collections":"Images"}
            sx={{ cursor: "pointer" }}
            onClick={handleImageOpen}
          />{" "}
        </Box></TableCell>
            </TableRow>
      {/* <div key={index} className={styles.listItem}>
        <div>
          <span className={styles.image}>
   
          </span>
          <span>{user.username}</span>
        </div>
        <div>
          {user.firstName} {user.lastName}
        </div>
        <div>{user.confirmed ? "Yes" : "No"}</div>
        <div>${photograph}</div>
       
      </div> */}
      <Popper
        open={open_notification}
        anchorEl={anchorRef_1.current}
        role={undefined}
        sx={{
          zIndex: "1200",
          width: {
            sm: "360px !important",
          },
        }}
        placement="bottom-start"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleCloseNotification}>
                <MenuList
                  autoFocusItem={open_notification}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown_1}
                >
                  <Box
                    sx={{
                      p: "0.5rem 1rem",
                    }}
                  >
                    <Typography textAlign={"center"}>
                      Are you sure want to request for approval ? Please make
                      sure your profile is complete and up to date
                    </Typography>

                    <Box
                      sx={{
                        pt: "1rem",
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                        "& button": {
                          color: "#fff !important",
                          lineHeight: "19.92px !important",
                          fontSize: "14px !important",
                          // height: "45px",
                          width: "30%",
                          padding: "0.5rem !important",
                          fontWeight: "400 !important",
                          fontFamily: "Exo 2 !important",
                          transition: "0.1s all !important",
                          borderRadius: "5px !important",
                        },
                      }}
                    >
                      <Button
                        sx={{
                          background: "green !important",
                          "&:hover": {
                            background: "green !important",
                          },
                        }}
                        onClick={() => sendForApproval()}
                      >
                        Yes
                      </Button>
                      <Button
                        sx={{
                          background: "#000 !important",
                          "&:hover": {
                            background: "#000 !important",
                          },
                        }}
                        onClick={handleCloseNotification}
                      >
                        No
                      </Button>
                    </Box>
                  </Box>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <UploadModal
        open={open}
        handleClose={handleClose}
        id={user?.id}
        fetchPhotographerUsers={fetchPhotographerUsers}
      />
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={imageOpen}
        onClose={handleImageClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {approvedImages?.length>0?"Collections":"Images"}{" "}
          <CloseIcon onClick={handleImageClose} sx={{ cursor: "pointer" }} />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {approvedImages?.length>0?
            <>
           { approvedImages?.map((imgData, index) => (
                <Grid item md={4} sm={6} xs={12} key={index}>
                  <ImageList
                    data={imgData?.image}
                    fetchPhotographerUsers={fetchPhotographerUsers}
                    user={user}
                  />
                </Grid>
              ))}
            </>:
             user?.images?.length > 0 &&
              user?.images?.map((imgData, index) => (
                <Grid item md={4} sm={6} xs={12} key={index}>
                  <ImageList
                    data={imgData}
                    fetchPhotographerUsers={fetchPhotographerUsers}
                    user={user}
                  />
                </Grid>
              ))}
            {user?.images?.length == 0 && (
              <Typography
                sx={{
                  p: "1.5rem",
                  textAlign: "center",
                  fontSize: "18px",
                  fontWeight: "600",
                }}
              >
                No images found.
              </Typography>
            )}
          </Grid>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center", pb: "2rem" }}>
          {user?.status == "Incomplete" && (
            <button onClick={handleClickOpen} className={styles.upload_}>
              Upload
            </button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};
export default PhotographerLIstRow;

const UploadModal = ({ open, handleClose, id, fetchPhotographerUsers }) => {
  const [result, setResult] = useState(null);
  const [loading, setLoading] = React.useState(false);
  const [files, setFiles] = React.useState([]);
  const [isCropped, setIsCropped] = useState(false);
  const [fileError, setFileError] = React.useState("");


  async function fetchBlobFromURL(url) {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      return blob;
    } catch (error) {
      console.error("Error fetching Blob from URL:", error);
      return null;
    }
  }
  const upload = async () => {
    // Check if files is null or undefined
    if (!files) {
      return setFileError("Please select an image");
    }

    try {
      setLoading(true);
      const imagesData = new FormData();

      // Fetch the Blob from the data URI
      const blob = await fetchBlobFromURL(files);
      if (!blob) {
        throw new Error("Failed to fetch Blob from URL");
      }

      // Append the Blob to FormData
      imagesData.append("files", blob, "image.png");
      imagesData.append("ref", "plugin::users-permissions.user");
      imagesData.append("field", "images");
      imagesData.append("refId", id);
      const jwt = localStorage.getItem("jwt");

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND}/upload`,
        imagesData,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        console.log();
        fetchPhotographerUsers();
        handleClose();
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={
          {
            // p:"14px 24px 14px"
          }
        }
      >
        Select photos{" "}
        <span style={{ fontSize: "15px", fontWeight: "600" }}>
          (Recommended Size: 780px X 1140px)
        </span>
      </DialogTitle>
      <DialogContent>
        <Dropzone
          isCropped={isCropped}
          setIsCropped={setIsCropped}
          error={fileError}
          label={false}
          onchange={setFiles}
          styles={styles}
          result={result}
          setResult={setResult}
        />
      </DialogContent>
      <DialogActions>
        <button
          onClick={handleClose}
          className={styles.cancel_}
          style={{ cursor: "pointer" }}
        >
          Cancel
        </button>
        <button
          disabled={loading || result === null}
          onClick={upload}
          className={styles.upload_}
        >
          {loading ? <Loader /> : "Upload"}
        </button>
      </DialogActions>
    </Dialog>
  );
};
