import React, { useEffect } from "react";
import styles from "./SalesList.module.css";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import PhotographerLIstRow from "./PhotographerLIstRow";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Close, GirlOutlined } from "@mui/icons-material";
import GirlForm from "./GirlForm";
import axios from "axios";

const PhotographerList = ({ flex , profile }) => {
  const [loading, setLoading] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  const [photographerUsers, setPhotographerUsers] = React.useState([]);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    window.location.reload()
    setOpen(false);
  };
  const fetchPhotographerUsers = async (next) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('jwt')}`,
      },
    };
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_BACKEND}/find-referred`,
        config,
      );
      setPhotographerUsers(data.data)
    } catch (error) {
      console.log("catch", error);
    }
  };
  useEffect(()=>{
    fetchPhotographerUsers()
  },[])
  return (
    <Box sx={{width:"100%"}}>
      <div className={styles.headerContainer}> 
        <p className={styles.header}>{`Photographer Girls (${
            photographerUsers?.length || 0
          })`}</p>
          <button onClick={handleClickOpen} style={{cursor:"pointer"}} className={styles.uploadButton}>
            Add Girl
          </button>
      </div>
      {/* <div className={styles.list}>
        <div className={styles.listItem}>
          <div>Username</div>
          <div>Name</div>
          <div>Confirmed</div>
          <div>Earning</div>
          <div>Actions</div>
        </div>
        {photographerUsers?.length > 0 ? (
          photographerUsers?.map((user, index) => {
            return <PhotographerLIstRow index={index} user={user} key={index} fetchPhotographerUsers={fetchPhotographerUsers}/>;
          })
        ) : (
          <Box sx={{ p: "3rem", textAlign: "center" }}>
            Photographer girls not found.
          </Box>
        )}
      </div> */}
      <Box>
      <TableContainer component={Paper} sx={{mt:"2rem","& th":{
        color:"rgb(0 0 0 / 49%)",
        fontWeight:"600"
      },
      "& tr":{
                borderBottom:"1px solid rgb(224 224 224)"
              },
              
              }} >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Username</TableCell>
            <TableCell align="right">Name</TableCell>
            <TableCell align="right">Status</TableCell>
            <TableCell align="right">Confirmed</TableCell>
            <TableCell align="right">Earning</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {photographerUsers?.length > 0 ? (
          photographerUsers?.map((user, index) => {
            return <PhotographerLIstRow index={index} user={user} key={index} fetchPhotographerUsers={fetchPhotographerUsers}/>;
          })
        ) : (
          <TableRow sx={{ p: "3rem", textAlign: "center" }}>
            <TableCell colSpan={5} style={{textAlign: "center"}}>
            No referred users  found.
            </TableCell>
          </TableRow>
        )}
       
        </TableBody>
      </Table>
    </TableContainer>
    </Box>
      <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Add Girl   <span style={{"float":"right","cursor" : "pointer"}}><Close  onClick={handleClose} className={styles.cancel} /></span>
          
         </DialogTitle>
      <DialogContent>
        {/* <Dropzone error={fileError} label={false} onChange={setFiles} /> */}
        <GirlForm profile={profile} />
      </DialogContent>
      {/* <DialogActions>
        <button onClick={handleClose} className={styles.cancel}>
          Cancel
        </button> 
        {/* <button disabled={loading} onClick={upload} className={styles.upload}>
          {loading ? <Loader /> : "Upload"}
        </button> 
      </DialogActions> */}
    </Dialog>

    </Box>
  );
};

export default PhotographerList;
