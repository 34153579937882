import React from "react";
import styles from "./Container.module.css";
import { Box } from "@mui/material";

const Container = ({ children, height,background }) => {
  return (
    <div className={styles.container}>
      <Box
        className={styles.content}
        sx={{
          height: height && height,
          background:background && background,
          "&::before": {
            height: height && height,
            background:background && background
          },
        }}
      >
        {children}
      </Box>
    </div>
  );
};

export default Container;
