import React from "react";
import success from "../../Assets/svg/success.svg";
import styles from "./Forms.module.css";

const Success = ({dark}) => {
  return (
    <div className={dark ? styles.darksuccess : styles.success} style={{padding:"5rem 1.5rem"}}>
      <div>
        <img src={success} alt="success" />
        <p>
    Thank you for showing your interest!<br />
    We will get back to you soon!<br />{" "}
    Please check spam if needed
</p>

      </div>
    </div>
  );
};

export default Success;
