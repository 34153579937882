import React from "react";
import Navbar from "../Component/Navbar/Navbar";
import Resetpassword from "../Component/Auth/Resetpassword";

const ResetPassword = () => {
  return (
    <>
      <Navbar />
      <Resetpassword />
    </>
  );
};

export default ResetPassword;
