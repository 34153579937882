import React from "react";
import Navbar from "../Component/Navbar/Navbar";
import DisclaimerContent from "../Component/Disclaimer/DisclaimerContent";
// import Tokenomics from "../Component/Tokenomics/Tokenomics";
import Footer from "../Component/Footer/Footer";

const Disclaimer = () => {
  return (
    <>
      <Navbar />
      <DisclaimerContent />
      <Footer />
    </>
  );
};

export default Disclaimer;
