import React from "react";
import styles from "./Button.module.css";

const Button = ({ type, children, onClick, disabled, width }) => {
  return (
    <button
      style={{ width: width }}
      type={type}
      onClick={onClick && onClick}
      disabled={disabled && disabled}
      className={disabled ? styles.buttonDisabled : styles.buttonEnabled}
    >
      {children}
    </button>
  );
};

export default Button;
