import React from "react";
import ReactDOM from "react-dom/client";

import "./index.css";
import "swiper/css/bundle";
import "swiper/css/navigation";

import App from "./App";
import "@rainbow-me/rainbowkit/styles.css";
import {
  WagmiConfig,
  createClient,
  defaultChains,
  configureChains,
  chain,
  useToken,
} from "wagmi";
import { publicProvider } from "wagmi/providers/public";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
import {
  getDefaultWallets,
  RainbowKitProvider,
  AvatarComponent,
} from "@rainbow-me/rainbowkit";
import { Buffer } from "buffer";
import ContractContextProvider from "./Context/ContractContext";
const zksync = {
  id: 324,
  name: "ZkSync Mainnet",
  network: "ZkSync Era Mainnet",
  nativeCurrency: {
    decimals: 18,
    name: "ETH",
    symbol: "ETH",
  },
  rpcUrls: {
    default: "https://mainnet.era.zksync.io",
  },
  // iconUrl: zkicon,
  blockExplorers: {
    default: { name: "zkSync Era Explorer", url: "https://explorer.zksync.io" },
  },
  testnet: false,
};
const Mumbai = {
  id: 80001,
  name: "Mumbai",
  network: "Mumbai Testnet",
  nativeCurrency: {
    decimals: 18,
    name: "MATIC",
    symbol: "MATIC",
  },
  rpcUrls: {
    default: "https://polygon-mumbai-bor.publicnode.com	",
    // default: 'https://rpc-mumbai.maticvigil.com',
  },
  blockExplorers: {
    default: { name: "Polygonscan", url: "https://mumbai.polygonscan.com/" },
  },
  testnet: true,
};

const bsc = {
  id: 56,
  name: "BSC Mainnet",
  network: "Binance Smart Chain",
  nativeCurrency: {
    decimals: 18,
    name: "BNB",
    symbol: "BNB",
  },
  rpcUrls: {
    default: "https://bsc-dataseed.binance.org/",
  },
  blockExplorers: {
    default: { name: "BscScan", url: "https://bscscan.com" },
  },
  testnet: false,
};
const bscTest = {
  id: 97,
  name: "BSC Testnet",
  network: "Binance Smart Chain Testnet",
  nativeCurrency: {
    decimals: 18,
    name: "tBNB",
    symbol: "tBNB",
  },
  iconUrl: "",
  rpcUrls: {
    default: "https://data-seed-prebsc-1-s2.binance.org:8545",
  },
  blockExplorers: {
    default: { name: "BscScan TestNet", url: "https://testnet.bscscan.com" },
  },
  testnet: true,
};
const CustomAvatar = ({ address, ensImage, size }) => {
  return ensImage ? (
    <img
      src={ensImage}
      width={size}
      height={size}
      style={{ borderRadius: 999 }}
      alt=""
    />
  ) : (
    ""
    //   <img style={{ boxShadow: '0px 0px 8px -2px #000', padding: '10px', }} src={zethan} alt='' />
  );
};

const myCustomTheme = {
  blurs: {
    modalOverlay: "...",
  },
  colors: {
    accentColor: "#0066FF",
    accentColorForeground: "#060a15",
    actionButtonBorder: "...",
    actionButtonBorderMobile: "...",
    actionButtonSecondaryBackground: "...",
    closeButton: "#060a15",
    closeButtonBackground: "...",
    connectButtonBackground: "...",
    connectButtonBackgroundError: "...",
    connectButtonInnerBackground: "...",
    connectButtonText: "...",
    connectButtonTextError: "...",
    connectionIndicator: "...",
    downloadBottomCardBackground: "...",
    downloadTopCardBackground: "...",
    error: "...",
    generalBorder: "...",
    generalBorderDim: "...",
    menuItemBackground: "...",
    modalBackdrop: "#000000c9",
    modalBackground:
      "linear-gradient(92.97deg,#3d2b0a 3.8%,#fcebbf 34.39%,#eec96c 57.07%,#fed87f 99.59%)",
    modalBorder: "#b09869",
    modalText: "#060a15",
    modalTextDim: "...",
    modalTextSecondary: "#060a15 ",
    profileAction: "...",
    profileActionHover: "...",
    profileForeground: "...",
    selectedOptionBorder: "...",
    standby: "...",
  },
  fonts: {
    body: "poppins",
  },
  radii: {
    actionButton: "...",
    connectButton: "...",
    menuButton: "...",
    modal: "16px",
    modalMobile: "16px",
  },
  shadows: {
    connectButton: "...",
    dialog: "0px 0px 12px -2px #000",
    profileDetailsAction: "...",
    selectedOption: "...",
    selectedWallet: "...",
    walletLogo: "...",
  },
};

const { chains, provider, webSocketProvider } = configureChains(
  [zksync, Mumbai],
  [
    publicProvider(),
    jsonRpcProvider({
      priority: 0,
      rpc: (chain) => {
        if (chain?.id === zksync?.id) {
          return { http: chain?.rpcUrls?.default };
        } else {
          return null;
        }
      },
    }),
  ],
);
const { connectors } = getDefaultWallets({
  appName: "My dreamgirl App",
  chains,
});
const client = createClient({
  autoConnect: true,
  connectors,
  provider,
  webSocketProvider,
});
// polyfill Buffer for client
if (!window.Buffer) {
  window.Buffer = Buffer;
}
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <WagmiConfig client={client}>
      <RainbowKitProvider
        avatar={CustomAvatar}
        theme={myCustomTheme}
        chains={chains}
        modalSize="compact"
        coolMode
      >
          <ContractContextProvider>
      <App />
    </ContractContextProvider>
      </RainbowKitProvider>
    </WagmiConfig>
  </React.StrictMode>,
);
