import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Input from "../Input/Input";
import Button from "../Button/Button";
import styles from "./Auth.module.css";
import axios from "axios";
import { TOKEN, USERTYPE, USER } from "../../Helper/Constants";
import { useSearchParams } from "react-router-dom";
import Loader from "../Loader/Loader";

const Resetpassword = () => {
  const [searchParams] = useSearchParams();

  const formik = useFormik({
    initialValues: {
      password: "",
      passwordConfirmation: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .required("Required"),

      // passwordConfirmation must match password
      passwordConfirmation: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .min(8, "Password must be at least 8 characters" )
        .required("Required"),
    }),
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      const resetPassword = `${process.env.REACT_APP_BACKEND}/auth/reset-password`;

      //call api to ChangePassword
      try {
        const response = await axios.post(resetPassword, {
          password: values.password,
          passwordConfirmation: values.passwordConfirmation,
          code: searchParams?.get("code"),
        });

        console.log("response", response.status);
        // check if response is ok
        if (response.status === 200) {
          // set token in local storage
          // localStorage.setItem(TOKEN, response.data.jwt);
          // // set usertype in local storage
          // localStorage.setItem(USERTYPE, response.data.user.userType);
          // // set user in local storage
          // localStorage.setItem(USER, JSON.stringify(response.data.user));

          localStorage.setItem('jwt', response.data.jwt);
          localStorage.setItem('id', response.data.user.id);

          // redirect to dashboard
          window.location.href = "/dashboard";
        }
      } catch (e) {
        setSubmitting(false);
        console.log("e", e.code);
        if (e.code === "ERR_NETWORK") {
          alert("Network Error");
        }
        if (e.code === "ERR_BAD_REQUEST") {
          setErrors({ passwordConfirmation: "Please try again!" });
        }
      }
    },
  });

  useEffect(() => {
    localStorage.clear();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.formContainer}>
        <h1>Please enter following details to change your password!</h1>
        <form onSubmit={formik.handleSubmit}>
          <Input
            id="password"
            name="password"
            type="password"
            label="Password*"
            placeholder="Enter your password"
            error={formik.errors.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password.trim()}
            touched={formik.touched.password ? 1 : 0}
            width={"372px"}
            eye={true}
          />

          <Input
            id="passwordConfirmation"
            name="passwordConfirmation"
            type="password"
            label="Confirm Password*"
            placeholder="Enter your password"
            error={formik.errors.passwordConfirmation}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.passwordConfirmation.trim()}
            touched={formik.touched.passwordConfirmation ? 1 : 0}
            width={"372px"}
            eye={true}
          />

          <div className={styles.buttonContainer}>
            <Button
              width={"372px"}
              disabled={formik.isSubmitting}
              type="submit"
            >
              {formik.isSubmitting ? <Loader /> : "Change Password"}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Resetpassword;
