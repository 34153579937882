import React, { memo } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Input from "../../Input/Input";
import Button from "../../Button/Button";
import styles from "./Forms.module.css";
import axios from "axios";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { TOKEN } from "../../../Helper/Constants";
import { Box, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

const EpaymentEmail = ({ profile,fetchProfile,setShowSnackbar}) => {

  const [errorText, setErrorText] = React.useState(null);
  const formik = useFormik({
    initialValues: {
        ePaymentEmail: profile?.ePaymentEmail,
    },
    validationSchema: Yup.object({
        ePaymentEmail: Yup.string().trim(
        "ePaymentEmail should not contain spaces").email("Invalid email address").required("Required"),
    }),
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      const updateProfile = `${process.env.REACT_APP_BACKEND}/users/${profile?.id}`;
      const jwt = localStorage.getItem('jwt') ; 
    
      const config = {
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem(TOKEN),
          Authorization: `Bearer ${jwt}`,

        },
      };

      try {
        const res = await axios.put(updateProfile, {
            username: profile?.username,
            firstName: profile?.firstName,
            lastName: profile?.lastName,
            email: profile?.email,
            phoneNumber: profile?.phoneNumber,
            instagram_handle: profile?.instagram_handle,
            bankName: profile?.bankName,
            bankAddress: profile?.bankAddress,
            accountName: profile?.accountName,
            accountNumber: profile?.accountNumber || 0,
            swiftCode: profile?.swiftCode,
            branchNumber: profile?.branchNumber,
            paypalEmail: profile?.paypalEmail,
            cryptoWallet: profile?.cryptoWallet,
            ePaymentEmail:values.ePaymentEmail
        }, config);

        if (res.status === 200) {
          setSubmitting(false);
          fetchProfile()
          setShowSnackbar(true)
          setTimeout(() => {
            setShowSnackbar(false)
          }, 2000);
        }
      } catch (err) {
        console.log(err);
        setErrorText(err.response.data.error.message)

      }
    },
  });

  useEffect(() => {
    if (!isEmpty(profile)) {

      formik.setValues({
        ePaymentEmail: profile?.ePaymentEmail || "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  return (
    <>
      {/* <div className={styles.container}>
      <div className={styles.childContainer}> */}
      <div className={styles.formContainer} style={{ margin: "0 auto" }}>
        <form onSubmit={formik.handleSubmit}>
          <div className={styles.inputs}>
          <p style={{fontSize:"20px",fontWeight:"bold",paddingBottom:"0.5rem"}}>eTransfer Details</p>
            <Input
              id="ePaymentEmail"
              name="ePaymentEmail"
              type="email"
              label="eTransfer Email"
              placeholder="eTransfer Email"
              error={formik.errors.ePaymentEmail}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.ePaymentEmail}
              touched={formik.touched.ePaymentEmail ? 1 : 0}
            />
          </div>
          {
                errorText &&
              <Box sx={{ p: "1rem 1.5rem", display: "flex" }}>
                  <Typography sx={{ color: "red", fontSize: "1rem" }}>
                    {errorText}  
                    <Typography
                      component={"span"}
                      title="For More Info" 
                    >
                      <InfoIcon
                        sx={{
                          fontSize: "1.2rem",
                          cursor: "pointer",
                          verticalAlign: "sub",
                        }}
                      />
                    </Typography>
                  </Typography>
                </Box>
              }
              <div className={styles.buttonContainer} style={{justifyContent:"flex-end"}}>
            <Button disabled={formik.isSubmitting} type="submit">
              {formik.isSubmitting ? "Submitting..." : "Save Changes"}
            </Button>
          </div>
        </form>
      </div>
      {/* </div>
    </div> */}
    </>
  );
};

export default memo(EpaymentEmail);

const isEmpty = (obj) => {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};
