import React from "react";
import styles from "./Partner.module.css";
import trust from "../../Assets/png/trust.png";
import { Link } from "react-router-dom";

const Partner = () => {
  return (
    <div className={styles.Testimonial}>
      <div className={styles.display}>
        <div>
          <h3 className={styles.show}>
            Expose Your Brand And Products To Our Exploding Global Community
          </h3>
          <Link className={styles.link} to="/business">
            <button className={styles.apply}>Partnership Application</button>
          </Link>
        </div>
        <div>
          <img src={trust} alt="coins" />
        </div>
      </div>
    </div>
  );
};

export default Partner;
