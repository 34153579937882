import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Input from "../Input/Input";
import Button from "../Button/Button";
import styles from "./Auth.module.css";
import axios from "axios";
import { TOKEN, USERTYPE, USER } from "../../Helper/Constants";
import { Link, Navigate } from "react-router-dom";
import Loader from "../Loader/Loader";
import { useAccount } from "wagmi";
import { ConnectWalletBtn } from "../Wallet/ConnectWallet";
import { Typography } from "@mui/material";

const Login = () => {
  const { isConnected, address } = useAccount();
  const [status, setStatus] = React.useState("idle");
  const [errorText, setErrorText] = React.useState(null);

  console.log(address);
  const formik = useFormik({
    initialValues: {
      identifier: "",
      password: "",
    },
    validationSchema: Yup.object({
      identifier: Yup.string().required("Required"),

      password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .required("Required"),
    }),
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      const login = `${process.env.REACT_APP_BACKEND}/auth/local`;

      //call api to login
      try {
        const res = await axios.post(login, {
          identifier: values.identifier,
          password: values.password,
        });
 
        if (res.status === 200) {
          setStatus("success");
          window.location.href = "/dashboard";
          localStorage.setItem('jwt', res.data.jwt);
          localStorage.setItem('id', res.data.user.id);
        }
        else  {
          // setErrorArray(res.data.error.message);
        setErrorText(res.data.error.message)
  
        }
      } catch (e) {
        console.log(e);
        setSubmitting(false);
        setErrorText(e.response.data.error.message)

      }
    },
  });

  if (typeof window !== "undefined") {
    if (localStorage.getItem(TOKEN)) {
      return <Navigate to="/dashboard" replace />;
    }
  }
  // const login = async () => {
  //   const loginRoute = `${process.env.REACT_APP_BACKEND}/auth/local/login`;

  //   try {
  //     const res = await axios.post(loginRoute, {
  //       wallet: process.env.REACT_APP_ADDRESS,
  //     });

  //     if (res.status === 200) {
  //       setStatus("success");
  //       window.location.href = "/dashboard";
  //       localStorage.setItem('jwt', res.data.jwt);
  //       localStorage.setItem('id', res.data.user.id);
  //     }
  //     else  {
  //       // setErrorArray(res.data.error.message);
  //     setErrorText(res.data.error.message)

  //     }
  //   } catch (err) {
  //     console.log(err);
  //     setErrorText(err.response.data.error.message)

  //   }
  // };

  return (
    <div className={styles.container}>
      <div className={styles.formContainer}>
        <h1>Welcome to Dream Girls, please sign in to continue</h1>
        <form onSubmit={formik.handleSubmit}>
          <Input
            id="identifier"
            name="identifier"
            type="text"
            label="Enter your email or username*"
            placeholder="email/username"
            error={formik.errors.identifier}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.identifier.trim()}
            touched={formik.touched.identifier ? 1 : 0}
            width={"372px"}
          />

          <Input
            id="password"
            name="password"
            type="password"
            label="Password*"
            placeholder="Enter your password"
            error={formik.errors.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password.trim()}
            touched={formik.touched.password ? 1 : 0}
            width={"372px"}
            eye={true}
          />

          <Typography style={{"color" : "red"}} >{errorText}</Typography>

          <div className={styles.buttonContainer}>
            <Button
              width={"372px"}
              disabled={formik.isSubmitting}
              type="submit">
              {formik.isSubmitting ? <Loader /> : "Login"}
            </Button>
            <div className={styles.forgotPassword}>
              <Link to="/forgot-password">Forgot Password?</Link>
            </div>
          </div>
        </form>

        {/* {isConnected?   */}
        {/* <div className={styles.buttonContainer}>
          <Button
            width={"372px"}
            onClick={login}
            // disabled={formik.isSubmitting}
            type="submit"
          >
            Login
           {formik.isSubmitting ? <Loader /> : "Login"}  
          </Button>
          <div className={styles.forgotPassword}>
              <Link to="/forgot-password">Forgot Password?</Link>
            </div>
        </div> */}
        {/* :
          <div style={{textAlign:"center"}}><ConnectWalletBtn/></div>} */}
      </div>
    </div>
  );
};

export default Login;
