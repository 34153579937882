import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Input from "../Input/Input";
import Button from "../Button/Button";
import styles from "./Forms.module.css";
import axios from "axios";
import PartnerSucess from "./PartnerSucess";
import Loader from "../Loader/Loader";

const BusinessForm = () => {
  const [status, setStatus] = React.useState("idle");
  const formik = useFormik({
    initialValues: {
      companyName: "",
      companyWebsite: "",
      phone: "",
      facebookHandle: "",
      industry: "",
      description: "",
      email: "",
    },
    validationSchema: Yup.object({
      companyName: Yup.string().required("Required"),
      companyWebsite: Yup.string().required("Required"),
      phone: Yup.string().required("Required"),
      facebookHandle: Yup.string().required("Required"),
      industry: Yup.string().required("Required"),
      description: Yup.string(),
      email: Yup.string().email("Invalid email address").required("Required"),
    }),
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      const partner = `${process.env.REACT_APP_BACKEND}/business-partners`;
      try {
        const res = await axios.post(partner, { data: values });
        if (res.status === 200) {
          setStatus("success");
          resetForm();
        }
      } catch (err) {
        console.log(err);
      }
    },
  });

  return (
    <div className={styles.container}>
      <div className={styles.childContainer}>
        <div className={styles.heroText}>
          <h1>Sign up to become our business partner</h1>
          <p>
            Thanks for your interest in considering a partnership with Dream
            Girls NFT and your company! We know this is going to be a mutually
            beneficial relationship. We're going to keep it really simple.
          </p>
          <br />
          <p>
            1. There's no fee to become a partner company or brand with Dream
            Girls NFT.
          </p>
          <p>
            2. We will give access to our global community and market your
            products and services.
          </p>
          <p>
            3. All we ask is that you offer some sort of special deal, discount
            or benefit to our NFT holders and/or Dream Girls. This is at your
            discretion and needs to make sense for you.
          </p>
          <p>
            4. As the relationship grows we can explore additional ways to help
            you grow your business.
          </p>
          <br />

          <p>
            If that sounds good to you please fill out a Partnership form and a
            <br />
            representative will be in contact right away!
          </p>
        </div>
      </div>
      <div className={styles.childContainer}>
        <div className={styles.formContainer}>
          {status === "idle" ? (
            <form onSubmit={formik.handleSubmit}>
              <div className={styles.inputs}>
                <Input
                  id="name"
                  name="companyName"
                  type="text"
                  label="Company's name*"
                  placeholder="Doe Inc."
                  error={formik.errors.companyName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.companyName}
                  touched={formik.touched.companyName ? 1 : 0}
                />
                <Input
                  id="website"
                  name="companyWebsite"
                  type="text"
                  label="Company's website*"
                  placeholder="example.com"
                  error={formik.errors.companyWebsite}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.companyWebsite}
                  touched={formik.touched.companyWebsite ? 1 : 0}
                />
                <Input
                  id="phone"
                  name="phone"
                  type="tel"
                  label="Phone number*"
                  placeholder="Enter your phone"
                  error={formik.errors.phone}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phone.trim()}
                  touched={formik.touched.phone ? 1 : 0}
                />
                   <Input
              id="email"
              name="email"
              type="email"
              label="Email*"
              placeholder="Email"
              error={formik.errors.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email.trim()}
              touched={formik.touched.email ? 1 : 0}
            />
                <Input
                  id="facebookHandle"
                  name="facebookHandle"
                  type="text"
                  label="Facebook Handle*"
                  placeholder="Your facebook handle"
                  error={formik.errors.facebookHandle}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.facebookHandle}
                  touched={formik.touched.facebookHandle ? 1 : 0}
                />
                <Input
                  id="industry"
                  name="industry"
                  type="text"
                  label="Industry*"
                  placeholder="Industry"
                  error={formik.errors.industry}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.industry}
                  touched={formik.touched.industry ? 1 : 0}
                />
                <Input
                  id="description"
                  name="description"
                  type="text"
                  label="Anything you'd like to tell us?"
                  placeholder="Type here..."
                  error={formik.errors.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.description}
                  touched={formik.touched.description ? 1 : 0}
                />
              </div>
              <div className={styles.buttonContainer}>
                <Button disabled={formik.isSubmitting} type="submit">
                  {formik.isSubmitting ? <Loader /> : "Submit"}
                </Button>
              </div>
            </form>
          ) : (
            <PartnerSucess />
          )}
        </div>
      </div>
    </div>
  );
};

export default BusinessForm;
