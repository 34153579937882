import React, { useContext, useEffect } from "react";
import styles from "./Navbar.module.css";
import DreamGirls from "../../../Assets/png/DreamGirls.png";
import User from "../../../Assets/svg/user.svg";
import { Link, useNavigate } from "react-router-dom";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { Box, Button, Drawer, Typography } from "@mui/material";
import { JWT, TOKEN } from "../../../Helper/Constants";
import axios from "axios";
import { ContractContext } from "../../../Context/ContractContext";

const Navbar = ({ profile, name }) => {
  const [open, setOpen] = React.useState(false);
  const [openMenu, setOpenMenu] = React.useState(false);
  const [open_notification, setOpen_notification] = React.useState(false);
  const [open_kyc, setOpen_kyc] = React.useState(false);
  const [open_notification_1, setOpen_notification_1] = React.useState(false);
  const navigate = useNavigate();
  const logout = () => {
    localStorage.clear();
    navigate("/signin");
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleToggleNotifcation = () => {
    setOpen_notification((prevOpen) => !prevOpen);
  };
  const handleTogglekyc = () => {
    setOpen_kyc((prevOpen) => !prevOpen);
  };
  const handleToggleNotifcation_1 = () => {
    setOpen_notification_1((prevOpen) => !prevOpen);
  };
  useEffect(() => {
    if (profile?.status === "Incomplete") {
      setOpen_notification(true);
    }
  }, [profile?.status]);
  useEffect(() => {
    if (profile?.kycStatus === "Pending") {
      setOpen_kyc(true);
    }
  }, [profile?.kycStatus]);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
    setOpen_notification(false);
  };
  const handleCloseNotification = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen_notification(false);
  };
  const handleCloseKyc = (event) => {
    if (anchorRef_kyc.current && anchorRef_kyc.current.contains(event.target)) {
      return;
    }

    setOpen_kyc(false);
  };

  const handleCloseNotification_1 = (event) => {
    setOpen_notification_1(false);
    setValue(2);
    navigate("/profile");
  };
  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }
  function handleListKeyDown_1(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen_notification(false);
    } else if (event.key === "Escape") {
      setOpen_notification(false);
    }
  }
  function handleListKeyDown_kyc(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen_kyc(false);
    } else if (event.key === "Escape") {
      setOpen_kyc(false);
    }
  }
  function handleListKeyDown_2(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen_notification_1(false);
    } else if (event.key === "Escape") {
      setOpen_notification_1(false);
    }
  }
  const anchorRef = React.useRef(null);
  const anchorRef_kyc = React.useRef(null);
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  const anchorRef_1 = React.useRef(null);
  const anchorRef_2 = React.useRef(null);
  const prevOpen_1 = React.useRef(open_notification);
  const prevOpen_2 = React.useRef(open_notification_1);
  const sendForApproval = async () => {
    const config = {
      headers: {
        // authorization: localStorage.getItem(JWT),
        Authorization: `Bearer ${localStorage.getItem(JWT)}`,
      },
    };
    try {
      const data = await axios.put(
        `${process.env.REACT_APP_BACKEND}/users/request-approval`,
        {},
        config
      );
      console.log(data);
      if (data.data.status == "Pending") {
        window.location.reload();
      }
      // console.log(data);
      // setEarningData(data.data);
    } catch (error) {
      console.log("catch", error);
    }
  };
  React.useEffect(() => {
    if (prevOpen_1.current === true && open_notification === false) {
      anchorRef_1.current.focus();
    }

    prevOpen_1.current = open_notification;
  }, [open_notification]);
  React.useEffect(() => {
    if (prevOpen_2.current === true && open_notification_1 === false) {
      anchorRef_2.current.focus();
    }

    prevOpen_2.current = open_notification_1;
  }, [open_notification_1]);
  const { setValue } = useContext(ContractContext);
  return (
    <>
      {profile?.firstName && profile?.status != "Approved" && (
        <Box
          width={"100%"}
          // height={"50px"}
          style={{
            background: profile?.infoApprove ? "Yellow" : "red",
            position: "relative",
          }}
        >
          <Typography
            textAlign={"center"}
            color={profile?.infoApprove ? "#000" : "white"}
            p={"10px"}
            style={{ verticalAlign: "center" }}
          >
            {profile?.infoApprove
              ? "Your account has been approved."
              : "Your account is limited as it is not approved yet."}
            {profile?.status === "Pending" ? (
              <Button
                style={{
                  color: profile?.infoApprove ? "#000" : "yellow",
                  fontWeight: profile?.infoApprove && "bold",
                }}
              >
                {profile?.infoApprove
                  ? "Collection under production"
                  : "Account under review"}
                .
              </Button>
            ) : (
              profile?.status === "Incomplete" && (
                <Button
                  ref={anchorRef_1}
                  id="composition-button"
                  aria-controls={
                    open_notification ? "composition-menu" : undefined
                  }
                  aria-expanded={open_notification ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleToggleNotifcation}
                  // color="white"
                  style={{
                    color: "yellow",
                  }}
                >
                  Request Approval
                </Button>
              )
            )}
            <Popper
              open={open_notification}
              anchorEl={anchorRef_1.current}
              role={undefined}
              sx={{
                zIndex: "1200",
                width: {
                  sm: "360px !important",
                },
              }}
              placement="bottom-start"
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom-start" ? "left top" : "left bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleCloseNotification}>
                      <MenuList
                        autoFocusItem={open_notification}
                        id="composition-menu"
                        aria-labelledby="composition-button"
                        onKeyDown={handleListKeyDown_1}
                      >
                        {(profile?.user_type == "Girl" &&
                        profile?.kycStatus=="Pending") ?
                        <Box
                            sx={{
                              p: "0.5rem 1rem",
                            }}
                          >
                            <Typography>
                        Please {" "}
                        <Link
                          className={styles.link}
                          style={{ color: "blue" }}
                          to="/kyc"
                        >
                          Get Verified 
                        </Link>{" "}
                        with KYC as it is required for Approval
                        </Typography>
                        </Box>  :
                        (!profile?.images?.length ||
                          profile?.images?.length < 3) ? (
                          <Box
                            sx={{
                              p: "0.5rem 1rem",
                            }}
                          >
                            <Typography>
                              Please upload at least three photo as it is
                              required for approval.
                            </Typography>
                          </Box>
                        ) : (
                          <Box
                            sx={{
                              p: "0.5rem 1rem",
                            }}
                          >
                            <Typography>
                              Are you sure want to request for approval ? Please
                              make sure your profile is complete and up to date
                            </Typography>

                            <Box
                              sx={{
                                pt: "1rem",
                                display: "flex",
                                justifyContent: "space-around",
                                alignItems: "center",
                                "& button": {
                                  color: "#fff !important",
                                  lineHeight: "19.92px !important",
                                  fontSize: "14px !important",
                                  // height: "45px",
                                  width: "30%",
                                  padding: "0.5rem !important",
                                  fontWeight: "400 !important",
                                  fontFamily: "Exo 2 !important",
                                  transition: "0.1s all !important",
                                  borderRadius: "5px !important",
                                },
                              }}
                            >
                              <Button
                                sx={{
                                  background: "green !important",
                                  "&:hover": {
                                    background: "green !important",
                                  },
                                }}
                                ref={anchorRef_2}
                                id="composition-button"
                                aria-controls={
                                  open_notification_1
                                    ? "composition-menu"
                                    : undefined
                                }
                                aria-expanded={
                                  open_notification_1 ? "true" : undefined
                                }
                                aria-haspopup="true"
                                // onClick={handleToggleNotifcation_1}
                                onClick={
                                  profile?.ethnicity === null ||
                                  profile?.eyeColor === null ||
                                  profile?.hairColor === null ||
                                  profile?.bodyType === null
                                    ? handleToggleNotifcation_1
                                    : () => sendForApproval()
                                }
                              >
                                Yes
                              </Button>
                              <Button
                                sx={{
                                  background: "#000 !important",
                                  "&:hover": {
                                    background: "#000 !important",
                                  },
                                }}
                                onClick={handleCloseNotification}
                              >
                                No
                              </Button>
                            </Box>
                          </Box>
                        )}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
            <Popper
              open={open_notification_1}
              anchorEl={anchorRef_1.current}
              role={undefined}
              sx={{
                zIndex: "1200",
                width: {
                  sm: "360px !important",
                },
              }}
              placement="bottom-start"
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom-start" ? "left top" : "left bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleCloseNotification_1}>
                      <MenuList
                        autoFocusItem={open_notification_1}
                        id="composition-menu"
                        aria-labelledby="composition-button"
                        onKeyDown={handleListKeyDown_2}
                      >
                        <Box
                          sx={{
                            p: "0.5rem 1rem",
                          }}
                        >
                          <Typography>
                            your profile information is not complete.
                            <br />
                            Are you sure want to proceed without completing
                            profile information ?
                          </Typography>

                          <Box
                            sx={{
                              pt: "1rem",
                              display: "flex",
                              justifyContent: "space-around",
                              alignItems: "center",
                              "& button": {
                                color: "#fff !important",
                                lineHeight: "19.92px !important",
                                fontSize: "14px !important",
                                // height: "45px",
                                width: "30%",
                                padding: "0.5rem !important",
                                fontWeight: "400 !important",
                                fontFamily: "Exo 2 !important",
                                transition: "0.1s all !important",
                                borderRadius: "5px !important",
                              },
                            }}
                          >
                            <Button
                              sx={{
                                background: "green !important",
                                "&:hover": {
                                  background: "green !important",
                                },
                              }}
                              onClick={() => sendForApproval()}
                            >
                              Yes
                            </Button>
                            <Button
                              sx={{
                                background: "#000 !important",
                                "&:hover": {
                                  background: "#000 !important",
                                },
                              }}
                              onClick={handleCloseNotification_1}
                            >
                              No
                            </Button>
                          </Box>
                        </Box>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Typography>
        </Box>
      )}
      {(profile?.user_type == "Girl"  && (profile?.kycStatus == "Pending" ||
        profile?.kycStatus == "KYC Under Review")) && (
        <Box
          width={"100%"}
          // height={"50px"}
          style={{ background: "Yellow", position: "relative" }}
        >
          <Typography
            textAlign={"center"}
            color={"#000"}
            p={"10px"}
            sx={{ display:"flex",justifyContent:"center",flexWrap:"wrap",alignItems:"center"}}
           
          >
            {/* {profile?.kycStatus == "Pending" && (
              <Button
                onClick={handleTogglekyc}
                // color="white"
                style={{
                  color: "#000",
                  fontSize:"15px"
                }}
                ref={anchorRef_kyc}
            id="composition-button_kyc"
            aria-controls={open_kyc ? "composition-menu_kyc" : undefined}
            aria-expanded={open_kyc ? "true" : undefined}
            aria-haspopup="true"
              >
                Do KYC.
              </Button>
            )} */}
            {profile?.kycStatus == "KYC Under Review"
              ? "Your KYC is under review."
              : <>KYC is mandatory for account approval and collection creation. <a href='/kyc' style={{color: "blue"}} > Get Verified.</a></> }
          
            {/* <Popper
              open={open_kyc}
              anchorEl={anchorRef_kyc.current}
              role={undefined}
              sx={{
                zIndex: "1200",
                width: {
                  sm: "360px !important",
                },
              }}
              placement="bottom-end"
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom-end" ? "right top" : "right bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleCloseKyc}>
                      <MenuList
                        autoFocusItem={open_kyc}
                        id="composition-menu_kyc"
                        aria-labelledby="composition-button_kyc"
                        onKeyDown={handleListKeyDown_kyc}
                      >
                        Please proceed with{" "}
                        <Link
                          className={styles.link}
                          style={{ color: "blue" }}
                          to="/kyc"
                        >
                          KYC
                        </Link>{" "}
                        as it is required for Approval
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper> */}
          </Typography>
        </Box>
      )}
      <div className={styles.navbar}>
        <div className={styles.navContainer}>
          <div>
            <Link to="/">
              <img className={styles.logo} src={DreamGirls} alt="Dream Girls" />
            </Link>
          </div>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1.5rem",
              "@media (max-width:1180px)": {
                display: "none",
              },
              "& a": {
                textDecoration: "none",
              },
            }}
          >
            <Link className={styles.link} to="/dashboard">
              Dashboard
            </Link>

            <Link className={styles.link} to="/earnings">
              Earnings
            </Link>
            <Link className={styles.link} to="/withdrawl-list">
              Withdrawals
            </Link>
            {profile?.user_type == "Girl" && (
              <Link className={styles.link} to="/kyc">
              KYC - Get Verified
              </Link>
            )}
          </Box>
          <Box
            sx={{
              "@media (max-width:1180px)": {
                display: "none",
              },
            }}
          >
            <Link
              className={styles.profileDetails}
              ref={anchorRef}
              id="composition-button"
              aria-controls={open ? "composition-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
            >
              <span className={styles.userIcon}>
                <img src={User} alt="User Icon" />
              </span>
              <span className={styles.name}>{"Your Profile" || "Bella M"}</span>
            </Link>
            <Box
              sx={{
                "& .MuiPopper-root": {
                  zIndex: "1200",
                  borderRadius: "12px",
                  // top: "13px !important",
                },
                "& ul": {
                  background: "#1d1e1f",
                  border: "1px solid rgba(255, 255, 255, 0.5)",
                  color: "rgba(255, 255, 255, 0.7)",
                  fontWeight: "500",
                },
                "& a": {
                  color: "rgba(255, 255, 255, 0.7)",
                  fontWeight: "500",
                },
              }}
            >
              <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === "bottom-start"
                          ? "left top"
                          : "left bottom",
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList
                          autoFocusItem={open}
                          id="composition-menu"
                          aria-labelledby="composition-button"
                          onKeyDown={handleListKeyDown}
                        >
                          {/* <MenuItem component={Link} to="/dashboard">
                            Dashboard
                          </MenuItem> */}
                          <MenuItem component={Link} to="/profile">
                            My Profile
                          </MenuItem>
                          <MenuItem onClick={() => logout()}>Logout</MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </Box>
          </Box>
          <Box
            sx={{
              "@media (min-width:1180px)": {
                display: "none",
              },
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              className={styles.menuMobile}
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <div onClick={() => setOpenMenu(true)} className={styles.burger}>
                <span></span>
                <span></span>
              </div>
              <Drawer
                anchor={"right"}
                open={openMenu}
                onClose={() => setOpenMenu((open) => !open)}
              >
                <div className={styles.drawer}>
                  <ul>
                    <li>
                      {/* <span className={styles.name}>{name || "Bella M"}</span> */}
                      <Link to="/">
                        <img
                          className={styles.logo}
                          src={DreamGirls}
                          alt="Dream Girls"
                        />
                      </Link>
                    </li>
                    <li>
                      <Link className={styles.link} to="/dashboard">
                        Dashboard
                      </Link>
                    </li>
                    <li>
                      <Link className={styles.link} to="/profile">
                        Profile
                      </Link>
                    </li>
                    <li>
                      <Link className={styles.link} to="/withdrawl-list">
                        Withdrawals
                      </Link>
                    </li>
                    <li>
                      <Link className={styles.link} to="/kyc">
                      KYC - Get Verified
                      </Link>
                    </li>

                    <li>
                      <Link className={styles.link} to="/earnings">
                        Earnings
                      </Link>
                    </li>
                    <li>
                      <Link className={styles.link} onClick={() => logout()}>
                        Logout
                      </Link>
                    </li>
                  </ul>
                </div>
              </Drawer>
            </div>
          </Box>
        </div>
      </div>
    </>
  );
};

export default Navbar;
