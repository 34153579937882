import React from "react";
import Navbar from "../Component/Navbar/Navbar";
import Forgotpassword from "../Component/Auth/Forgotpassword";

const ForgotPassword = () => {
  return (
    <>
      <Navbar />
      <Forgotpassword />
    </>
  );
};

export default ForgotPassword;
