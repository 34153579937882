import React, { useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import styles from "./Input.module.css";
import { Box } from "@mui/material";

const Input = ({
  type,
  name,
  id,
  label,
  placeholder,
  error,
  onChange,
  value,
  touched,
  width,
  disabled,
  eye,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <>
      <label className={styles.label} htmlFor={name}>
        {label}
      </label>
      <Box className={styles.input} sx={{ width: {sm:width,xs:"100%"} }}>
        <input
          style={{
            color: "#fff",
          }}
          placeholder={placeholder}
          type={eye ? (showPassword ? "text" : "password") : type}
          name={name}
          id={id}
          onChange={onChange}
          onBlur={onChange}
          value={value}
          touched={touched}
          disabled={disabled}
        />
        {eye && (
          <span onClick={handleTogglePassword}>
            {showPassword ? (
              <VisibilityIcon sx={{ color: "#fff", cursor: "pointer" }} />
            ) : (
              <VisibilityOffIcon sx={{ color: "#fff", cursor: "pointer" }} />
            )}
          </span>
        )}
      </Box>
      {touched && error ? <div className={styles.error}>{error}</div> : null}
    </>
  );
};

export default Input;
