import React, { useEffect, useState } from "react";
import Container from "../../Component/Dashboard/Container/Container";
import Navbar from "../../Component/Dashboard/Navbar/Navbar";
import Header from "../../Component/Dashboard/Header/Header";
import { useProfile } from "../../Component/Dashboard/Hooks/useDashboard";
import Content from "../../Component/Dashboard/Container/Content";
import styles from "../../Component/Dashboard/Container/Container.module.css";
import { Box, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import axios from "axios";

const KycPage = () => {
  const [profile, setProfile] = useState({});

  const getProfile = async () => {
    const config = {
      headers: {
        // authorization: localStorage.getItem(JWT),
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    };
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_BACKEND}/users/me?populate=*`,
        config
      );
      setProfile(data.data);
    } catch (error) {
      console.log("catch", error);
    }
  };
  const kycUpdate = async () => {
    const config = {
      headers: {
        // authorization: localStorage.getItem(JWT),
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    };
    try {
      const data = await axios.post(
        `${process.env.REACT_APP_BACKEND}/users/kyc-review`,
        { id: profile?.id },
        config
      );
      if (data.status === 200) {
        getProfile();
      }
    } catch (error) {
      console.log("catch", error);
    }
  };
  useEffect(() => {
    getProfile();
  }, []);

  useEffect(() => {
    if (
      profile?.kycStatus === "Pending" ||
      profile?.kycStatus === "Not Approved"
    ) {
      // Create a function to handle script loading
      const loadScript = (src) => {
        return new Promise((resolve, reject) => {
          const script = document.createElement("script");
          script.src = src;
          script.async = true;
          script.onload = resolve;
          script.onerror = reject;
          document.body.appendChild(script);
        });
      };
  
      // Load both scripts sequentially
      const loadVeriffScripts = async () => {
        try {
          await loadScript("https://cdn.veriff.me/sdk/js/1.5/veriff.min.js");
          await loadScript("https://cdn.veriff.me/incontext/js/v1/veriff.js");
  
          if (window.Veriff) {
            const veriff = window.Veriff({
              host: "https://stationapi.veriff.com",
              apiKey: "03f1e65d-1368-4d0c-af23-889dfeb09e72",
              parentId: "veriff-root",
              onSession: async (err, response) => {
                if (err) {
                  console.error("Error during Veriff session:", err);
                  return;
                }
  
                window.veriffSDK.createVeriffFrame({
                  url: response.verification.url,
                  onEvent: function (msg) {
                    switch (msg) {
                      case window.veriffSDK.MESSAGES.CANCELED:
                        // Handle the case where the verification is canceled
                        console.log("Verification was canceled");
                        break;
                      // Handle other events if necessary
                      default:
                        kycUpdate();
                        break;
                    }
                  },
                });
              },
            });
  
            veriff.setParams({
              vendorData: profile?.id.toString(),
            });
            veriff.mount();
          }
        } catch (error) {
          console.error("Error loading Veriff scripts:", error);
        }
      };
  
      loadVeriffScripts();
  
      // Cleanup: remove the scripts when the component unmounts
      return () => {
        const veriffSdkScript = document.querySelector(
          'script[src="https://cdn.veriff.me/sdk/js/1.5/veriff.min.js"]'
        );
        const veriffInContextScript = document.querySelector(
          'script[src="https://cdn.veriff.me/incontext/js/v1/veriff.js"]'
        );
  
        if (veriffSdkScript) document.body.removeChild(veriffSdkScript);
        if (veriffInContextScript) document.body.removeChild(veriffInContextScript);
      };
    }
  }, [profile?.kycStatus]);
  

  // Function to render KYC status message
  const KycStatusMessage = () => {
    let message, bgColor, color, Icon;

    switch (profile?.kycStatus) {
      case "KYC Under Review":
        message = "Your KYC is Under Review.";
        bgColor = "#f9f9a9";
        color = "black";
        Icon = HourglassEmptyIcon;
        break;
      case "Approved":
        message = "Your KYC has been Approved.";
        bgColor = "#e0ffe0";
        color = "green";
        Icon = CheckCircleIcon;
        break;
      case "Not Approved":
        message = "Your KYC has been Rejected.";
        bgColor = "#ffe0e0";
        color = "red";
        Icon = CancelIcon;
        break;
      default:
        return null;
    }

    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          p: { sm: "2rem", xs: "1rem" },
          background: bgColor,
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          width: { md: "60%", xs: "100%" },
          textAlign: "center",
          borderRadius: "5px",
          fontWeight: "bold",
          color: color,
        }}
      >
        <Icon sx={{ fontSize: "30px", mr: "1rem" }} />
        <Typography sx={{ fontSize: "20px" }}>{message}</Typography>
      </Box>
    );
  };

  return (
    <Container>
      {profile && (
        <Navbar
          profile={profile}
          name={`${profile?.firstName} ${profile?.lastName}`}
        />
      )}
      <Header
        message={`Welcome back, ${profile?.firstName} ${profile?.lastName}`}
        title="KYC"
      />
      <div
        className={styles.mainContent}
        style={{
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {profile?.kycStatus === "Pending" && <div id="veriff-root"></div>}
        {KycStatusMessage()}
        {profile?.kycStatus === "Not Approved" && (
          <Box>
            <Typography
              sx={{ py: "0.5rem", fontWeight: "600", textAlign: "center" }}
            >
              Please re-initiate your KYC veriffication request
            </Typography>
            <div id="veriff-root"></div>
          </Box>
        )}
      </div>
      <Content></Content>
    </Container>
  );
};

export default KycPage;
