import React from "react";
import { Box, Typography } from "@mui/material";

const NFTTab = ({ totalCollection, minted, fullName }) => {
  return (
    <div>
      <Box
        sx={{
          padding: "4px",
          m: "0px 0 18px",
          border: "1px solid #1f1f1f",
          borderRadius: "24px",
        }}
      >
        <Typography
          sx={{
            backgroundColor: "#1f1f1f",
            fontSize: "15px",
            textAlign: "center",
            textTransform: "capitalize",
            padding: "8px 10px",
            borderRadius: "24px",
            fontWeight: "600",
          }}
        >
          Model: {fullName}
        </Typography>
      </Box>
      <Box
        sx={{
          border: "1px solid #2a2a2a",
          borderRadius: "16px",
          padding: "1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: "10px",
            "& h6": {
              fontSize: "16px !important",
              fontWeight: "600 !important",
            },
            "& p": {
              fontSize: "16px !important",
            },
          }}
        >
          <Typography component={"h6"}>Total Unique NFTs</Typography>
          <Typography component={"p"}>{1040}</Typography>
        </Box>
        {/* <Box className={classes.inner_box}>
          <Typography component={"h6"}>Minted</Typography>
          <Typography component={"p"}>{minted}</Typography>
        </Box> */}
      </Box>
    </div>
  );
};

export default NFTTab;
