import React from "react";
import DreamGirlForm from "../Component/Forms/DreamGirlForm";
import Navbar from "../Component/Navbar/Navbar";
import Footer from "../Component/Footer/Footer";
import { Helmet } from "react-helmet-async";

const DreamGirl = () => {
  return (
    <>
      <Helmet>
        <title>DreamGirl Application</title>
        <meta
          name="description"
          content="
           To apply as a Dream Girl, please complete and submit the form to enter the approval process.
           As a Dream Girl & Content Provider, you could earn 40% of the sales generated by your NFT, with no cost to you. You have hundreds of pictures, so let's put them to work and create your own personal revenue stream! Plus, if you have friends who might be interested in participating as well, we'll give you 10% of the sales their NFTs generate, and they'll still get the full 40%."
        />
      </Helmet>
      <Navbar />
      <DreamGirlForm />
      <Footer />
    </>
  );
};

export default DreamGirl;
