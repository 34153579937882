import React from "react";
import ArrowDown from "../../Assets/svg/ArrowDown.svg";
import Circular from "../../Assets/png/Circular.png";
import styles from "./Number.module.css";

const Number = () => {
  return (
    <div className={styles.container}>
      <div className={styles.rotating}>
        <span className={styles.group}>
          <img className={styles.circle} src={Circular} alt="Circle" />
          <span className={styles.arrow}>
            <img src={ArrowDown} alt="ArrowDown" />
          </span>
        </span>
      </div>
      <div className={`${styles.numbers} ${styles.number1}`}>
        <h3>Mint</h3>
        <p>NFT's</p>
      </div>
      <div className={`${styles.numbers} ${styles.number2}`}>
        <h3>Create</h3>
        <p>Poker Hands</p>
      </div>
      <div className={`${styles.numbers} ${styles.number3}`}>
        <h3>Unlock</h3>
        <p>Prizes</p>
      </div>
    </div>
  );
};

export default Number;
